import React from 'react';
import { Box, Typography, Paper, Button } from '@mui/material';
import AssessmentIcon from '@mui/icons-material/Assessment';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import DonutLargeIcon from '@mui/icons-material/DonutLarge';

const NoDataMessage = ({ message, section = null }) => {
  // Different icons for different sections
  const getIcon = () => {
    switch (section) {
      case 'kpis':
        return <DonutLargeIcon sx={{ fontSize: 80, color: '#3f51b5', opacity: 0.7 }} />;
      case 'sentiment':
        return <QueryStatsIcon sx={{ fontSize: 80, color: '#3f51b5', opacity: 0.7 }} />;
      case 'reviews':
        return <AssessmentIcon sx={{ fontSize: 80, color: '#3f51b5', opacity: 0.7 }} />;
      default:
        return <AssessmentIcon sx={{ fontSize: 80, color: '#3f51b5', opacity: 0.7 }} />;
    }
  };

  return (
    <Paper
      elevation={0}
      sx={{
        p: 5,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        borderRadius: 2,
        minHeight: '400px',
        bgcolor: 'white'
      }}
    >
      <Box sx={{ mb: 3 }}>
        {getIcon()}
      </Box>
      <Typography variant="h5" gutterBottom sx={{ fontWeight: 'medium' }}>
        We're Compiling Your Data
      </Typography>
      <Typography variant="body1" color="text.secondary" sx={{ maxWidth: '600px', mb: 3 }}>
        {message || "We're still gathering insights for this recently added or claimed business. Check back soon as we analyze your reviews and customer interactions."}
      </Typography>
      <Box sx={{ mt: 2 }}>
        <Button
          variant="outlined"
          color="primary"
          sx={{ mr: 2 }}
          onClick={() => window.location.reload()}
        >
          Refresh
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={() => window.open('https://support.yourdomain.com/business-insights', '_blank')}
        >
          Learn More
        </Button>
      </Box>
    </Paper>
  );
};

export default NoDataMessage;