import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import {
  Container,
  Grid,
  Paper,
  Typography,
  Box,
  Button,
  Avatar,
  Card,
  CardContent,
  Divider,
  IconButton,
  TextField,
  MenuItem,
  Skeleton,
  CircularProgress,
  Badge
} from '@mui/material';
import {
  Search as SearchIcon,
  FilterList as FilterIcon,
  Business as BusinessIcon,
  Group as GroupIcon,
  TrendingUp as TrendingUpIcon,
  Add as AddIcon,
  HourglassEmpty as HourglassEmptyIcon,
  Person as PersonIcon,
  BarChart as BarChartIcon,
  Insights as InsightsIcon
} from '@mui/icons-material';
import { useGetUserOutgoingReqQuery } from '../../redux/thunks/users/useroutgoingrequests';
import { useAddTrustedUserMutation } from '../../redux/thunks/users/useraddtrusteduser';
import { useAddTrustedUserResMutation } from '../../redux/thunks/users/useraddtrusteduserres';
import { useGetUserIncomingReqQuery } from '../../redux/thunks/users/userincomingrequests';
import { useGetNetworkStatsQuery } from '../../redux/thunks/users/getnetworkstats';
import { usePrefetch } from '../../redux/thunks/users/userprofile';



// Import our custom network insights components
import NetworkInsightsColumn from './NetworkInsightsColumn';



// Skeleton Components
const NetworkStatsSkeleton = () => (
  <Paper variant='outlined' sx={{ p: 3, mb: 2, border: 'none' }}>
    <Skeleton variant="text" width="60%" height={32} sx={{ mb: 2 }} />
    <Grid container spacing={2}>
      {[1, 2, 3, 4].map((item) => (
        <Grid item xs={6} key={item}>
          <Skeleton variant="text" width="60%" height={40} />
          <Skeleton variant="text" width="80%" height={24} />
        </Grid>
      ))}
    </Grid>
  </Paper>
);

const QuickActionsSkeleton = () => (
  <Paper variant='outlined' sx={{ p: 3, mb: 2, border: 'none' }}>
    <Skeleton variant="text" width="50%" height={32} sx={{ mb: 2 }} />
    {[1, 2, 3].map((item) => (
      <Skeleton 
        key={item}
        variant="rectangular" 
        height={36} 
        sx={{ mb: 2, borderRadius: 1 }}
      />
    ))}
  </Paper>
);

const CategoriesSkeleton = () => (
  <Paper variant='outlined' sx={{ p: 3, border: 'none' }}>
    <Skeleton variant="text" width="60%" height={32} sx={{ mb: 2 }} />
    {[1, 2, 3, 4].map((item) => (
      <Box key={item} sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
        <Skeleton variant="text" width="60%" />
        <Skeleton variant="text" width="20%" />
      </Box>
    ))}
  </Paper>
);

const ConnectionCardSkeleton = () => (
  <Paper variant='outlined' sx={{ p: 3, mb: 2, border: 'none' }}>
    <Box sx={{ display: 'flex', gap: 2 }}>
      <Skeleton variant="circular" width={50} height={50} />
      <Box sx={{ flex: 1 }}>
        <Skeleton variant="text" width="60%" sx={{ mb: 1 }} />
        <Skeleton variant="text" width="80%" />
        <Skeleton variant="text" width="40%" />
      </Box>
      <Box sx={{ width: 100 }}>
        <Skeleton variant="text" width="100%" />
      </Box>
    </Box>
  </Paper>
);

const NetworkInsightsSkeleton = () => (
  <>
    <Paper variant='outlined' sx={{ p: 3, mb: 2, border: 'none' }}>
      <Skeleton variant="text" width="70%" height={32} sx={{ mb: 2 }} />
      <Skeleton variant="rectangular" height={200} sx={{ mb: 2 }} />
      <Skeleton variant="text" width="100%" />
      <Skeleton variant="text" width="90%" />
    </Paper>
    
    <Paper variant='outlined' sx={{ p: 3, mb: 2, border: 'none' }}>
      <Skeleton variant="text" width="60%" height={32} sx={{ mb: 2 }} />
      <Skeleton variant="rectangular" height={180} />
    </Paper>
    
    <Paper variant='outlined' sx={{ p: 3, border: 'none' }}>
      <Skeleton variant="text" width="65%" height={32} sx={{ mb: 2 }} />
      {[1, 2, 3, 4].map((item) => (
        <Box key={item} sx={{ mb: 2 }}>
          <Skeleton variant="rectangular" height={60} />
        </Box>
      ))}
    </Paper>
  </>
);

// Sent Request Card Component
const SentRequestCard = ({ request, onResend, onCancel }) => {
  const navigate = useNavigate();

  const formattedDate = new Intl.DateTimeFormat("en-US", {
    year: "numeric",
    month: "long",
    day: "2-digit",
  }).format(new Date(request.creationTime));
  
  return (
    <Paper variant='outlined' sx={{ p: 2, mb: 2, border: 'none', cursor: 'pointer' }} onClick={() => navigate(`/profile/${request.trustedUserId}/active`)}>
      <Box sx={{ display: 'flex', gap: 2 }}>
        <Avatar src={request.profileImageUrl} sx={{ width: 50, height: 50 }} />
        <Box sx={{ flex: 1 }}>
          <Typography variant="subtitle1">{request.displayName}</Typography>
          
          <Typography variant="body2" color="text.secondary">
            Sent on {formattedDate}
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', gap: 1, alignItems: 'flex-start' }}>
          <Button 
            variant="outlined" 
            size="small" 
            onClick={(e) => {
              e.stopPropagation();
              onResend(request);
            }}
            sx={{ minWidth: 'auto' }}
          >
            Resend
          </Button>
          <Button 
            variant="outlined" 
            color="error" 
            size="small" 
            onClick={(e) => {
              e.stopPropagation();
              onCancel(request);
            }}
            sx={{ minWidth: 'auto' }}
          >
            Cancel
          </Button>
        </Box>
      </Box>
    </Paper>
  );
};
  
// Received Invite Card Component
const ReceivedInviteCard = ({ invite, onAccept, onReject }) => {
  const navigate = useNavigate();
  
  const formattedDate = new Intl.DateTimeFormat("en-US", {
    year: "numeric",
    month: "long",
    day: "2-digit",
  }).format(new Date(invite.creationTime));
  
  return (
    <Paper variant='outlined' sx={{ p: 2, mb: 2, border: 'none', cursor: 'pointer' }} onClick={() => navigate(`/profile/${invite.trustedUserId}/active`)}>
      <Box sx={{ display: 'flex', gap: 2 }}>
        <Avatar src={invite.profileImageUrl} sx={{ width: 50, height: 50 }} />
        <Box sx={{ flex: 1 }}>
          <Typography variant="subtitle1">{invite.displayName}</Typography>
          <Typography variant="body2" color="text.secondary">
            {invite.role} • {invite.company || 'Not specified'}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {invite.mutualConnections || 0} mutual connections
          </Typography>
          <Typography variant="body2" color="text.secondary">
            Received on {formattedDate}
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', gap: 1, alignItems: 'flex-start' }}>
          <Button 
            variant="contained" 
            color="primary" 
            size="small" 
            onClick={(e) => {
              e.stopPropagation();
              onAccept(invite);
            }}
            sx={{ minWidth: 'auto' }}
          >
            Accept
          </Button>
          <Button 
            variant="outlined" 
            color="error" 
            size="small" 
            onClick={(e) => {
              e.stopPropagation();
              onReject(invite);
            }}
            sx={{ minWidth: 'auto' }}
          >
            Decline
          </Button>
        </Box>
      </Box>
    </Paper>
  );
};

// Network Stats Component
const NetworkStatsCard = ({ user, incoming, outgoing }) => {
  return (
    <Paper variant='outlined' sx={{ p: 3, mb: 2, border: 'none' }}>
      <Typography variant="h6" gutterBottom>Network Overview</Typography>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Typography variant="h4" color="primary">{user.myCurrentNetworkList.userDetails.length}</Typography>
          <Typography variant="body2" color="text.secondary">Extended Network</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="h4" color="primary">{incoming.pendingInTrustedUsers.length}</Typography>
          <Typography variant="body2" color="text.secondary">Requests Waiting</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="h4" color="primary"> {(user.trustScore || 50).toFixed(1)}%</Typography>
          <Typography variant="body2" color="text.secondary">Trust Score</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="h4" color="primary">{outgoing.pendingOutTrustedUsers.length}</Typography>
          <Typography variant="body2" color="text.secondary">Pending Requests</Typography>
        </Grid>
      </Grid>
    </Paper>
  );
};

// Quick Actions Component
const QuickActionsCard = ({user}) => {
  const navigate = useNavigate();

  return (
    <Paper variant='outlined' sx={{ p: 3, mb: 2, border: 'none' }}>
      <Typography variant="h6" gutterBottom>
        Quick Actions
      </Typography>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        {/* Find Connections */}
        <Button
          variant="contained"
          startIcon={<AddIcon />}
          onClick={() => navigate('/usersearch')}
        >
          Find Connections
        </Button>

        {/* Import Contacts */}
        <Button
          variant="outlined"
          startIcon={<GroupIcon />}
          sx={{
            position: 'relative',
            justifyContent: 'flex-start',
          }}
        >
          <Typography
            sx={{
              position: 'absolute',
              top: -2,
              right: 8,
              fontSize: '0.7rem',
              color: 'orange',
              fontWeight: 'bold',
            }}
          >
            Coming Soon
          </Typography>
          Import Contacts
        </Button>

        {/* Manage Profile */}
        <Button
          variant="outlined"
          startIcon={<PersonIcon />}
          onClick={() => navigate(`/profile/${user._id}/active`)}
        >
          Manage Profile
        </Button>
      </Box>
    </Paper>
  );
};

// Categories Component
const CategoriesCard = ({ stats }) => {

  const CONNECTION_CATEGORIES = [
    { name: 'Follows', count: stats.networkFollows || 0 },
    { name: 'Referrals', count: stats.networkReferred || 0},
    { name: 'Reviews', count: stats.networkReviews || 0},
    { name: 'Views', count: stats.networkViews || 0}
  ];
  
  return (
  <Paper variant='outlined' sx={{ p: 3, border: 'none' }}>
    <Typography variant="h6" gutterBottom>Network Stats</Typography>
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
      {CONNECTION_CATEGORIES.map((category) => (
        <Box key={category.name} sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography variant="body2">{category.name}</Typography>
          <Typography variant="body2" color="text.secondary">({category.count})</Typography>
        </Box>
      ))}
    </Box>
  </Paper>
)};

// Connection Card Component
const ConnectionCard = ({ connection }) => {
  const navigate = useNavigate();

  const formattedDate = new Intl.DateTimeFormat("en-US", {
    year: "numeric",
    month: "long",
    day: "2-digit",
  }).format(new Date(connection.creationTime));
    
  return (
    <Paper variant='outlined' sx={{ p: 2, mb: 2, border: 'none', cursor: 'pointer' }} onClick={() => navigate(`/profile/${connection.userId}/active`)}>
      <Box sx={{ display: 'flex', gap: 2 }}>
        <Avatar src={connection.profileImageUrl} sx={{ width: 50, height: 50 }} />
        <Box sx={{ flex: 1 }}>
          <Typography variant="subtitle1">{connection.displayName}</Typography>
          <Typography variant="body2" color="text.secondary">
            {connection.role || 'No role specified'} {connection.industry ? `• ${connection.industry}` : ''}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            Connected since {formattedDate}
          </Typography>
        </Box>
        <Box sx={{ textAlign: 'right' }}>
          <Typography variant="body2" color="primary">
            Trust Score: {connection.trustRating}%
          </Typography>
        </Box>
      </Box>
    </Paper>
  );
};

// Main Network Page Component
export default function NetworkPage() {
  const navigate = useNavigate();

  const userId = useSelector((state) => state.auth.userId)  
  const thisUser = useSelector((state) => state.auth?.user || '');

  const [loading, setLoading] = useState(true);
  const [showRequests, setShowRequests] = useState(false); 
  const [pendingInvites, setPendingInvites] = useState([]); 
  const [sentInvites, setSentInvites] = useState([]);

  const [addTrustedUserRes, { isLoading: addTrustedUserResLoading }] = useAddTrustedUserResMutation();
  const { data: incoming, isLoading: incomingLoading, isSuccess: incomingSuccess } = useGetUserIncomingReqQuery(userId);
  const { data: outgoing, isLoading: outgoingLoading, isSuccess: outgoingSuccess } = useGetUserOutgoingReqQuery(userId);
  const [addTrustedUser, { isLoading: addTrustedUserLoading }] = useAddTrustedUserMutation();
  const { data: networkStatsData,  isError: isNetworkStatsDataError,  isLoading: isNetworkStatsDataLoading,  error: networkStatsDataError,} = useGetNetworkStatsQuery();
  console.log('networkStatsData', networkStatsData)

  const firstDegrees = useSelector((state) => state.auth.user.firstDegreeFriends);

  console.log('thisUser', thisUser)

  useEffect(() => {   
    setLoading(incomingLoading || outgoingLoading || isNetworkStatsDataLoading); 
  }, [incomingLoading, outgoingLoading, isNetworkStatsDataLoading]);

  useEffect(() => {
    if (incomingSuccess) {
      console.log('incoming', incoming);    
      const pendingInvitesData = incoming.pendingInTrustedUsers.filter((invite) => invite.state === 'notification');   
      setPendingInvites(pendingInvitesData);
    }
  }, [incoming, incomingSuccess]);

  useEffect(() => {
    if (outgoingSuccess) {
      console.log('outgoing', outgoing);    
      const pendingOutvites = outgoing.pendingOutTrustedUsers;   
      setSentInvites(pendingOutvites);
    }
  }, [outgoing, outgoingSuccess]);

  // Handler functions
  const handleResendRequest = async (request) => {
    console.log('Resending request:', request);
    const payload = { 'trustedUserId': request.trustedUserId, requestType: 'reminder' };
    await addTrustedUser({payload, userId: request.trustedUserId});
    console.log(`Reminder sent to ${request.displayName}`);
  };

  const handleCancelRequest = async (request) => {
    console.log('Canceling request:', request);
    const payload = { 'trustedUserId': request.trustedUserId, requestType: 'withdraw' };
    await addTrustedUser({payload, userId: request.trustedUserId});
    
    setSentInvites((prevInvites) => prevInvites.filter((prevInvite) => 
      prevInvite.trustedUserId !== request.trustedUserId
    ));
    console.log(`Withdrawn invitation to ${request.displayName}`);
  };

  const handleAcceptInvite = async (invite) => {
    console.log('Accepting invite:', invite);
    const payload = { 'trustedUserId': invite.trustedUserId, response: 1, 'userId': userId };
    await addTrustedUserRes(payload);
    
    setPendingInvites((prevInvites) => prevInvites.filter((prevInvite) => 
      prevInvite.trustedUserId !== invite.trustedUserId
    ));
    console.log(`Accepted invitation from ${invite.displayName}`);
  };

  const handleRejectInvite = async (invite) => {
    console.log('Rejecting invite:', invite);
    const payload = { 'trustedUserId': invite.trustedUserId, response: 0, 'userId': userId };
    await addTrustedUserRes(payload);
    
    setPendingInvites((prevInvites) => prevInvites.filter((prevInvite) => 
      prevInvite.trustedUserId !== invite.trustedUserId
    ));
    console.log(`Rejected invitation from ${invite.displayName}`);
  };

  if (loading) {
    return (
      <Box>
        <Box sx={{ bgcolor: 'primary.main', p: 3, mb: 3 }}>
          <Container maxWidth="xl">
            <Skeleton variant="text" width="300px" height={40} sx={{ bgcolor: 'rgba(255,255,255,0.1)' }} />
          </Container>
        </Box>

        <Container maxWidth="xl">
          <Grid container spacing={3}>
            {/* Left Column Skeleton */}
            <Grid item xs={12} md={3}>
              <NetworkStatsSkeleton />
              <QuickActionsSkeleton />
              <CategoriesSkeleton />
            </Grid>

            {/* Middle Column Skeleton */}
            <Grid item xs={12} md={6}>
              <Paper variant='outlined' sx={{ p: 3, mb: 2, border: 'none' }}>
                <Box sx={{ display: 'flex', gap: 2 }}>
                  <Skeleton variant="rectangular" width={150} height={40} sx={{ borderRadius: 1 }} />
                  <Skeleton variant="rectangular" width={150} height={40} sx={{ borderRadius: 1 }} />
                </Box>
              </Paper>
              {[1, 2, 3].map((item) => (
                <ConnectionCardSkeleton key={item} />
              ))}
            </Grid>

            {/* Right Column Skeleton */}
            <Grid item xs={12} md={3}>
              <NetworkInsightsSkeleton />
            </Grid>
          </Grid>
        </Container>
      </Box>
    );
  }

  return (
    <Box>
      <Box 
        sx={{ 
          bgcolor: 'primary.main', 
          color: 'white',
          p: 3,
          mb: 3 
        }}
      >
        <Container maxWidth="xl">
          <Typography variant="h4">My Network</Typography>
        </Container>
      </Box>

      <Container maxWidth="xl">
        <Grid container spacing={3}>
          {/* Left Column */}
          <Grid item xs={12} md={3}>
            <NetworkStatsCard user={thisUser} incoming={incoming} outgoing={outgoing} />
            <QuickActionsCard user={thisUser} />
            <CategoriesCard stats={networkStatsData} />
          </Grid>

          {/* Middle Column */}
          <Grid item xs={12} md={6}>
            <Paper variant='outlined' sx={{ p: 3, mb: 2, border: 'none' }}>
              <Box sx={{ display: 'flex', gap: 2, mb: 2 }}>
                <TextField
                  select
                  size="small"
                  defaultValue="all"
                  sx={{ minWidth: 150 }}
                >
                  <MenuItem value="all">All Connections</MenuItem>
                  <MenuItem value="recent">Recently Added</MenuItem>
                  <MenuItem value="business">Business Partners</MenuItem>
                </TextField>
                <TextField
                  select
                  size="small"
                  defaultValue="recent"
                  sx={{ minWidth: 150 }}
                >
                  <MenuItem value="recent">Recently Active</MenuItem>
                  <MenuItem value="trust">Trust Score</MenuItem>
                  <MenuItem value="name">Name</MenuItem>
                </TextField>
                <Button
                  variant="outlined"
                  onClick={() => setShowRequests(!showRequests)}
                  sx={{ ml: 'auto' }}
                >
                  {showRequests ? 'Show Connections' : 'Show Requests'}
                </Button>
              </Box>
            </Paper>

            {showRequests ? (
              <>
                {/* Sent Requests Section */}
                <Box sx={{ mb: 4 }}>
                  <Typography variant="h6" gutterBottom sx={{ px: 1 }}>
                    Sent Connection Requests
                  </Typography>
                  {sentInvites.length > 0 ? (
                    sentInvites.map((request) => (
                      <SentRequestCard
                        key={request.trustedUserId}
                        request={request}
                        onResend={handleResendRequest}
                        onCancel={handleCancelRequest}
                      />
                    ))
                  ) : (
                    <Paper variant='outlined' sx={{ p: 3, mb: 2, border: 'none', textAlign: 'center' }}>
                      <Typography variant="body2" color="text.secondary">
                        No pending sent requests
                      </Typography>
                    </Paper>
                  )}
                </Box>

                {/* Received Invites Section */}
                <Box>
                  <Typography variant="h6" gutterBottom sx={{ px: 1 }}>
                    Received Invites
                  </Typography>
                  {pendingInvites.length > 0 ? (
                    pendingInvites.map((invite) => (
                      <ReceivedInviteCard
                        key={invite.trustedUserId}
                        invite={invite}
                        onAccept={handleAcceptInvite}
                        onReject={handleRejectInvite}
                      />
                    ))
                  ) : (
                    <Paper variant='outlined' sx={{ p: 3, mb: 2, border: 'none', textAlign: 'center' }}>
                      <Typography variant="body2" color="text.secondary">
                        No pending received invites
                      </Typography>
                    </Paper>
                  )}
                </Box>
              </>
            ) : (
              <>
                {firstDegrees && firstDegrees.length > 0 ? (
                  firstDegrees.map((connection) => (
                    <ConnectionCard key={connection.userId} connection={connection} />
                  ))
                ) : (
                  <Paper variant='outlined' sx={{ p: 3, mb: 2, border: 'none', textAlign: 'center' }}>
                    <Typography variant="body2" color="text.secondary">
                      You don't have any connections yet. Use the "Find Connections" button to get started.
                    </Typography>
                  </Paper>
                )}
              </>
            )}
          </Grid>

          {/* Right Column - New Network Insights */}
          <Grid item xs={12} md={3}>
            <NetworkInsightsColumn userData={thisUser} />
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}