import React, { useState } from 'react';
import { useSelector } from 'react-redux'; // For auth state

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Paper,
  Typography,
  Box,
  CircularProgress,
  Alert
} from '@mui/material';
import PlacesAutocomplete from 'react-places-autocomplete';
import { LocationOn as LocationIcon } from '@mui/icons-material';
import { useUpdateUserProfileMutation } from '../../redux/thunks/users/userprofile';

const LocationModal = ({open, onClose, onLocationSet, userData, setUserData }) => {

  const userId = useSelector((state) => state.auth?.userId || null);
    
  const [address, setAddress] = useState('');
  const [error, setError] = useState(null);
  
  const [updateProfile, { isLoading: isSubmitting }] = useUpdateUserProfileMutation();

  const handleAddressChange = (newAddress) => {
    setAddress(newAddress);
    setError(null);
  };

  const handleAddressSelect = (selectedAddress) => {
    setAddress(selectedAddress);
  };

  const handleSubmit = async () => {
    try {
      if (!address) {
        setError('Please enter a location');
        return;
      }

      const payload = {
        address: address, 
        updateType: 'single'
      }

      // Send the raw address to the backend for processing
      const result = await updateProfile({userId, payload }).unwrap();

    // Optimistically mark location as set in the user data with minimal required data
    setUserData({
        ...userData,
        geocodeResults: {
            fullAddress: address,
            timestamp: new Date().toISOString()
        }
        });
    

      // Call the parent handler with the result if needed
      if (onLocationSet) {
        onLocationSet(result);
      }
      
      onClose();
    } catch (err) {
      setError('Error saving location. Please try again.');
      console.error('Location update error:', err);
    }
  };

  return (
    <Dialog 
      open={open} 
      onClose={onClose}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle>
        <Box display="flex" alignItems="center" gap={1}>
          <LocationIcon color="primary" />
          <Typography variant="h6">Set Your Location</Typography>
        </Box>
      </DialogTitle>

      <DialogContent>
        <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
          Enter your location to get personalized recommendations and connect with local businesses
        </Typography>

        {error && (
          <Alert severity="error" sx={{ mb: 2 }}>
            {error}
          </Alert>
        )}

        <PlacesAutocomplete
          value={address}
          onChange={handleAddressChange}
          onSelect={handleAddressSelect}
          searchOptions={{
            componentRestrictions: { country: ['us', 'ca'] }
          }}
        >
          {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
            <div>
              <TextField
                {...getInputProps({
                  placeholder: 'Enter your location',
                  fullWidth: true,
                  variant: 'outlined',
                })}
                sx={{ mb: 2 }}
              />
              {suggestions.length > 0 && (
                <Paper 
                  elevation={3}
                  sx={{
                    maxHeight: '200px',
                    overflowY: 'auto',
                    mt: -2,
                    mb: 2,
                    zIndex: 1000
                  }}
                >
                  {loading && (
                    <Box p={2} display="flex" alignItems="center" gap={1}>
                      <CircularProgress size={20} />
                      <Typography>Loading suggestions...</Typography>
                    </Box>
                  )}
                  {suggestions.map((suggestion) => {
                    const { key, ...suggestionProps } = getSuggestionItemProps(suggestion, {
                      className: 'suggestion-item',
                    });
                    
                    return (
                      <Box
                        key={suggestion.placeId}
                        {...suggestionProps}
                        sx={{
                          p: 2,
                          cursor: 'pointer',
                          '&:hover': {
                            bgcolor: 'action.hover',
                          },
                          '&:not(:last-child)': {
                            borderBottom: '1px solid',
                            borderColor: 'divider',
                          },
                        }}
                      >
                        <Typography variant="body2">
                          {suggestion.description}
                        </Typography>
                      </Box>
                    );
                  })}
                </Paper>
              )}
            </div>
          )}
        </PlacesAutocomplete>

        <Typography variant="caption" color="text.secondary">
          Your location helps us provide better recommendations and connect you with trusted local businesses
        </Typography>
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose} color="inherit">
          Cancel
        </Button>
        <Button
          onClick={handleSubmit}
          variant="contained"
          disabled={!address || isSubmitting}
        >
          {isSubmitting ? <CircularProgress size={20} /> : 'Set Location'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default LocationModal;