import React, { useState, useEffect } from 'react';
import {
  Avatar,
  Paper,
  Typography,
  IconButton,
  Menu,
  MenuItem,
  CircularProgress,
  Box,
  Divider,
  Badge,
  Chip,
  Button,
  SvgIcon
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import VolumeOffIcon from '@mui/icons-material/VolumeOff';
import NotificationsOffIcon from '@mui/icons-material/NotificationsOff';
import DeleteIcon from '@mui/icons-material/Delete';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import BusinessIcon from '@mui/icons-material/Business';
import RateReviewIcon from '@mui/icons-material/RateReview';
import PeopleIcon from '@mui/icons-material/People';
import StarIcon from '@mui/icons-material/Star';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import { Link, useNavigate } from 'react-router-dom';

import { useGetUserNotificationsQuery } from '../../redux/thunks/users/usernotifications';
import { useUserNotificationReadMutation } from '../../redux/thunks/users/usernotificationsmarkread';

// Function to determine notification type and get appropriate icon
const getNotificationIcon = (notification) => {
  // This is a placeholder - you would determine type based on your notification data structure
  const notice = notification.notice?.toLowerCase() || '';
  
  if (notice.includes('review')) {
    return <RateReviewIcon sx={{ color: '#3f51b5' }} />;
  } else if (notice.includes('follow')) {
    return <PeopleIcon sx={{ color: '#4caf50' }} />;
  } else if (notice.includes('like') || notice.includes('liked')) {
    return <ThumbUpIcon sx={{ color: '#ff9800' }} />;
  } else if (notice.includes('trust') || notice.includes('score')) {
    return <WorkspacePremiumIcon sx={{ color: '#9c27b0' }} />;
  } else if (notice.includes('business')) {
    return <BusinessIcon sx={{ color: '#607d8b' }} />;
  } else {
    return <StarIcon sx={{ color: '#2196f3' }} />;
  }
};

// Function to format the timestamp
const formatTimeAgo = (timestamp) => {
  if (!timestamp) return 'Recently';
  
  const now = new Date();
  const notificationDate = new Date(timestamp);
  const diffMs = now - notificationDate;
  const diffMins = Math.floor(diffMs / 60000);
  const diffHours = Math.floor(diffMins / 60);
  const diffDays = Math.floor(diffHours / 24);
  const diffWeeks = Math.floor(diffDays / 7);
  const diffMonths = Math.floor(diffDays / 30);
  
  if (diffMins < 1) return 'Just now';
  if (diffMins < 60) return `${diffMins}m ago`;
  if (diffHours < 24) return `${diffHours}h ago`;
  if (diffDays < 7) return `${diffDays}d ago`;
  if (diffWeeks < 4) return `${diffWeeks}w ago`;
  if (diffMonths < 12) return `${diffMonths}mo ago`;
  return `${Math.floor(diffMonths / 12)}y ago`;
};

// Empty state notification icon
const EmptyNotificationIcon = () => (
  <SvgIcon sx={{ fontSize: 120, color: 'rgba(0, 0, 0, 0.1)' }}>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <path d="M12 22c1.1 0 2-.9 2-2h-4c0 1.1.9 2 2 2zm6-6v-5c0-3.07-1.63-5.64-4.5-6.32V4c0-.83-.67-1.5-1.5-1.5s-1.5.67-1.5 1.5v.68C7.64 5.36 6 7.92 6 11v5l-2 2v1h16v-1l-2-2zm-2 1H8v-6c0-2.48 1.51-4.5 4-4.5s4 2.02 4 4.5v6z" />
      <circle cx="12" cy="11" r="7" fill="none" stroke="currentColor" strokeWidth="1" strokeDasharray="1,2" />
    </svg>
  </SvgIcon>
);

const NotificationList = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedNotification, setSelectedNotification] = useState(null);
  const navigate = useNavigate();

  const {
    data: notifications = [],
    isLoading,
    isError,
    refetch
  } = useGetUserNotificationsQuery();
  
  const [userNotificationRead] = useUserNotificationReadMutation();
  const [notificationList, setNotificationList] = useState([]);
  
  useEffect(() => {
    if (notifications && notifications.length > 0) {
      setNotificationList(notifications);
    }
  }, [notifications]);

  const handleMenuOpen = (event, notification) => {
    setAnchorEl(event.currentTarget);
    setSelectedNotification(notification);
    
    // Mark notification as read when options are opened
    if (notification && !notification.isRead) {
      handleMarkAsRead(notification);
    }
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedNotification(null);
  };

  const handleDeleteNotification = () => {
    // Implement your logic to delete the selected notification here
    console.log(`Deleted notification: ${selectedNotification.notice}`);
    const updatedNotifications = notificationList.filter(
      (notification) => notification._id !== selectedNotification._id
    );
    setNotificationList(updatedNotifications);
    handleMenuClose();
  };

  const handleTurnOffNotifications = () => {
    // Implement your logic to turn off notifications of this type here
    console.log(
      `Turned off notifications from ${selectedNotification.senderDisplayName}`
    );
    handleMenuClose();
  };

  const handleMuteNotifications = () => {
    // Implement your logic to mute notifications from this sender here
    console.log(
      `Muted notifications from ${selectedNotification.senderDisplayName}`
    );
    handleMenuClose();
  };

  const handleMarkAsRead = (notification) => {
    // Call your API to mark the notification as read
    userNotificationRead({ notificationId: notification._id })
      .unwrap()
      .then(() => {
        // Update the local state to reflect the change
        const updatedNotifications = notificationList.map(n => 
          n._id === notification._id ? { ...n, isRead: true } : n
        );
        setNotificationList(updatedNotifications);
      })
      .catch(error => {
        console.error('Failed to mark notification as read', error);
      });
  };

  const handleMarkAllAsRead = () => {
    // This would need to be implemented on your backend
    console.log('Mark all as read');
    // After API call, update all notifications to read
    const updatedNotifications = notificationList.map(n => ({ ...n, isRead: true }));
    setNotificationList(updatedNotifications);
  };

  const handleNotificationClick = (notification) => {
    // Mark as read
    if (!notification.isRead) {
      handleMarkAsRead(notification);
    }
    
    // Navigate based on notification type
    // This is a placeholder - replace with your actual navigation logic
    if (notification.linkUrl) {
      navigate(notification.linkUrl);
    }
  };

  if (isLoading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="200px"
      >
        <CircularProgress color="primary" />
      </Box>
    );
  }

  const unreadCount = notificationList.filter(n => !n.isRead).length;

  if (!notificationList || notificationList.length === 0) {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          minHeight: '400px',
          py: 6,
          px: 2,
          textAlign: 'center'
        }}
      >
        <EmptyNotificationIcon />
        
        <Typography 
          variant="h5" 
          sx={{ 
            mt: 3, 
            mb: 1, 
            fontWeight: 500,
            color: 'text.primary' 
          }}
        >
          No notifications yet
        </Typography>
        
        <Typography 
          variant="body1" 
          color="text.secondary" 
          sx={{ 
            maxWidth: '400px',
            mb: 4
          }}
        >
          You don't have any notifications at the moment. When people interact with your reviews, follow you, or mention you, you'll see them here.
        </Typography>
        
        <Box sx={{ display: 'flex', gap: 2 }}>
          <Button 
            variant="contained" 
            color="primary" 
            component={Link} 
            to="/mynetwork"
            startIcon={<PeopleIcon />}
          >
            Build Your Network
          </Button>
          
          <Button 
            variant="outlined" 
            component={Link} 
            to="/find"
            startIcon={<BusinessIcon />}
          >
            Discover Businesses
          </Button>
        </Box>
      </Box>
    );
  }

  return (
    <div>
      <Box 
        sx={{ 
          display: 'flex', 
          justifyContent: 'space-between', 
          alignItems: 'center', 
          mb: 3
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <NotificationsActiveIcon sx={{ mr: 1.5, color: 'primary.main' }} />
          <Typography variant="h6" sx={{ fontWeight: 500 }}>
            Notifications
            {unreadCount > 0 && (
              <Chip 
                size="small" 
                label={unreadCount} 
                color="primary" 
                sx={{ ml: 1.5, height: 22, fontSize: '0.75rem' }} 
              />
            )}
          </Typography>
        </Box>
        
        {notificationList.length > 0 && unreadCount > 0 && (
          <Button 
            size="small" 
            onClick={handleMarkAllAsRead}
            startIcon={<CheckCircleIcon />}
            sx={{ fontSize: '0.8125rem' }}
          >
            Mark all as read
          </Button>
        )}
      </Box>
      
      <Divider sx={{ mb: 3 }} />
      
      {notificationList.map((notification) => {
        const isUnread = !notification.isRead;
        return (
          <Box
            key={notification._id}
            sx={{
              display: 'flex',
              alignItems: 'flex-start',
              p: 2,
              mb: 2,
              borderRadius: 2,
              bgcolor: isUnread ? 'rgba(25, 118, 210, 0.04)' : 'transparent',
              borderLeft: isUnread ? '3px solid #1976d2' : '3px solid transparent',
              transition: 'background-color 0.2s',
              '&:hover': {
                bgcolor: 'rgba(0, 0, 0, 0.04)',
                cursor: 'pointer'
              }
            }}
            onClick={() => handleNotificationClick(notification)}
          >
            <Avatar
              src={notification.senderPhoto}
              alt={notification.senderDisplayName}
              sx={{ 
                mr: 2, 
                bgcolor: isUnread ? 'primary.light' : 'grey.200',
                width: 40,
                height: 40
              }}
            >
              {getNotificationIcon(notification)}
            </Avatar>
            
            <Box sx={{ flex: 1, mr: 1 }}>
              <Typography 
                variant="body1" 
                fontWeight={isUnread ? 500 : 400}
                color={isUnread ? 'text.primary' : 'text.secondary'}
              >
                {notification.notice}
              </Typography>
              
              <Typography 
                variant="caption" 
                color="text.secondary" 
                sx={{ display: 'block', mt: 0.5 }}
              >
                {formatTimeAgo(notification.creationTime)}
              </Typography>
            </Box>
            
            {isUnread && (
              <Badge
                variant="dot"
                color="primary"
                sx={{ mr: 1 }}
              />
            )}
            
            <IconButton
              aria-label="Options"
              aria-controls="notification-menu"
              aria-haspopup="true"
              onClick={(e) => {
                e.stopPropagation();
                handleMenuOpen(e, notification);
              }}
              size="small"
              sx={{ color: 'text.secondary' }}
            >
              <MoreVertIcon />
            </IconButton>
          </Box>
        );
      })}

      {/* Options Menu */}
      <Menu
        id="notification-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        PaperProps={{
          elevation: 2,
          sx: { mt: 0.5, borderRadius: 2 }
        }}
      >
        <MenuItem onClick={handleDeleteNotification}>
          <DeleteIcon fontSize="small" sx={{ mr: 1.5 }} /> Delete
        </MenuItem>
        <MenuItem onClick={handleTurnOffNotifications}>
          <NotificationsOffIcon fontSize="small" sx={{ mr: 1.5 }} /> Turn Off Notifications
        </MenuItem>
        <MenuItem onClick={handleMuteNotifications}>
          <VolumeOffIcon fontSize="small" sx={{ mr: 1.5 }} /> 
          Mute {selectedNotification?.senderDisplayName}
        </MenuItem>
      </Menu>
    </div>
  );
};

export default NotificationList;