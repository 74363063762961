import React, { useState } from 'react';
import { 
  Grid, 
  Card, 
  CardContent, 
  Typography, 
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Divider,
  Chip,
  Paper,
  Avatar
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import EmojiPeopleIcon from '@mui/icons-material/EmojiPeople';
import RestaurantMenuIcon from '@mui/icons-material/RestaurantMenu';
import StarIcon from '@mui/icons-material/Star';
import LoyaltyIcon from '@mui/icons-material/Loyalty';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import BuildIcon from '@mui/icons-material/Build';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import PublicIcon from '@mui/icons-material/Public';

// Define the actionable insights areas with icons and colors
const insightsAreas = [
  { area: 'Marketing', icon: <LocalOfferIcon />, color: '#2196f3' },
  { area: 'Customer Experience', icon: <EmojiPeopleIcon />, color: '#4caf50' },
  { area: 'Product Development', icon: <RestaurantMenuIcon />, color: '#8bc34a' },
  { area: 'Competition', icon: <StarIcon />, color: '#f44336' },
  { area: 'Customer Retention', icon: <LoyaltyIcon />, color: '#00bcd4' },
  { area: 'Sales Optimization', icon: <TrendingUpIcon />, color: '#673ab7' },
  { area: 'Operational Efficiency', icon: <BuildIcon />, color: '#e91e63' },
  { area: 'Customer Acquisition', icon: <AccountBalanceIcon />, color: '#ff9800' },
  { area: 'Customer Service', icon: <PublicIcon />, color: '#2196f3' }
];

// Helper function to sanitize suggestions
const sanitizeSuggestion = (suggestion) => {
  // Remove any leading punctuations or symbols like *, -, etc.
  if (!suggestion) return '';
  return suggestion.replace(/^[\*|\-|\.|,|\s]+/, '').trim();
};

const ActionableInsights = ({ data }) => {
  // State to track which accordion panel is expanded
  const [expanded, setExpanded] = useState(false);
  
  // Count total number of available insights
  const availableInsightsCount = data?.actionableInsightsData 
    ? Object.keys(data.actionableInsightsData).filter(
        key => data.actionableInsightsData[key] && 
        data.actionableInsightsData[key] !== 'Not enough reviews yet for the insights'
      ).length 
    : 0;
  
  // Count total categories
  const totalCategories = data?.actionableInsightsData 
    ? Object.keys(data.actionableInsightsData).length 
    : 0;
  
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  // If no data is available
  if (!data || !data.actionableInsightsData || Object.keys(data.actionableInsightsData).length === 0) {
    return (
      <Box sx={{ p: 3, textAlign: 'center' }}>
        <Typography variant="h5" color="text.secondary" sx={{ mb: 2 }}>
          No Actionable Insights Available
        </Typography>
        <Typography variant="body1" color="text.secondary">
          We're still analyzing your business data to generate personalized insights.
          Check back soon for recommendations to help grow your business.
        </Typography>
      </Box>
    );
  }

  return (
    <Box sx={{ padding: 3 }}>
      {/* Header */}
      <Typography variant="h5" sx={{ mb: 1 }}>
        Actionable Insights
      </Typography>
      <Typography variant="body2" color="text.secondary" sx={{ mb: 3 }}>
        Personalized recommendations based on your business data
      </Typography>
      
      {/* Summary Numbers */}
      <Paper 
        variant="outlined" 
        sx={{ 
          p: 2, 
          mb: 3, 
          display: 'flex', 
          justifyContent: 'space-around',
          flexWrap: 'wrap',
          border: 'none',
          borderRadius: 2,
          bgcolor: 'white'
        }}
      >
        <Box sx={{ p: 1, textAlign: 'center' }}>
          <Typography variant="body2" color="text.secondary">Total Insights</Typography>
          <Typography variant="h4" color="primary">{availableInsightsCount}</Typography>
        </Box>
        
        <Divider orientation="vertical" flexItem sx={{ mx: 2 }} />
        
        <Box sx={{ p: 1, textAlign: 'center' }}>
          <Typography variant="body2" color="text.secondary">Categories</Typography>
          <Typography variant="h4" color="primary">{totalCategories}</Typography>
        </Box>
        
        <Divider orientation="vertical" flexItem sx={{ mx: 2 }} />
        
        <Box sx={{ p: 1, textAlign: 'center' }}>
          <Typography variant="body2" color="text.secondary">Last Updated</Typography>
          <Typography variant="h6" color="primary">Today</Typography>
        </Box>
      </Paper>
      
      {/* Accordion Layout */}
      <Box>
        {insightsAreas.map((insight, index) => {
          // Fetch the suggestion from the data object based on the area name
          const rawSuggestion = data.actionableInsightsData?.[insight.area] || 'Not enough reviews yet for the insights';
          const suggestion = sanitizeSuggestion(rawSuggestion);
          const hasSuggestion = suggestion !== 'Not enough reviews yet for the insights' && suggestion !== '';
          
          // Skip if no meaningful suggestion is available
          if (!hasSuggestion) {
            return null;
          }
          
          return (
            <Accordion 
              key={index}
              expanded={expanded === `panel${index}`}
              onChange={handleChange(`panel${index}`)}
              sx={{
                mb: 1,
                border: 'none',
                borderRadius: expanded === `panel${index}` ? 0 : 2,
                boxShadow: 'none',
                bgcolor: 'white',
                '&.Mui-expanded': {
                  mt: 0,
                  mb: 1,
                  '& .MuiAccordionSummary-root': {
                    borderBottomLeftRadius: 0,
                    borderBottomRightRadius: 0
                  }
                },
                '&:before': {
                  display: 'none' // Remove the default divider
                }
              }}
              disableGutters={true}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`panel${index}-content`}
                id={`panel${index}-header`}
                sx={{
                  backgroundColor: expanded === `panel${index}` ? insight.color : 'white',
                  color: expanded === `panel${index}` ? 'white' : 'inherit',
                  borderRadius: expanded === `panel${index}` ? '8px 8px 0 0' : 8,
                  mb: 0,
                  '& .MuiAccordionSummary-expandIconWrapper': {
                    color: expanded === `panel${index}` ? 'white' : 'inherit',
                  },
                  
                }}
              >
                <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                  <Avatar
                    sx={{
                      bgcolor: expanded === `panel${index}` ? 'rgba(255,255,255,0.2)' : insight.color,
                      color: expanded === `panel${index}` ? 'white' : 'white',
                      mr: 2
                    }}
                  >
                    {insight.icon}
                  </Avatar>
                  <Typography variant="h6" sx={{ flexGrow: 1 }}>
                    {insight.area}
                  </Typography>
                  <Chip 
                    label="1" 
                    size="small" 
                    sx={{ 
                      ml: 2,
                      bgcolor: expanded === `panel${index}` ? 'rgba(255,255,255,0.2)' : 'rgba(0,0,0,0.08)',
                      color: expanded === `panel${index}` ? 'white' : 'inherit'
                    }} 
                  />
                </Box>
              </AccordionSummary>
              
              <AccordionDetails 
                sx={{ 
                  p: 3, 
                  bgcolor: 'white', 
                  borderBottomLeftRadius: 8,
                  borderBottomRightRadius: 8,
                  borderLeft: '1px solid #e0e0e0',
                  borderRight: '1px solid #e0e0e0',
                  borderBottom: '1px solid #e0e0e0'
                }}
              >
                <Typography variant="body1" paragraph>
                  {suggestion}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Analysis of recent data indicates this could improve your {insight.area.toLowerCase()} metrics.
                </Typography>
              </AccordionDetails>
            </Accordion>
          );
        })}
      </Box>
    </Box>
  );
};

export default ActionableInsights;