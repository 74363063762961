import React from 'react';
import { 
  Paper, 
  Typography, 
  Box, 
  Divider, 
  LinearProgress,
  Avatar,
  AvatarGroup,
  Tooltip,
  Chip,
  Badge,
  Card,
  CardContent
} from '@mui/material';
import { 
  BarChart as BarChartIcon,
  Person as PersonIcon,
  PeopleAlt as PeopleAltIcon,
  CompareArrows as CompareArrowsIcon,
  Timeline as TimelineIcon,
  SignalCellularAlt as SignalIcon,
  Fingerprint as FingerprintIcon,
  Speed as SpeedIcon,
  Today as TodayIcon
} from '@mui/icons-material';
import { Chart } from 'react-google-charts';

// Trust Score Distribution Component
const TrustScoreDistribution = ({ userData }) => {
  // Create distribution data from actual user data
  const calculateTrustDistribution = () => {
    // Check if userData and userDetails exist and are not empty
    if (!userData || !userData.myCurrentNetworkList || !userData.myCurrentNetworkList.userDetails || userData.myCurrentNetworkList.userDetails.length === 0) {
      return [
        ['Trust Score Range', 'Number of Connections'],
        ['50-59', 0],
        ['60-69', 0],
        ['70-79', 0],
        ['80-89', 0],
        ['90-100', 0]
      ];
    }

    // Initialize distribution buckets
    const distribution = {
      '50-59': 0,
      '60-69': 0,
      '70-79': 0,
      '80-89': 0,
      '90-100': 0
    };

    // Count users in each trust score range
    userData.myCurrentNetworkList.userDetails.forEach(user => {
      const trustScore = user.trustScore;
      
      if (trustScore >= 90) {
        distribution['90-100']++;
      } else if (trustScore >= 80) {
        distribution['80-89']++;
      } else if (trustScore >= 70) {
        distribution['70-79']++;
      } else if (trustScore >= 60) {
        distribution['60-69']++;
      } else if (trustScore >= 50) {
        distribution['50-59']++;
      }
    });

    // Convert to array format for Google Charts
    const chartData = [['Trust Score Range', 'Number of Connections']];
    Object.entries(distribution).forEach(([range, count]) => {
      chartData.push([range, count]);
    });

    return chartData;
  };

  const distributionData = calculateTrustDistribution();
  
  // Find the most common trust score range
  const dataRows = distributionData.slice(1);
  const mostCommonRange = dataRows.reduce(
    (prev, current) => (current[1] > prev[1] ? current : prev),
    ['', 0]
  );

  return (
    <Paper variant='outlined' sx={{ p: 3, mb: 2, border: 'none' }}>
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
        <BarChartIcon sx={{ color: 'primary.main', mr: 1 }} />
        <Typography variant="h6">Trust Score Distribution</Typography>
      </Box>
      
      {/* Bar chart using Google Charts */}
      <Chart
        chartType="BarChart"
        data={distributionData}
        options={{
          legend: { position: 'none' },
          colors: ['#1976d2'],
          hAxis: { title: 'Connections' },
          vAxis: { title: 'Trust Score Range', minValue: 0 },
          
        }}
        width="100%"
        height="200px"
      />
      
      <Box sx={{ mt: 2, p: 2, bgcolor: 'grey.50', borderRadius: 1 }}>
        {dataRows.every(item => item[1] === 0) ? (
          <Typography variant="body2" color="text.secondary">
            You don't have any connections yet. Start building your network to see trust score distribution.
          </Typography>
        ) : (
          <Typography variant="body2" color="text.secondary">
            {mostCommonRange[1] > 0 
              ? `Most of your network connections have trust scores in the range of ${mostCommonRange[0]}, indicating a ${mostCommonRange[0] === '80-89' || mostCommonRange[0] === '90-100' ? 'reliable' : 'developing'} network.`
              : 'Start building your network to see trust score insights.'}
          </Typography>
        )}
      </Box>
    </Paper>
  );
};

// Network Comparison Component
const NetworkComparison = ({ userData }) => {
    // Calculate actual network average from user data
    const calculateNetworkStats = () => {
      const platformAvg = 72; // This is the assumed platform average
      
      // Default values if no data is available
      let yourNetworkAvg = 0;
      let percentile = 0;
      
      // Check if userData and userDetails exist and are not empty
      if (userData && userData.myCurrentNetworkList && 
          userData.myCurrentNetworkList.userDetails && 
          userData.myCurrentNetworkList.userDetails.length > 0) {
        
        // Calculate average trust score of user's network
        const totalScore = userData.myCurrentNetworkList.userDetails.reduce(
          (sum, user) => sum + (user.trustScore || 0), 
          0
        );
        
        yourNetworkAvg = Math.round(totalScore / userData.myCurrentNetworkList.userDetails.length);
        
        // Simple percentile calculation (this is a simplified version)
        // In a real implementation, you might want to get this from the server
        // or have a more sophisticated calculation based on actual distribution
        if (yourNetworkAvg > platformAvg) {
          // If user's average is higher than platform average,
          // calculate a percentile between 51 and 99
          percentile = 50 + Math.round((yourNetworkAvg - platformAvg) / (100 - platformAvg) * 49);
        } else {
          // If user's average is lower than or equal to platform average,
          // calculate a percentile between 1 and 50
          percentile = Math.round((yourNetworkAvg / platformAvg) * 50);
        }
      }
      
      return {
        yourNetworkAvg,
        platformAvg,
        percentile
      };
    };
  
    const networkStats = calculateNetworkStats();
  
    return (
      <Paper variant='outlined' sx={{ p: 3, mb: 2, border: 'none' }}>
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
          <CompareArrowsIcon sx={{ color: 'primary.main', mr: 1 }} />
          <Typography variant="h6">Network Comparison</Typography>
        </Box>
        
        <Box sx={{ mb: 3 }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
            <Typography variant="body2">Your Network Average</Typography>
            <Typography variant="body2" fontWeight="bold">{networkStats.yourNetworkAvg}</Typography>
          </Box>
          <LinearProgress 
            variant="determinate" 
            value={networkStats.yourNetworkAvg} 
            sx={{ height: 10, borderRadius: 5, mb: 2 }} 
          />
          
          <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
            <Typography variant="body2">Platform Average</Typography>
            <Typography variant="body2" fontWeight="bold">{networkStats.platformAvg}</Typography>
          </Box>
          <LinearProgress 
            variant="determinate" 
            value={networkStats.platformAvg} 
            sx={{ height: 10, borderRadius: 5, bgcolor: 'grey.300' }} 
            color="secondary"
          />
        </Box>
        
        {userData && userData.myCurrentNetworkList && userData.myCurrentNetworkList.userDetails && userData.myCurrentNetworkList.userDetails.length > 0 ? (
          <Box sx={{ 
            textAlign: 'center', 
            p: 2, 
            bgcolor: networkStats.percentile > 75 ? 'success.light' : networkStats.percentile > 50 ? 'primary.light' : networkStats.percentile > 25 ? 'warning.light' : 'error.light', 
            color: 'white', 
            borderRadius: 1 
          }}>
            <Typography variant="h5">Top {networkStats.percentile}%</Typography>
            <Typography variant="body2">
              {networkStats.percentile > 75 ? 'Your network trust quality is exceptional!' : 
               networkStats.percentile > 50 ? 'Your network trust quality is above average' : 
               networkStats.percentile > 25 ? 'Your network trust quality needs some improvement' : 
               'Your network trust quality needs significant improvement'}
            </Typography>
          </Box>
        ) : (
          <Box sx={{ textAlign: 'center', p: 2, bgcolor: 'grey.100', borderRadius: 1 }}>
            <Typography variant="body2" color="text.secondary">
              You don't have any connections yet. Start building your network to see how it compares.
            </Typography>
          </Box>
        )}
      </Paper>
    );
  };

// Connection Depth Breakdown Component
const ConnectionDepthBreakdown = () => {
  // Data for pie chart
  const connectionDepthData = [
    ['Connection Type', 'Count'],
    ['1st Degree', 7],
    ['2nd Degree', 15],
    ['3rd Degree', 28],
  ];

  return (
    <Paper variant='outlined' sx={{ p: 3, mb: 2, border: 'none' }}>
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
        <PersonIcon sx={{ color: 'primary.main', mr: 1 }} />
        <Typography variant="h6">Connection Depth</Typography>
      </Box>
      
      <Chart
        chartType="PieChart"
        data={connectionDepthData}
        options={{
          pieHole: 0.4,
          legend: { position: 'right' },
          colors: ['#1976d2', '#64b5f6', '#bbdefb'],
          chartArea: { width: '80%', height: '80%' },
        }}
        width="100%"
        height="200px"
      />
      
      <Box sx={{ mt: 2 }}>
        <Typography variant="body2" color="text.secondary">
          You have a well-distributed network with strong extended reach. Adding more 1st-degree connections could strengthen your direct influence.
        </Typography>
      </Box>
    </Paper>
  );
};

// Trust Rating Comparison Component
const TrustRatingComparison = () => {
  // Data for connections trust ratings
  const connections = [
    { name: 'Felix Baxter', score: 89, growth: '+5' },
    { name: 'Youko Curry', score: 89, growth: '+3' },
    { name: 'Allison Hardin', score: 89, growth: '+7' },
    { name: 'Noel Everett', score: 89, growth: '+2' },
    { name: 'Wilma Gibson', score: 89, growth: '0' }
  ];

  return (
    <Paper variant='outlined' sx={{ p: 3, mb: 2, border: 'none' }}>
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
        <SignalIcon sx={{ color: 'primary.main', mr: 1 }} />
        <Typography variant="h6">Trust Rating Growth</Typography>
      </Box>
      
      {connections.map((connection, index) => (
        <Box key={index} sx={{ mb: 2, pb: 2, borderBottom: index < connections.length - 1 ? '1px solid #eee' : 'none' }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography variant="body2">{connection.name}</Typography>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Typography variant="body2" fontWeight="bold">{connection.score}</Typography>
              <Chip 
                label={connection.growth} 
                size="small" 
                color={connection.growth.startsWith('+') ? 'success' : 'default'} 
                variant="outlined"
                sx={{ ml: 1, height: 20, fontSize: '0.7rem' }}
              />
            </Box>
          </Box>
        </Box>
      ))}
      
      <Box sx={{ mt: 2, p: 2, bgcolor: 'grey.50', borderRadius: 1 }}>
        <Typography variant="body2" color="text.secondary">
          Your connections are maintaining consistent trust scores. Encourage more network activity to boost growth.
        </Typography>
      </Box>
    </Paper>
  );
};

// Connection Longevity Component
const ConnectionLongevity = ({ userData }) => {
    // Calculate connection longevity distribution
    const calculateLongevityDistribution = () => {
      // Default data structure for chart
      const defaultDistribution = [
        ['Time Period', 'Connections'],
        ['< 1 month', 0],
        ['1-3 months', 0],
        ['3-6 months', 0],
        ['6-12 months', 0],
        ['> 12 months', 0]
      ];
      
      // If no firstDegreeFriends data, return default empty distribution
      if (!userData || !userData.firstDegreeFriends || userData.firstDegreeFriends.length === 0) {
        return defaultDistribution;
      }
      
      // Initialize counters for each time period
      const distribution = {
        'less1month': 0,
        '1to3months': 0,
        '3to6months': 0,
        '6to12months': 0,
        'more12months': 0
      };
      
      // Current date for calculating the time difference
      const currentDate = new Date();
      
      // Process each connection
      userData.firstDegreeFriends.forEach(connection => {
        if (!connection.creationTime) return; // Skip if no creation time
        
        const connectionDate = new Date(connection.creationTime);
        const diffTime = currentDate - connectionDate;
        const diffDays = diffTime / (1000 * 60 * 60 * 24);
        const diffMonths = diffDays / 30; // Approximate months
        
        if (diffMonths < 1) {
          distribution.less1month++;
        } else if (diffMonths < 3) {
          distribution['1to3months']++;
        } else if (diffMonths < 6) {
          distribution['3to6months']++;
        } else if (diffMonths < 12) {
          distribution['6to12months']++;
        } else {
          distribution.more12months++;
        }
      });
      
      // Format data for Google Charts
      return [
        ['Time Period', 'Connections'],
        ['< 1 month', distribution.less1month],
        ['1-3 months', distribution['1to3months']],
        ['3-6 months', distribution['3to6months']],
        ['6-12 months', distribution['6to12months']],
        ['> 12 months', distribution.more12months]
      ];
    };
    
    const longevityData = calculateLongevityDistribution();
    
    // Determine most common time period
    const dataRows = longevityData.slice(1);
    const mostCommonPeriod = dataRows.reduce(
      (prev, current) => (current[1] > prev[1] ? current : prev),
      ['', 0]
    );
    
    // Calculate total connections
    const totalConnections = dataRows.reduce((sum, row) => sum + row[1], 0);
    
    // Generate insight based on the distribution
    const generateInsight = () => {
      if (totalConnections === 0) {
        return "You don't have any connections yet. Start building your network!";
      }
      
      const recentPercentage = ((longevityData[1][1] + longevityData[2][1]) / totalConnections) * 100;
      const olderPercentage = ((longevityData[4][1] + longevityData[5][1]) / totalConnections) * 100;
      
      if (recentPercentage > 70) {
        return "Your network is very new. Focus on strengthening these recent connections to build lasting relationships.";
      } else if (olderPercentage > 70) {
        return "You have a mature network with many long-term connections. Consider expanding with some new connections to bring fresh perspectives.";
      } else if (mostCommonPeriod[0] === '3-6 months' || mostCommonPeriod[0] === '6-12 months') {
        return "Your network has a healthy balance of developing relationships. These middle-stage connections are ideal for deeper engagement.";
      } else {
        return `Most of your connections have been established for ${mostCommonPeriod[0]}, indicating a ${mostCommonPeriod[0] === '> 12 months' ? 'mature' : 'developing'} network.`;
      }
    };
  
    return (
      <Paper variant='outlined' sx={{ p: 3, mb: 2, border: 'none' }}>
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
          <TimelineIcon sx={{ color: 'primary.main', mr: 1 }} />
          <Typography variant="h6">Connection Longevity</Typography>
        </Box>
        
        {totalConnections > 0 ? (
          <Chart
            chartType="AreaChart"
            data={longevityData}
            options={{
              legend: { position: 'none' },
              colors: ['#1976d2'],
              hAxis: { title: 'Time Connected' },
              vAxis: { title: 'Connections', minValue: 0 },
              areaOpacity: 0.3,
            }}
            width="100%"
            height="200px"
          />
        ) : (
          <Box sx={{ height: 200, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Typography variant="body2" color="text.secondary">
              No connection data to display
            </Typography>
          </Box>
        )}
        
        <Box sx={{ mt: 2, p: 2, bgcolor: 'grey.50', borderRadius: 1 }}>
          <Typography variant="body2" color="text.secondary">
            {generateInsight()}
          </Typography>
        </Box>
      </Paper>
    );
  };

// Connection Strength Component
const ConnectionStrength = () => {
  // Data for connection strength
  const connections = [
    { name: 'Felix Baxter', strength: 85, indicator: 'High', interactions: 23 },
    { name: 'Noel Everett', strength: 72, indicator: 'Medium', interactions: 15 },
    { name: 'Youko Curry', strength: 91, indicator: 'Very High', interactions: 30 },
    { name: 'Wilma Gibson', strength: 63, indicator: 'Medium', interactions: 12 },
    { name: 'Isaac Benjamin', strength: 54, indicator: 'Low', interactions: 5 }
  ];

  const getStrengthColor = (strength) => {
    if (strength >= 80) return 'success';
    if (strength >= 60) return 'primary';
    return 'error';
  };

  return (
    <Paper variant='outlined' sx={{ p: 3, border: 'none' }}>
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
        <FingerprintIcon sx={{ color: 'primary.main', mr: 1 }} />
        <Typography variant="h6">Connection Strength</Typography>
      </Box>
      
      {connections.map((connection, index) => (
        <Card 
          key={index} 
          variant="outlined" 
          sx={{ mb: 2, bgcolor: 'grey.50', border: 'none' }}
        >
          <CardContent sx={{ p: 2, '&:last-child': { pb: 2 } }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 1 }}>
              <Typography variant="body2" fontWeight="medium">{connection.name}</Typography>
              <Chip 
                label={connection.indicator} 
                size="small" 
                color={getStrengthColor(connection.strength)} 
                variant="outlined"
                sx={{ height: 20, fontSize: '0.7rem' }}
              />
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <LinearProgress 
                variant="determinate" 
                value={connection.strength} 
                sx={{ 
                  height: 6, 
                  borderRadius: 3, 
                  flexGrow: 1,
                  bgcolor: 'grey.300'
                }}
                color={getStrengthColor(connection.strength)}
              />
              <Badge 
                badgeContent={connection.interactions} 
                color="primary"
                sx={{ '& .MuiBadge-badge': { fontSize: '0.7rem', height: 16, minWidth: 16 } }}
              >
                <SpeedIcon fontSize="small" color="action" />
              </Badge>
            </Box>
          </CardContent>
        </Card>
      ))}
      
      <Box sx={{ mt: 2, p: 2, bgcolor: 'primary.light', color: 'white', borderRadius: 1 }}>
        <Typography variant="body2" fontWeight="medium">Connection Strength Tip</Typography>
        <Typography variant="body2" sx={{ mt: 1 }}>
          Engage more with lower-strength connections to build stronger relationships and improve your network quality.
        </Typography>
      </Box>
    </Paper>
  );
};

// Main Network Insights Column Component
const NetworkInsightsColumn = ({ userData }) => {
  return (
    <>
      <TrustScoreDistribution userData={userData} />
      <NetworkComparison userData={userData}/>
      <ConnectionLongevity userData={userData} />
    </>
  );
};

export default NetworkInsightsColumn;