import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Grid, Box, Paper, CircularProgress, Skeleton } from '@mui/material';
import { useNavigate, useParams } from "react-router-dom";
import BusinessInfo from './BusinessInfo';
import BusinessLinks from './BusinessLinks';
import ActionableInsights from './ActionableInsights';
import ComparativeAnalysis from './ComparativeAnalysis';
import KPIs from './KPIs';
import SentimentAnalysis from './SentimentAnalysis';
import ReviewAnalysis from './ReviewAnalysis';
import NoDataMessage from './NoDataMessage';
import { useGetBusinessStatsQuery } from '../../redux/thunks/sp/getbusinessstats';

const BusinessAdminPage = () => {
  const { businessId } = useParams(); // Extract businessId from URL params
  const token = useSelector((state) => state.auth.token);

  const [activeContent, setActiveContent] = useState('kpis');
  const [businessStatsData, setBusinessStatsData] = useState('');
  const [missingDataSections, setMissingDataSections] = useState({
    all: false,
    kpis: false,
    sentiment: false,
    reviews: false,
    comparison: false,
    engagement: false
  });

  const args = {
    authType: 'token',
    token: token,
    businessId: businessId
  };
  
  const { data: businessStats, error: statsError, isLoading: statsLoading } = useGetBusinessStatsQuery(args);
  console.log('businessStats', businessStats)

 

  useEffect(() => {
    if (businessStats) {
      // Handle explicit "No reviews yet" message
      if (businessStats.message === 'No reviews yet') {
        setMissingDataSections({
          all: true,
          kpis: true,
          sentiment: true,
          reviews: true,
          comparison: true,
          engagement: true
        });
        return;
      }
      
      // Store the business stats data
      setBusinessStatsData(businessStats);
      
      // Check for missing sections
      const newMissingDataSections = {
        all: false,
        kpis: !businessStats.businessKPIsData,
        sentiment: !businessStats.sentimentData,
        reviews: !businessStats.reviewAnalysisData,
        comparison: !businessStats.comparativeAnalysisData, // Assuming this is the property name
        engagement: !businessStats.actionableInsightsData
      };
      
      // If all sections are missing, set all to true
      if (
        newMissingDataSections.kpis &&
        newMissingDataSections.sentiment &&
        newMissingDataSections.reviews &&
        newMissingDataSections.comparison &&
        newMissingDataSections.engagement
      ) {
        newMissingDataSections.all = true;
      }
      
      setMissingDataSections(newMissingDataSections);
      
      // If current active tab has no data, switch to a tab that has data (if any)
      if (newMissingDataSections[activeContent] && !newMissingDataSections.all) {
        const availableTabs = Object.keys(newMissingDataSections)
          .filter(key => key !== 'all' && !newMissingDataSections[key]);
        
        if (availableTabs.length > 0) {
          setActiveContent(availableTabs[0]);
        }
      }
    }
  }, [businessStats, activeContent]);

   // Create skeleton placeholders for loading state
   const renderSkeletons = () => (
    <Box sx={{ width: '100%' }}>
      {/* First Row - Four card skeletons */}
      <Grid container spacing={2} sx={{ mb: 2 }}>
        {[...Array(4)].map((_, index) => (
          <Grid item xs={12} sm={3} key={`top-skeleton-${index}`}>
            <Skeleton variant="rounded" height={100} sx={{ borderRadius: 2 }} />
          </Grid>
        ))}
      </Grid>
      
      {/* Second Row - Three chart skeletons */}
      <Grid container spacing={2} sx={{ mb: 2 }}>
        {[...Array(3)].map((_, index) => (
          <Grid item xs={12} sm={4} key={`chart-skeleton-${index}`}>
            <Skeleton variant="rounded" height={250} sx={{ borderRadius: 2 }} />
          </Grid>
        ))}
      </Grid>
      
      {/* Third Row - Two larger skeletons */}
      <Grid container spacing={2}>
        {[...Array(2)].map((_, index) => (
          <Grid item xs={12} sm={6} key={`bottom-skeleton-${index}`}>
            <Skeleton variant="rounded" height={200} sx={{ borderRadius: 2 }} />
          </Grid>
        ))}
      </Grid>
    </Box>
  );

  const renderContent = () => {
    // Only render actual content when not loading
    if (statsLoading) {
      return renderSkeletons();
    }
    if (missingDataSections[activeContent]) {
      return (
        <NoDataMessage 
          message={
            missingDataSections.all 
              ? "We're still gathering insights for this business. Check back soon as we analyze your reviews and customer interactions." 
              : `We're still compiling data for the ${activeContent === 'kpis' ? 'KPIs' : 
                activeContent === 'sentiment' ? 'Sentiment Analysis' : 
                activeContent === 'reviews' ? 'Review Analysis' : 
                activeContent === 'comparison' ? 'Comparison & Benchmark' : 
                'Actionable Insights'} section. Please check other tabs or come back later.`
          }
        />
      );
    }
    
    switch (activeContent) {
      case 'kpis':
        return <KPIs data={businessStatsData} />;
      case 'sentiment':
        return <SentimentAnalysis data={businessStatsData} />;
      case 'reviews':
        return <ReviewAnalysis data={businessStatsData} />;
      case 'comparison':
        return <ComparativeAnalysis data={businessStatsData} />;
      case 'engagement':
        return <ActionableInsights data={businessStatsData}/>;
      default:
        return <KPIs data={businessStatsData}/>;
    }
  };

  

  return (
    <Box maxWidth={1280} mx="auto" sx={{ flexGrow: 1, p: 2 }}>
      <Grid container spacing={2}>
        {/* Left Column */}
        <Grid item xs={12} sm={4} md={3}>
          {statsLoading ? (
            <>
              <Skeleton variant="rounded" height={200} sx={{ borderRadius: 2, mb: 2 }} />
              <Skeleton variant="rounded" height={300} sx={{ borderRadius: 2 }} />
            </>
          ) : (
            <>
              <BusinessInfo />
              <BusinessLinks 
                setActiveContent={setActiveContent} 
                disabledLinks={missingDataSections}
                activeContent={activeContent}
              />
            </>
          )}
        </Grid>
        
        {/* Right Column */}
        <Grid item xs={12} sm={8} md={9}>
          <Paper variant="outlined" sx={{ padding: statsLoading ? 2 : 0, border: 'none', mb: 1, borderRadius: 2, bgcolor: '#F8F9F9' }}>
            {renderContent()}
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default BusinessAdminPage;