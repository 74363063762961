
import {userApi} from './user'


export const getNetworkStatsApi = userApi.injectEndpoints({
  endpoints: (builder) => ({
    getNetworkStats: builder.query({
      query: () => `/networkstats`,
    }),
    

  }),
  overrideExisting: false,
})

export const { useGetNetworkStatsQuery, usePrefetch } = getNetworkStatsApi