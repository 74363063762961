import { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux'; // For auth state
import { useAuthenticator } from '@aws-amplify/ui-react';
import {
  Container,
  Grid,
  Paper,
  Typography,
  Box,
  TextField,
  Button,
  Avatar,
  IconButton,
  Card,
  CardContent,
  Chip,
  Divider,
  Skeleton,
  MenuItem
} from '@mui/material';
import {
  PhotoCamera as PhotoCameraIcon,
  SearchOutlined as SearchIcon,
  BusinessCenter as BusinessIcon,
  RateReview as ReviewIcon,
  Psychology as AIIcon,
  LocationOn as LocationIcon,
  Link as LinkIcon,
  Phone as PhoneIcon,
  Settings as SettingsIcon,
  AddBusinessOutlined as AddBusinessIcon,
} from '@mui/icons-material';

import PlacesAutocomplete from 'react-places-autocomplete';
import { useGetPreSignedUrlQuery } from '../../redux/thunks/files/filespresignurl';
import { useGetUserProfileQuery, useUpdateUserProfileMutation } from '../../redux/thunks/users/userprofile';
import { DatePicker } from '@mui/x-date-pickers';




// Mock Data
const USER_GOALS = [
  {
    id: 'discover',
    icon: SearchIcon,
    title: 'Discover Trusted Businesses',
    description: 'Find and read verified reviews from your network',
  },
  {
    id: 'request',
    icon: ReviewIcon,
    title: 'Request Reviews',
    description: 'Get insights about products or services not yet on Revfer',
  },
  {
    id: 'business',
    icon: BusinessIcon,
    title: 'Business Owner',
    description: 'Manage your business presence and gather trusted reviews',
  },
  {
    id: 'ai',
    icon: AIIcon,
    title: 'AI Business Insights',
    description: 'Leverage AI to understand your business performance and trends',
  },
];

const NEXT_STEPS = [
  {
    icon: LinkIcon,
    title: 'Connect Network',
    description: 'Import trusted connections',
    navigation: 'mynetwork'
  },
  {
    icon: SearchIcon,
    title: 'Find a Business',
    description: 'Find trusted businesses easily',
    navigation: 'find'
  },
  {
    icon: AddBusinessIcon,
    title: 'Add your Business',
    description: 'Showcase and grow business.',
    navigation: 'company/new'
  },
  
];

const PLATFORM_BENEFITS = [
  'Trust-based recommendations',
  'Verified business reviews',
  'AI-powered insights',
  'Suggestions improve with usage',
  'Network-driven discovery',
  'Empowering small business growth.',
];

// Skeleton Components
const ProfilePreviewSkeleton = () => (
  <Box sx={{ p: 2 }}>
    <Skeleton variant="text" width="60%" height={32} />
    <Skeleton variant="circular" width={120} height={120} sx={{ mx: 'auto', my: 2 }} />
    <Skeleton variant="text" width="80%" sx={{ mx: 'auto' }} />
    <Skeleton variant="text" width="60%" sx={{ mx: 'auto' }} />
  </Box>
);

const ContentCardSkeleton = () => (
  <Box sx={{ p: 2 }}>
    <Skeleton variant="text" width="40%" height={32} sx={{ mb: 2 }} />
    <Skeleton variant="rectangular" height={100} sx={{ mb: 2 }} />
    <Skeleton variant="rectangular" height={100} sx={{ mb: 2 }} />
    <Skeleton variant="rectangular" height={100} />
  </Box>
);

// Main Component
export default function OnboardingPage() {
  const navigate = useNavigate();

  const user = useSelector((state) => state.auth?.user || '');
  const userId = useSelector((state) => state.auth?.userId || null);
  console.log('user', user)

  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    lastName: '',
    location: '',
    selectedGoals: [],
    photo: '',
    dob: new Date(),
    gender: '', // Include Sex

  });
  const [profileImage, setProfileImage] = useState(null);
  

  const args = {
    eventType: 'UserProfilePhoto',
    userId
  }

  const { data: presignedUrlData, isLoading: isPresignedUrlLoading } = useGetPreSignedUrlQuery(args);
  console.log('presignedUrlData', presignedUrlData)
  const [editProfile, { isLoading: ProfileEditSaving }] = useUpdateUserProfileMutation();

  // Initialize name from user.displayName
  useEffect(() => {
    if (user?.firstName) {
      setFormData((prev) => ({ ...prev, name: user.firstName }));
    }
    if (user?.lastName) {
      setFormData((prev) => ({ ...prev, lastName: user.lastName }));
    }
  }, [user]);

  const uploadPhotoToS3 = async (file, presignedUrl) => {

    console.log('file', file)
    try {
      const response = await fetch(presignedUrl, {
        method: 'PUT',
        body: file,
        headers: {
          'Content-Type': file.type,
          'x-amz-meta-eventtype': 'UserProfilePhoto'
        },

      });

      if (!response.ok) {
        throw new Error('Failed to upload photo');
      }

      return true;
    } catch (error) {
      console.error('Error uploading photo:', error);
      throw error;
    }
  };



  const handleImageUpload = async (event) => {
    const file = event.target.files[0];
    if (file) {
      try {
        // Generate preview
        const reader = new FileReader();
        reader.onload = (e) => setProfileImage(e.target.result);
        reader.readAsDataURL(file);
  
        // Upload to S3 using pre-signed URL
        if (presignedUrlData?.url) {
          await uploadPhotoToS3(file, presignedUrlData.url);
  
          // Update formData with the photo URL
          const photoUrl = presignedUrlData.url.split('?')[0]; // Get the public URL
          setFormData((prev) => ({ ...prev, photo: photoUrl }));
        } else {
          console.error("Failed to fetch pre-signed URL.");
        }
      } catch (error) {
        console.error("Error uploading photo:", error);
      }
    }
  };

  const handleGoalSelection = (goalId) => {
    setFormData(prev => ({
      ...prev,
      selectedGoals: prev.selectedGoals.includes(goalId)
        ? prev.selectedGoals.filter(id => id !== goalId)
        : [...prev.selectedGoals, goalId]
    }));
  };

  const handleAddressChange = (address) => {
    setFormData((prev) => ({
      ...prev,
      location: address,
    }));
  };

  const handleAddressSelect = async (address) => {
    try {
      handleAddressChange(address);
      // Optional: Add geocoding here if needed
    } catch (error) {
      console.error('Error selecting address:', error);
    }
  };

  const handleSubmit = async () => {
    console.log('form data before submitting:',  formData)
    console.log('formData.dob.toISOString():',  formData.dob.toISOString().split('T')[0])
    setLoading(true);
  
    try {
      // Prepare current data in the required format
      const currentData = {
      firstName: formData.name || null, // Null if not filled
      gender: formData.gender,
      lastName: formData.lastName,
      address: formData.location || null,
      selectedGoals: formData.selectedGoals.length > 0 ? formData.selectedGoals : null,
      photoUrl: formData.photo || null,
      dateOfBirth: formData.dob
        ? new Date(formData.dob).toISOString().split('T')[0]
        : null, // Format as `YYYY-MM-DD` or set to null
    };

    // Filter out null or empty values
    const payload = Object.fromEntries(
      Object.entries(currentData).filter(([_, value]) => value !== null)
    );

    // Only send payload if there are fields to submit
    if (Object.keys(payload).length > 0) {
      await editProfile({ userId, payload });
    }

    navigate(`/earlyuserdashboard`);
    } catch (error) {
      console.error("Error submitting form:", error);
    } finally {
      setLoading(false);
    }
  };

  if (loading || isPresignedUrlLoading) {
    return (
      <Box>
        {/* Banner Skeleton */}
        <Paper sx={{ width: '100%', p: 3, mb: 3 }}>
          <Skeleton variant="text" width="50%" height={60} />
          <Skeleton variant="text" width="70%" height={40} />
        </Paper>

        <Container maxWidth="xl">
          <Grid container spacing={3}>
            <Grid item xs={12} md={3}>
              <Paper>
                <ProfilePreviewSkeleton />
              </Paper>
            </Grid>
            <Grid item xs={12} md={6}>
              <Paper>
                <ContentCardSkeleton />
              </Paper>
            </Grid>
            <Grid item xs={12} md={3}>
              <Paper>
                <ContentCardSkeleton />
              </Paper>
            </Grid>
          </Grid>
        </Container>
      </Box>
    );
  }

  return (
    <Box>
      {/* Welcome Banner */}
      <Paper 
        elevation={0} 
        sx={{ 
          width: '100%', 
          p: 3, 
          mb: 0, 
          borderRadius: 0,
          background: 'linear-gradient(45deg, #1976d2, #1565c0)', // Add blue gradient
          color: 'white', // White text
        }}
      >
        <Container maxWidth="xl">
          <Typography 
          variant="h3" 
          gutterBottom
          sx={{ 
            color: 'white',
            fontWeight: 500 
          }}
        >
            Welcome to Revfer!
          </Typography>
          <Typography variant="h6"  sx={{  color: 'rgba(255, 255, 255, 0.9)', fontWeight: 400 }}>
            Building trust through connections, one review at a time
          </Typography>
        </Container>
      </Paper>

      <Container maxWidth="xl"
       
       sx={{ 
         pt: 3, // Add some padding top instead of margin
         px: { xs: 2, sm: 3 } // Responsive padding
       }}
      >
        <Grid container spacing={3}>
          {/* Left Column - Profile Preview */}
          <Grid item xs={12} md={3}>
            <Paper variant="outlined" sx={{ p: 2, border: 'none', textAlign: 'center', mb: 2 }}>
              <Typography variant="h6" gutterBottom>
                Profile Preview
              </Typography>
              
              <Box sx={{ position: 'relative', width: 120, height: 120, mx: 'auto', mb: 2 }}>
                <Avatar
                  src={profileImage}
                  sx={{ width: 120, height: 120 }}
                />
                <IconButton
                  sx={{
                    position: 'absolute',
                    bottom: 0,
                    right: 0,
                    backgroundColor: 'primary.main',
                    '&:hover': { backgroundColor: 'primary.dark' },
                  }}
                  component="label"
                >
                  <input
                    hidden
                    accept="image/*"
                    type="file"
                    onChange={handleImageUpload}
                  />
                  <PhotoCameraIcon sx={{ color: 'white' }} />
                </IconButton>
              </Box>

              <Typography variant="subtitle1">
                {formData.name || 'Your Name'}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                New Member
              </Typography>
            </Paper>

            <Paper variant="outlined" sx={{ p: 2, border: 'none' }}>
              <Typography variant="h6" gutterBottom>
              What You Can Do
              </Typography>
              <Typography variant="body2" color="text.secondary" paragraph>
                Build connections to unlock:
              </Typography>
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
              {[
                'Build your trust network.',
                'Request reviews from peers.',
                'Discover top-rated businesses.',
                'Analyze business performance.',
                'Share your valuable insights.',
              ].map((item) => (
                  <Typography key={item} variant="body2" sx={{ display: 'flex', alignItems: 'center' }}>
                    • {item}
                  </Typography>
                ))}
              </Box>
            </Paper>
          </Grid>

          {/* Middle Column - Main Setup */}
          <Grid item xs={12} md={6}>
            <Paper variant="outlined" sx={{ p: 3, border: 'none' }}>
              <TextField
                fullWidth
                label="First Name"
                value={formData.name}
                onChange={(e) => setFormData(prev => ({ ...prev, name: e.target.value }))}
                sx={{ mb: 4 }}
              />
              <TextField
                fullWidth
                label="Last Name"
                value={formData.lastName}
                onChange={(e) => setFormData(prev => ({ ...prev, lastName: e.target.value }))}
                sx={{ mb: 4 }}
              />

              <Typography variant="h6" gutterBottom>
                Date of Birth
              </Typography>
              <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
                Providing your date of birth helps us offer more personalized insights.
              </Typography>
              
              <DatePicker
                label="Date of Birth"
                value={formData.dob}
                onChange={(newValue) => {
                  setFormData((prev) => ({ ...prev, dob: newValue }));
                }}
                renderInput={(params) => <TextField {...params} fullWidth variant="outlined"   />}
              />
            

              <Typography variant="h6" gutterBottom sx={{mt:2}}>
                Gender
              </Typography>
              <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
                This information improves the relevance of AI insights tailored to your needs.
              </Typography>
              <TextField
                select
                fullWidth
                label="Select"
                value={formData.gender}
                onChange={(e) => setFormData((prev) => ({ ...prev, gender: e.target.value }))}
                sx={{ mb: 4 }}
              >
                <MenuItem value="Male">Male</MenuItem>
                <MenuItem value="Female">Female</MenuItem>
                <MenuItem value="Other">Other</MenuItem>
              </TextField>

              <Typography variant="h6" gutterBottom>
                What are you looking for?
              </Typography>
              <Typography variant="body2" color="text.secondary" sx={{ mb: 3 }}>
                Select all that apply
              </Typography>

              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, mb: 4 }}>
                {USER_GOALS.map((goal) => {
                  const Icon = goal.icon;
                  const isSelected = formData.selectedGoals.includes(goal.id);

                  return (
                    <Paper
                      key={goal.id}
                      variant="outlined"
                      sx={{
                        p: 2,
                        cursor: 'pointer',
                        border: '1px solid',
                        borderColor: isSelected ? 'primary.main' : 'divider',
                        bgcolor: isSelected ? 'primary.lighter' : 'background.paper',
                      }}
                      onClick={() => handleGoalSelection(goal.id)}
                    >
                      <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                        <Icon color={isSelected ? 'primary' : 'action'} />
                        <Box>
                          <Typography variant="subtitle1">{goal.title}</Typography>
                          <Typography variant="body2" color="text.secondary">
                            {goal.description}
                          </Typography>
                        </Box>
                      </Box>
                    </Paper>
                  );
                })}
              </Box>

              <Typography variant="h6" gutterBottom>
                Location
              </Typography>
              <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
                For local recommendations and business connections
              </Typography>
              <PlacesAutocomplete
                value={formData.location || ''}
                onChange={handleAddressChange}
                onSelect={handleAddressSelect}
              >
                {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                  <div>
                    <TextField
                      {...getInputProps({
                        placeholder: 'Enter your location',
                        fullWidth: true,
                        variant: 'outlined',
                      })}
                      sx={{ mb: 2 }}
                    />
                    {suggestions.length > 0 && (
                      <Paper>
                        {loading && <div>Loading...</div>}
                        {suggestions.map((suggestion) => {
                          const { key, ...suggestionProps } = getSuggestionItemProps(suggestion, {
                            className: 'suggestion-item',
                          });
                          return (
                            <div
                              key={suggestion.placeId}
                              {...suggestionProps}
                              style={{
                                padding: '10px',
                                cursor: 'pointer',
                                borderBottom: '1px solid #e0e0e0',
                              }}
                            >
                              {suggestion.description}
                            </div>
                          );
                        })}
                      </Paper>
                    )}
                  </div>
                )}
              </PlacesAutocomplete>

              <Button
                variant="contained"
                size="large"
                onClick={handleSubmit}
                sx={{ minWidth: 200 }}
              >
                Get Started →
              </Button>
            </Paper>
          </Grid>

          {/* Right Column - Next Steps & Benefits */}
          <Grid item xs={12} md={3}>
            <Paper variant="outlined" sx={{ p: 2, border: 'none', mb: 2 }}>
              <Typography variant="h6" gutterBottom>
                Next Steps
              </Typography>
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                {NEXT_STEPS.map((step, index) => {
                  const Icon = step.icon;
                  return (
                    <Paper
                      key={index}
                      variant="outlined"
                      sx={{ p: 2, border: 'none', bgcolor: 'grey.50', cursor: 'pointer' }}
                      onClick={() => navigate(`/${step.navigation}`)}
                    >
                      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        <Icon color="action" />
                        <Box>
                          <Typography variant="subtitle2">{step.title}</Typography>
                          <Typography variant="body2" color="text.secondary">
                            {step.description}
                          </Typography>
                        </Box>
                      </Box>
                    </Paper>
                  );
                })}
              </Box>
            </Paper>

            <Paper variant="outlined" sx={{ p: 2, border: 'none' }}>
              <Typography variant="h6" gutterBottom>
                Why Revfer?
              </Typography>
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                {PLATFORM_BENEFITS.map((benefit, index) => (
                  <Typography key={index} variant="body2" sx={{ display: 'flex', alignItems: 'center' }}>
                    • {benefit}
                  </Typography>
                ))}
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}