import React from 'react';
import { Paper, List, ListItemButton, ListItemText, ListItemIcon, Box, Typography, Tooltip, Divider } from '@mui/material';
import AssessmentIcon from '@mui/icons-material/Assessment';
import SentimentVerySatisfiedIcon from '@mui/icons-material/SentimentVerySatisfied';
import ReviewsIcon from '@mui/icons-material/Reviews';
import CompareIcon from '@mui/icons-material/Compare';
import PeopleIcon from '@mui/icons-material/People';
import InfoIcon from '@mui/icons-material/Info';

const BusinessLinks = ({ setActiveContent, activeContent = 'kpis', disabledLinks = {} }) => {
  // Check if all sections are disabled
  const allDisabled = disabledLinks.all || false;
  
  const handleClick = (content) => {
    // Only allow navigation if the section isn't disabled
    if (!allDisabled && !disabledLinks[content]) {
      setActiveContent(content);
    }
  };

  // Navigation items configuration
  const navItems = [
    {
      id: 'kpis',
      label: 'Business KPIs',
      icon: <AssessmentIcon />,
      disabled: allDisabled || disabledLinks.kpis || false
    },
    {
      id: 'sentiment',
      label: 'Sentiment Analysis',
      icon: <SentimentVerySatisfiedIcon />,
      disabled: allDisabled || disabledLinks.sentiment || false
    },
    {
      id: 'reviews',
      label: 'Review Analysis',
      icon: <ReviewsIcon />,
      disabled: allDisabled || disabledLinks.reviews || false
    },
    {
      id: 'comparison',
      label: 'Comparison & Benchmark',
      icon: <CompareIcon />,
      disabled: allDisabled || disabledLinks.comparison || false
    },
    {
      id: 'engagement',
      label: 'Actionable Personal Insights',
      icon: <PeopleIcon />,
      disabled: allDisabled || disabledLinks.engagement || false
    }
  ];

  return (
    <Paper variant="outlined" sx={{ padding: '10px', border: 'none', mb: 1, borderRadius: 2 }}>
      {allDisabled && (
        <Box sx={{ p: 2, bgcolor: '#f5f5f5', borderRadius: 1, mb: 1 }}>
          <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
            <InfoIcon color="info" sx={{ mr: 1, fontSize: 20 }} />
            <Typography variant="subtitle2" color="text.secondary">
              Limited Data
            </Typography>
          </Box>
          <Typography variant="caption" color="text.secondary">
            Navigation is limited until we gather more data for your business.
          </Typography>
        </Box>
      )}

      {!allDisabled && Object.values(disabledLinks).some(Boolean) && (
        <Box sx={{ p: 2, bgcolor: '#f5f5f5', borderRadius: 1, mb: 1 }}>
          <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
            <InfoIcon color="info" sx={{ mr: 1, fontSize: 20 }} />
            <Typography variant="subtitle2" color="text.secondary">
              Partial Data Available
            </Typography>
          </Box>
          <Typography variant="caption" color="text.secondary">
            Some sections are still being compiled. Greyed-out sections are not yet available.
          </Typography>
        </Box>
      )}

      <List component="nav">
        {navItems.map((item) => (
          <Tooltip 
            key={item.id}
            title={item.disabled ? "Data for this section is still being compiled" : ""} 
            placement="right"
          >
            <div> {/* Wrapper div because disabled ListItemButton can't be a tooltip child */}
              <ListItemButton
                onClick={() => handleClick(item.id)}
                selected={activeContent === item.id}
                disabled={item.disabled}
                sx={{ 
                  opacity: item.disabled ? 0.5 : 1,
                  bgcolor: activeContent === item.id && !item.disabled ? 'rgba(63, 81, 181, 0.08)' : 'transparent',
                  borderLeft: activeContent === item.id && !item.disabled ? '4px solid #3f51b5' : '4px solid transparent',
                  paddingLeft: activeContent === item.id && !item.disabled ? 1.5 : 2,
                  '&.Mui-disabled': {
                    opacity: 0.5,
                    pointerEvents: 'all', // Enable tooltip on disabled items
                    cursor: 'not-allowed'
                  }
                }}
              >
                <ListItemIcon sx={{ 
                  color: activeContent === item.id && !item.disabled ? '#3f51b5' : 'inherit' 
                }}>
                  {item.icon}
                </ListItemIcon>
                <ListItemText primary={item.label} primaryTypographyProps={{
                  fontWeight: activeContent === item.id && !item.disabled ? 'medium' : 'normal',
                  color: activeContent === item.id && !item.disabled ? '#3f51b5' : 'inherit'
                }} />
              </ListItemButton>
            </div>
          </Tooltip>
        ))}
      </List>
    </Paper>
  );
};

export default BusinessLinks;