import React from 'react';
import { Grid, Card, CardContent, Typography, Box, Avatar, CircularProgress } from '@mui/material';
import { Chart } from 'react-google-charts';
import StarIcon from '@mui/icons-material/Star';
import RateReviewIcon from '@mui/icons-material/RateReview';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import PeopleIcon from '@mui/icons-material/People';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import ReplyIcon from '@mui/icons-material/Reply';
import ReviewsIcon from '@mui/icons-material/Reviews';
import BarChartIcon from '@mui/icons-material/BarChart';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import WcIcon from '@mui/icons-material/Wc';

const ReviewAnalysis = ({ data }) => {
  if (!data || !data.reviewAnalysisData) {
    // Show a loading spinner if data is not available
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
        <CircularProgress color="primary" />
      </Box>
    );
  }

  const reviewData = data.reviewAnalysisData;

  // Prepare data for charts
  const monthlyReviewData = [
    ['Month', 'Review Count', 'Average Star Rating'],
    ...reviewData.monthlyTrend.map((data) => [
      data.month,
      data.reviewCount,
      data.avgStarRating,
    ]),
  ];

  const ratingDistributionData = [
    ['Star Rating', 'Review Count'],
    ...Object.keys(reviewData.reviewDistributionByRating).map((rating) => [
      `${rating} Stars`,
      reviewData.reviewDistributionByRating[rating],
    ]),
  ];

  const hopsDistributionData = [
    ['Hops', 'Review Count'],
    ...Object.keys(reviewData.hopCountAll).map((hops) => [
      `Hop ${hops}`,
      reviewData.hopCountAll[hops],
    ]),
  ];

  const genderDistributionData = [
    ['Gender', 'Percentage'],
    ['Male', reviewData.genderDistribution.male],
    ['Female', reviewData.genderDistribution.female],
  ];

  const ageGroupDistributionData = [
    ['Age Group', 'Review Count'],
    ...Object.keys(reviewData.ageGroupDistribution).map((ageGroup) => [
      `${ageGroup}`,
      reviewData.ageGroupDistribution[ageGroup],
    ]),
  ];

  // Chart options for consistent styling
  const monthlyReviewOptions = {
    hAxis: { title: 'Month' },
    vAxes: {
      0: { title: 'Review Count' },
      1: { title: 'Average Star Rating', minValue: 0, maxValue: 5 },
    },
    seriesType: 'bars',
    series: {
      0: { color: '#3f51b5' },
      1: { type: 'line', targetAxisIndex: 1, color: '#FFC107' }
    },
    chartArea: { width: '80%', height: '70%' },
    backgroundColor: 'transparent',
    legend: { position: 'bottom' }
  };

  const ratingDistributionOptions = {
    hAxis: { title: 'Star Rating' },
    vAxis: { title: 'Review Count' },
    colors: ['#4CAF50'],
    legend: 'none',
    chartArea: { width: '80%', height: '70%' },
    backgroundColor: 'transparent'
  };

  const hopsDistributionOptions = {
    hAxis: { title: 'Hops' },
    vAxis: { title: 'Review Count' },
    colors: ['#9C27B0'],
    legend: 'none',
    chartArea: { width: '80%', height: '70%' },
    backgroundColor: 'transparent'
  };

  const genderDistributionOptions = {
    pieHole: 0.6,
    pieSliceText: 'none',
    legend: { position: 'right' },
    slices: {
      0: { color: '#2196F3' },
      1: { color: '#FF9800' }
    },
    chartArea: { width: '80%', height: '80%' },
    backgroundColor: 'transparent'
  };

  const ageGroupDistributionOptions = {
    hAxis: { title: 'Age Group' },
    vAxis: { title: 'Review Count' },
    colors: ['#FF5722'],
    legend: 'none',
    chartArea: { width: '80%', height: '70%' },
    backgroundColor: 'transparent'
  };

  return (
    <Box>
      {/* First Row - Key Metrics with accent colors */}
      <Grid container spacing={2} sx={{ mb: 2 }} alignItems="stretch">
        {/* Rating Card */}
        <Grid item xs={12} sm={3}>
          <Card 
            variant="outlined" 
            sx={{ 
              height: '100%', 
              position: 'relative', 
              overflow: 'visible',
              border: 'none', 
              borderRadius: 2,
              bgcolor: 'white'
            }}
          >
            <Box sx={{ position: 'absolute', left: 0, top: 0, bottom: 0, width: '8px', backgroundColor: '#FFC107', borderTopLeftRadius: 8, borderBottomLeftRadius: 8 }} />
            <CardContent>
              <Box display="flex" alignItems="center" justifyContent="space-between">
                <Box>
                  <Typography variant="caption" color="textSecondary">Rating</Typography>
                  <Typography variant="h4" sx={{ fontWeight: '500' }}>{reviewData.rating}</Typography>
                  <Typography variant="body2" color="textSecondary">Overall Star Rating</Typography>
                </Box>
                <Avatar sx={{ bgcolor: '#FFF9C4', color: '#FFC107', width: 56, height: 56 }}>
                  <StarIcon sx={{ fontSize: 32 }} />
                </Avatar>
              </Box>
            </CardContent>
          </Card>
        </Grid>

        {/* Review Count Card */}
        <Grid item xs={12} sm={3}>
          <Card 
            variant="outlined" 
            sx={{ 
              height: '100%', 
              position: 'relative', 
              overflow: 'visible',
              border: 'none', 
              borderRadius: 2,
              bgcolor: 'white'
            }}
          >
            <Box sx={{ position: 'absolute', left: 0, top: 0, bottom: 0, width: '8px', backgroundColor: '#3f51b5', borderTopLeftRadius: 8, borderBottomLeftRadius: 8 }} />
            <CardContent>
              <Box display="flex" alignItems="center" justifyContent="space-between">
                <Box>
                  <Typography variant="caption" color="textSecondary">Review Count</Typography>
                  <Typography variant="h4" sx={{ fontWeight: '500' }}>{reviewData.reviewCount}</Typography>
                  <Typography variant="body2" color="textSecondary">Total Reviews</Typography>
                </Box>
                <Avatar sx={{ bgcolor: '#E8EAF6', color: '#3f51b5', width: 56, height: 56 }}>
                  <RateReviewIcon sx={{ fontSize: 32 }} />
                </Avatar>
              </Box>
            </CardContent>
          </Card>
        </Grid>

        {/* Response Time Card */}
        <Grid item xs={12} sm={3}>
          <Card 
            variant="outlined" 
            sx={{ 
              height: '100%', 
              position: 'relative', 
              overflow: 'visible',
              border: 'none', 
              borderRadius: 2,
              bgcolor: 'white'
            }}
          >
            <Box sx={{ position: 'absolute', left: 0, top: 0, bottom: 0, width: '8px', backgroundColor: '#2196F3', borderTopLeftRadius: 8, borderBottomLeftRadius: 8 }} />
            <CardContent>
              <Box display="flex" alignItems="center" justifyContent="space-between">
                <Box>
                  <Typography variant="caption" color="textSecondary">Response Time</Typography>
                  <Typography variant="h4" sx={{ fontWeight: '500' }}>{reviewData.responseTime}</Typography>
                  <Typography variant="body2" color="textSecondary">Average Wait Time</Typography>
                </Box>
                <Avatar sx={{ bgcolor: '#E3F2FD', color: '#2196F3', width: 56, height: 56 }}>
                  <AccessTimeIcon sx={{ fontSize: 32 }} />
                </Avatar>
              </Box>
            </CardContent>
          </Card>
        </Grid>

        {/* Response Rate Card */}
        <Grid item xs={12} sm={3}>
          <Card 
            variant="outlined" 
            sx={{ 
              height: '100%', 
              position: 'relative', 
              overflow: 'visible',
              border: 'none', 
              borderRadius: 2,
              bgcolor: 'white'
            }}
          >
            <Box sx={{ position: 'absolute', left: 0, top: 0, bottom: 0, width: '8px', backgroundColor: '#4CAF50', borderTopLeftRadius: 8, borderBottomLeftRadius: 8 }} />
            <CardContent>
              <Box display="flex" alignItems="center" justifyContent="space-between">
                <Box>
                  <Typography variant="caption" color="textSecondary">Response Rate</Typography>
                  <Typography variant="h4" sx={{ fontWeight: '500' }}>{reviewData.responseRate}</Typography>
                  <Typography variant="body2" color="textSecondary">Reviews Responded</Typography>
                </Box>
                <Avatar sx={{ bgcolor: '#E8F5E9', color: '#4CAF50', width: 56, height: 56 }}>
                  <PeopleIcon sx={{ fontSize: 32 }} />
                </Avatar>
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      {/* Second Row - Charts */}
      <Grid container spacing={2} sx={{ mb: 2 }} alignItems="stretch">
        {/* Monthly Review Trend Chart */}
        <Grid item xs={12} sm={4}>
          <Card 
            variant="outlined" 
            sx={{ 
              height: '100%', 
              border: 'none', 
              borderRadius: 2,
              bgcolor: 'white'
            }}
          >
            <CardContent>
              <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                <TrendingUpIcon sx={{ color: '#3f51b5', mr: 1 }} />
                <Typography variant="h6">Monthly Review Trend</Typography>
              </Box>
              <Chart
                chartType="ComboChart"
                width="100%"
                height="250px"
                data={monthlyReviewData}
                options={monthlyReviewOptions}
              />
            </CardContent>
          </Card>
        </Grid>

        {/* Star Rating Distribution Chart */}
        <Grid item xs={12} sm={4}>
          <Card 
            variant="outlined" 
            sx={{ 
              height: '100%', 
              border: 'none', 
              borderRadius: 2,
              bgcolor: 'white'
            }}
          >
            <CardContent>
              <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                <StarIcon sx={{ color: '#4CAF50', mr: 1 }} />
                <Typography variant="h6">Rating Distribution</Typography>
              </Box>
              <Chart
                chartType="ColumnChart"
                width="100%"
                height="250px"
                data={ratingDistributionData}
                options={ratingDistributionOptions}
              />
            </CardContent>
          </Card>
        </Grid>

        {/* Hops Distribution Chart */}
        <Grid item xs={12} sm={4}>
          <Card 
            variant="outlined" 
            sx={{ 
              height: '100%', 
              border: 'none', 
              borderRadius: 2,
              bgcolor: 'white'
            }}
          >
            <CardContent>
              <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                <BarChartIcon sx={{ color: '#9C27B0', mr: 1 }} />
                <Typography variant="h6">Reviews by Hop Level</Typography>
              </Box>
              <Chart
                chartType="ColumnChart"
                width="100%"
                height="250px"
                data={hopsDistributionData}
                options={hopsDistributionOptions}
              />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      
      {/* Third Row - Demographics and Engagement */}
      <Grid container spacing={2} alignItems="stretch">
        {/* Engagement Stats Card */}
        <Grid item xs={12} sm={4}>
          <Card 
            variant="outlined" 
            sx={{ 
              height: '100%', 
              border: 'none', 
              borderRadius: 2,
              bgcolor: 'white'
            }}
          >
            <CardContent>
              <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                <ReviewsIcon sx={{ color: '#FF5722', mr: 1 }} />
                <Typography variant="h6">Review Engagement</Typography>
              </Box>
              
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                    <Avatar sx={{ bgcolor: '#FBE9E7', color: '#FF5722', width: 40, height: 40, mr: 1 }}>
                      <ThumbUpIcon sx={{ fontSize: 24 }} />
                    </Avatar>
                    <Box>
                      <Typography variant="body2" color="textSecondary">Likes</Typography>
                      <Typography variant="h6">{reviewData.likesDislikesReplies.likes}</Typography>
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                    <Avatar sx={{ bgcolor: '#E8F5E9', color: '#4CAF50', width: 40, height: 40, mr: 1 }}>
                      <ThumbDownIcon sx={{ fontSize: 24 }} />
                    </Avatar>
                    <Box>
                      <Typography variant="body2" color="textSecondary">Dislikes</Typography>
                      <Typography variant="h6">{reviewData.likesDislikesReplies.dislikes}</Typography>
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Avatar sx={{ bgcolor: '#E3F2FD', color: '#2196F3', width: 40, height: 40, mr: 1 }}>
                      <ReplyIcon sx={{ fontSize: 24 }} />
                    </Avatar>
                    <Box>
                      <Typography variant="body2" color="textSecondary">Replies</Typography>
                      <Typography variant="h6">{reviewData.likesDislikesReplies.replies}</Typography>
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Avatar sx={{ bgcolor: '#EDE7F6', color: '#673AB7', width: 40, height: 40, mr: 1 }}>
                      <RateReviewIcon sx={{ fontSize: 24 }} />
                    </Avatar>
                    <Box>
                      <Typography variant="body2" color="textSecondary">Unique Reviews</Typography>
                      <Typography variant="h6">{reviewData.likesDislikesReplies.uniqueReviews}</Typography>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        
        {/* Gender Distribution Chart */}
        <Grid item xs={12} sm={4}>
          <Card 
            variant="outlined" 
            sx={{ 
              height: '100%', 
              border: 'none', 
              borderRadius: 2,
              bgcolor: 'white'
            }}
          >
            <CardContent>
              <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                <WcIcon sx={{ color: '#2196F3', mr: 1 }} />
                <Typography variant="h6">Gender Distribution</Typography>
              </Box>
              <Chart
                chartType="PieChart"
                width="100%"
                height="250px"
                data={genderDistributionData}
                options={genderDistributionOptions}
              />
              <Box sx={{ textAlign: 'center', mt: 2 }}>
                <Typography variant="body2" color="textSecondary">
                  Male: {reviewData.genderDistribution.male}% · Female: {reviewData.genderDistribution.female}%
                </Typography>
              </Box>
            </CardContent>
          </Card>
        </Grid>
        
        {/* Age Group Distribution Chart */}
        <Grid item xs={12} sm={4}>
          <Card 
            variant="outlined" 
            sx={{ 
              height: '100%', 
              border: 'none', 
              borderRadius: 2,
              bgcolor: 'white'
            }}
          >
            <CardContent>
              <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                <PeopleIcon sx={{ color: '#FF5722', mr: 1 }} />
                <Typography variant="h6">Age Group Distribution</Typography>
              </Box>
              <Chart
                chartType="ColumnChart"
                width="100%"
                height="250px"
                data={ageGroupDistributionData}
                options={ageGroupDistributionOptions}
              />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ReviewAnalysis;