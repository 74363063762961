import React from 'react';
import {
  Box,
  Chip,
  Typography,
  Avatar,
  Rating,
  Grid,
  Divider,
  Paper,
  LinearProgress,
  Tooltip,
  CircularProgress,
} from '@mui/material';
import { Chart } from 'react-google-charts';

import {
  Star as StarIcon,
  HowToReg as HowToRegIcon,
  Visibility as VisibilityIcon,
  FollowTheSigns as FollowTheSignsIcon,
  TrendingUp as TrendingUpIcon,
  ShowChart as ShowChartIcon,
  CheckCircle as CheckCircleIcon,
  VerifiedUser as VerifiedUserIcon,
  People as PeopleIcon,
} from '@mui/icons-material';

const SkillStats = ({ skill, spId }) => {
  if (!skill) {
    return null; // Return null if skill is undefined
  }
  
  console.log('in skill stats, skill', skill);
  
  // Calculate business health score (0-100)
  // This is a simplified calculation that can be refined based on actual data distribution
  const calculateBusinessHealth = () => {
    // Define weights for each metric (can be adjusted)
    const weights = {
      reviews: 0.3,
      referrals: 0.3,
      follows: 0.2,
      pageViews: 0.2
    };
    
    // Get values with fallbacks
    const reviewCount = skill.stats?.reviews?.totalReviews || 0;
    const referralCount = skill.stats?.referrals?.totalReferrals || 0;
    const followCount = skill.stats?.follows?.totalFollows || 0;
    const viewCount = skill.stats?.pageViews?.totalPageViews || 0;
    
    // Define benchmark values (what would constitute a "perfect" score)
    // These should be calibrated based on your platform's typical metrics
    const benchmarks = {
      reviews: 10, // A business with 50+ reviews gets full points for this category
      referrals: 3, // 30+ referrals is excellent
      follows: 2, // 100+ follows is excellent
      pageViews: 10 // 500+ views is excellent
    };
    
    // Calculate score for each metric (capped at 100)
    const reviewScore = Math.min(100, (reviewCount / benchmarks.reviews) * 100);
    const referralScore = Math.min(100, (referralCount / benchmarks.referrals) * 100);
    const followScore = Math.min(100, (followCount / benchmarks.follows) * 100);
    const viewScore = Math.min(100, (viewCount / benchmarks.pageViews) * 100);
    
    // Calculate weighted average
    const healthScore = (
      (reviewScore * weights.reviews) +
      (referralScore * weights.referrals) +
      (followScore * weights.follows) +
      (viewScore * weights.pageViews)
    );
    
    return Math.round(healthScore);
  };
  
  const businessHealth = calculateBusinessHealth();
  
  // Helper function to get health color
  const getHealthColor = (score) => {
    if (score >= 80) return '#4caf50'; // Green
    if (score >= 60) return '#2196f3'; // Blue
    if (score >= 40) return '#ff9800'; // Orange
    return '#f44336'; // Red
  };
  
  // Helper function to get health status text
  const getHealthStatus = (score) => {
    if (score >= 80) return 'Excellent';
    if (score >= 60) return 'Good';
    if (score >= 40) return 'Fair';
    return 'Needs Improvement';
  };
  
  // Set up data for review source breakdown
  // For demo, using notConnectedCount and connectedCount 
  // (should be replaced with actual connected vs non-connected review counts)
  const notConnectedCount = skill.count_hops_traditional ? 
    skill.count_hops_traditional[0] || 0 : 0;
  const connectedCount = skill.count_hops_traditional ? 
    skill.count_hops_traditional.slice(1).reduce((acc, value) => acc + value, 0) : 0;
  
  const totalReviews = notConnectedCount + connectedCount;
  const connectedPercentage = totalReviews > 0 ? 
    Math.round((connectedCount / totalReviews) * 100) : 0;
  
  const reviewSourceData = [
    ['Review Type', 'Count'],
    ['Connected', connectedCount],
    ['Not Connected', notConnectedCount],
  ];
  
  const reviewSourceOptions = {
    title: 'Review Sources',
    pieHole: 0.4, // Makes it a donut chart
    colors: ['#4caf50', '#bbdefb'], // Green for connected, light blue for not connected
    legend: { position: 'bottom' },
    chartArea: { width: '85%', height: '70%' },
    pieSliceText: 'percentage',
  };
  
  // Achievement badges (could be dynamically determined)
  const achievementsBadges = [
    skill.verified ? 'Verified Business' : 'Just Started',
    connectedPercentage >= 50 ? 'Network Trusted' : null,
    skill.stats?.reviews?.totalReviews > 10 ? 'Well Reviewed' : null,
  ].filter(Boolean);

  return (
    <div>
      {/* Business Health Score */}
      <Box sx={{ mb: 3, textAlign: 'center' }}>
        <Typography variant="subtitle1" gutterBottom>
          Business Health Score
        </Typography>
        <Box sx={{ position: 'relative', display: 'inline-flex' }}>
          <CircularProgress
            variant="determinate"
            value={businessHealth}
            size={100}
            thickness={4}
            sx={{
              color: getHealthColor(businessHealth),
              circle: {
                strokeLinecap: 'round',
              },
            }}
          />
          <Box
            sx={{
              top: 0,
              left: 0,
              bottom: 0,
              right: 0,
              position: 'absolute',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Typography
              variant="h4"
              component="div"
              color={getHealthColor(businessHealth)}
              fontWeight="bold"
            >
              {businessHealth}
            </Typography>
          </Box>
        </Box>
        <Typography variant="body2" color="text.secondary" mt={1}>
          {getHealthStatus(businessHealth)}
        </Typography>
      </Box>
      
      <Divider sx={{ mb: 2 }} />
      
      {/* Review Source Breakdown */}
      <Box sx={{ mb: 3 }}>
        <Typography variant="subtitle2" gutterBottom>
          Review Source Breakdown
        </Typography>
        {totalReviews > 0 ? (
          <>
            <Chart
              chartType="PieChart"
              data={reviewSourceData}
              options={reviewSourceOptions}
              width="100%"
              height="200px"
            />
            <Box sx={{ mt: 1, textAlign: 'center' }}>
              <Typography variant="body2" color="text.secondary">
                {connectedPercentage}% from connected reviewers
              </Typography>
              <Tooltip title="Connected reviews tend to be more reliable as they come from users with established connections">
                <Typography variant="caption" color="primary" sx={{ cursor: 'help' }}>
                  Why does this matter?
                </Typography>
              </Tooltip>
            </Box>
          </>
        ) : (
          <Typography variant="body2" color="text.secondary" align="center" sx={{ py: 3 }}>
            No reviews yet
          </Typography>
        )}
      </Box>
      
      <Divider sx={{ mb: 2 }} />
      
      {/* Activity Metrics */}
      <Box sx={{ mb: 2, textAlign: 'left' }}>
        <Typography variant="subtitle2" gutterBottom>
          Activity Metrics
        </Typography>
        
        <Grid container spacing={2}>
          {/* First row */}
          <Grid item xs={6}>
            <Paper variant="outlined" sx={{ p: 1.5, height: '100%', bgcolor: 'background.default' }}>
              <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <StarIcon color="primary" sx={{ fontSize: 24, mb: 0.5 }} />
                <Typography variant="h6" color="primary" fontWeight="medium">
                  {skill.stats?.reviews?.totalReviews || 0}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Reviews
                </Typography>
              </Box>
            </Paper>
          </Grid>
          
          <Grid item xs={6}>
            <Paper variant="outlined" sx={{ p: 1.5, height: '100%', bgcolor: 'background.default' }}>
              <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <HowToRegIcon color="primary" sx={{ fontSize: 24, mb: 0.5 }} />
                <Typography variant="h6" color="primary" fontWeight="medium">
                  {skill.stats?.referrals?.totalReferrals || 0}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Referrals
                </Typography>
              </Box>
            </Paper>
          </Grid>
          
          {/* Second row */}
          <Grid item xs={6}>
            <Paper variant="outlined" sx={{ p: 1.5, height: '100%', bgcolor: 'background.default' }}>
              <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <FollowTheSignsIcon color="primary" sx={{ fontSize: 24, mb: 0.5 }} />
                <Typography variant="h6" color="primary" fontWeight="medium">
                  {skill.stats?.follows?.totalFollows || 0}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Followers
                </Typography>
              </Box>
            </Paper>
          </Grid>
          
          <Grid item xs={6}>
            <Paper variant="outlined" sx={{ p: 1.5, height: '100%', bgcolor: 'background.default' }}>
              <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <VisibilityIcon color="primary" sx={{ fontSize: 24, mb: 0.5 }} />
                <Typography variant="h6" color="primary" fontWeight="medium">
                  {skill.stats?.pageViews?.totalPageViews || 0}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Views
                </Typography>
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </Box>
      
      <Divider sx={{ mb: 2 }} />
      
      {/* Recognition & Badges */}
      <Box sx={{ mb: 2, textAlign: 'left' }}>
        <Typography variant="subtitle2" gutterBottom>
          Recognition
        </Typography>
        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
          {achievementsBadges.map((badge, index) => (
            <Chip
              key={index}
              icon={badge === 'Verified Business' ? <VerifiedUserIcon /> : 
                   badge === 'Network Trusted' ? <PeopleIcon /> :
                   <StarIcon />}
              label={badge}
              color={badge === 'Verified Business' ? 'success' : 
                     badge === 'Network Trusted' ? 'primary' : 
                     'secondary'}
              variant="outlined"
              size="small"
              sx={{ borderRadius: 1 }}
            />
          ))}
          {achievementsBadges.length === 0 && (
            <Typography variant="body2" color="text.secondary">
              No badges earned yet
            </Typography>
          )}
        </Box>
      </Box>
      

    </div>
  );
};

export default SkillStats;