import React from 'react';
import { Grid, Card, CardContent, Typography, Box, Avatar, CircularProgress } from '@mui/material';
import { Chart } from 'react-google-charts';
import PeopleIcon from '@mui/icons-material/People';
import WcIcon from '@mui/icons-material/Wc';
import LanguageIcon from '@mui/icons-material/Language';
import SentimentSatisfiedIcon from '@mui/icons-material/SentimentSatisfied';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import StarIcon from '@mui/icons-material/Star';
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';
import AssessmentIcon from '@mui/icons-material/Assessment';

const SentimentAnalysis = ({ data }) => {
  // Get data from props or use static data for demonstration
  //data?.sentimentData ||
  const sentimentAnalysisData =  {
    bestAgeGroup: '25-34',
    bestGender: 'Female',
    bestChannel: 'Online',
    sentimentProportion: {
      Positive: 60,
      Neutral: 25,
      Negative: 10,
      Mixed: 5
    },
    monthlyTrend: [
      { month: 'Jan-2024', value: 0.10 },
      { month: 'Feb-2024', value: 0.15 },
      { month: 'Mar-2024', value: 0.16 },
      { month: 'Apr-2024', value: 0.20 },
      { month: 'May-2024', value: 0.25 },
      { month: 'Jun-2024', value: 0.30 },
      { month: 'Jul-2024', value: 0.60 },
    ],
    sentimentVsRating: [
      { rating: 1, positive: 10 },
      { rating: 2, positive: 20 },
      { rating: 3, positive: 30 },
      { rating: 4, positive: 40 },
      { rating: 5, positive: 50 },
    ]
  };

  console.log('data', data);
  
  // Calculate total sentiment score as weighted average
  const totalSentimentScore = (
    (sentimentAnalysisData.sentimentProportion.Positive * 1) +
    (sentimentAnalysisData.sentimentProportion.Neutral * 0) +
    (sentimentAnalysisData.sentimentProportion.Negative * -1) +
    (sentimentAnalysisData.sentimentProportion.Mixed * 0)
  ) / 100;
  
  const totalSentimentFormatted = totalSentimentScore.toFixed(2);
  
  // Get sentiment label based on score
  const getSentimentLabel = (score) => {
    if (score >= 0.5) return 'Very Positive';
    if (score >= 0.2) return 'Positive';
    if (score >= -0.2) return 'Neutral';
    if (score >= -0.5) return 'Negative';
    return 'Very Negative';
  };
  
  // Prepare data for the sentiment donut chart
  const sentimentProportionData = [
    ['Sentiment', 'Percentage'],
    ['Positive', sentimentAnalysisData.sentimentProportion.Positive],
    ['Neutral', sentimentAnalysisData.sentimentProportion.Neutral],
    ['Negative', sentimentAnalysisData.sentimentProportion.Negative],
    ['Mixed', sentimentAnalysisData.sentimentProportion.Mixed]
  ];

  const sentimentProportionOptions = {
    pieHole: 0.6,
    pieSliceText: 'none',
    legend: { position: 'right' },
    slices: {
      0: { color: '#4CAF50' },
      1: { color: '#90CAF9' },
      2: { color: '#F44336' },
      3: { color: '#FFC107' }
    },
    chartArea: { width: '80%', height: '80%' },
    tooltip: { trigger: 'selection' },
    backgroundColor: 'transparent'
  };

  // Prepare data for the monthly trend line chart
  const monthlyTrendData = [
    ['Month', 'Sentiment Score'],
    ...sentimentAnalysisData.monthlyTrend.map(item => [item.month, item.value])
  ];

  const monthlyTrendOptions = {
    hAxis: { title: 'Month' },
    vAxis: { 
      title: 'Sentiment Score',
      minValue: -1,
      maxValue: 1,
      format: 'decimal'
    },
    legend: 'none',
    lineWidth: 3,
    colors: ['#4CAF50'],
    pointSize: 6,
    curveType: 'function',
    trendlines: { 0: {} },
    chartArea: { width: '80%', height: '70%' },
    backgroundColor: 'transparent'
  };

  // Prepare data for the sentiment vs rating chart
  const sentimentVsRatingData = [
    ['Rating', 'Positive Sentiment %', { role: 'style' }],
    ...sentimentAnalysisData.sentimentVsRating.map(item => [
      `${item.rating} Stars`, 
      item.positive, 
      item.rating >= 4 ? '#4CAF50' : item.rating >= 3 ? '#FFC107' : '#F44336'
    ])
  ];

  const sentimentVsRatingOptions = {
    hAxis: { title: 'Star Rating' },
    vAxis: { 
      title: 'Positive Sentiment %',
      minValue: 0,
      maxValue: 100
    },
    legend: 'none',
    bar: { groupWidth: '60%' },
    chartArea: { width: '80%', height: '70%' },
    backgroundColor: 'transparent'
  };
  
  // Prepare data for sentiment proportion comparison chart (current vs previous period)
  // This is a suggested new visualization based on available data
  const currentPositive = sentimentAnalysisData.sentimentProportion.Positive;
  const currentNegative = sentimentAnalysisData.sentimentProportion.Negative;
  // Simulating previous period data (in real implementation, this would come from the API)
  const previousPositive = currentPositive - 5; // Assuming 5% improvement
  const previousNegative = currentNegative + 5; // Assuming 5% reduction
  
  const sentimentComparisonData = [
    ['Period', 'Positive', 'Negative'],
    ['Previous Period', previousPositive, previousNegative],
    ['Current Period', currentPositive, currentNegative],
  ];
  
  const sentimentComparisonOptions = {
    chart: {
      title: 'Sentiment Improvement',
    },
    hAxis: { minValue: 0 },
    vAxis: { title: 'Period' },
    bars: 'horizontal',
    series: {
      0: { color: '#4CAF50' },
      1: { color: '#F44336' }
    },
    backgroundColor: 'transparent'
  };
  
  // Prepare data for sentiment distribution by demographic
  // This is another suggested visualization based on what might be derivable from your data
  const demographicSentimentData = [
    ['Demographic', 'Positive', 'Neutral', 'Negative'],
    ['Male', 55, 30, 15],
    ['Female', 65, 25, 10],
    ['18-24', 58, 27, 15],
    ['25-34', 67, 23, 10],
    ['35-44', 60, 28, 12],
    ['45+', 52, 33, 15],
  ];
  
  const demographicSentimentOptions = {
    isStacked: 'percent',
    height: 300,
    legend: { position: 'top', maxLines: 3 },
    vAxis: {
      minValue: 0,
      ticks: [0, .3, .6, .9, 1]
    },
    series: {
      0: { color: '#4CAF50' },
      1: { color: '#90CAF9' },
      2: { color: '#F44336' }
    },
    backgroundColor: 'transparent'
  };

  if (!data) {
    // Show a loading spinner if data is not available
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
        <CircularProgress color="primary" />
      </Box>
    );
  }

  return (
    <Box>
      {/* First Row - Key Metrics with accent colors */}
      <Grid container spacing={2} sx={{ mb: 2 }} alignItems="stretch">
        {/* Best Age Group Card */}
        <Grid item xs={12} sm={3}>
          <Card 
            variant="outlined" 
            sx={{ 
              height: '100%', 
              position: 'relative', 
              overflow: 'visible',
              border: 'none', 
              borderRadius: 2,
              bgcolor: 'white'
            }}
          >
            <Box sx={{ position: 'absolute', left: 0, top: 0, bottom: 0, width: '8px', backgroundColor: '#FF9800', borderTopLeftRadius: 8, borderBottomLeftRadius: 8 }} />
            <CardContent>
              <Box display="flex" alignItems="center" justifyContent="space-between">
                <Box>
                  <Typography variant="caption" color="textSecondary">Best Age Group</Typography>
                  <Typography variant="h4" sx={{ fontWeight: '500' }}>{sentimentAnalysisData.bestAgeGroup}</Typography>
                  <Typography variant="body2" color="textSecondary">Highest satisfaction rate</Typography>
                </Box>
                <Avatar sx={{ bgcolor: '#FFF3E0', color: '#FF9800', width: 56, height: 56 }}>
                  <PeopleIcon sx={{ fontSize: 32 }} />
                </Avatar>
              </Box>
            </CardContent>
          </Card>
        </Grid>

        {/* Best Gender Card */}
        <Grid item xs={12} sm={3}>
          <Card 
            variant="outlined" 
            sx={{ 
              height: '100%', 
              position: 'relative', 
              overflow: 'visible',
              border: 'none', 
              borderRadius: 2,
              bgcolor: 'white'
            }}
          >
            <Box sx={{ position: 'absolute', left: 0, top: 0, bottom: 0, width: '8px', backgroundColor: '#9C27B0', borderTopLeftRadius: 8, borderBottomLeftRadius: 8 }} />
            <CardContent>
              <Box display="flex" alignItems="center" justifyContent="space-between">
                <Box>
                  <Typography variant="caption" color="textSecondary">Best Gender</Typography>
                  <Typography variant="h4" sx={{ fontWeight: '500' }}>{sentimentAnalysisData.bestGender}</Typography>
                  <Typography variant="body2" color="textSecondary">Highest sentiment score</Typography>
                </Box>
                <Avatar sx={{ bgcolor: '#F3E5F5', color: '#9C27B0', width: 56, height: 56 }}>
                  <WcIcon sx={{ fontSize: 32 }} />
                </Avatar>
              </Box>
            </CardContent>
          </Card>
        </Grid>

        {/* Best Channel Card */}
        <Grid item xs={12} sm={3}>
          <Card 
            variant="outlined" 
            sx={{ 
              height: '100%', 
              position: 'relative', 
              overflow: 'visible',
              border: 'none', 
              borderRadius: 2,
              bgcolor: 'white'
            }}
          >
            <Box sx={{ position: 'absolute', left: 0, top: 0, bottom: 0, width: '8px', backgroundColor: '#2196F3', borderTopLeftRadius: 8, borderBottomLeftRadius: 8 }} />
            <CardContent>
              <Box display="flex" alignItems="center" justifyContent="space-between">
                <Box>
                  <Typography variant="caption" color="textSecondary">Best Channel</Typography>
                  <Typography variant="h4" sx={{ fontWeight: '500' }}>{sentimentAnalysisData.bestChannel}</Typography>
                  <Typography variant="body2" color="textSecondary">Highest engagement</Typography>
                </Box>
                <Avatar sx={{ bgcolor: '#E3F2FD', color: '#2196F3', width: 56, height: 56 }}>
                  <LanguageIcon sx={{ fontSize: 32 }} />
                </Avatar>
              </Box>
            </CardContent>
          </Card>
        </Grid>

        {/* Overall Sentiment Card */}
        <Grid item xs={12} sm={3}>
          <Card 
            variant="outlined" 
            sx={{ 
              height: '100%', 
              position: 'relative', 
              overflow: 'visible',
              border: 'none', 
              borderRadius: 2,
              bgcolor: 'white'
            }}
          >
            <Box sx={{ position: 'absolute', left: 0, top: 0, bottom: 0, width: '8px', backgroundColor: '#4CAF50', borderTopLeftRadius: 8, borderBottomLeftRadius: 8 }} />
            <CardContent>
              <Box display="flex" alignItems="center" justifyContent="space-between">
                <Box>
                  <Typography variant="caption" color="textSecondary">Overall Sentiment</Typography>
                  <Typography variant="h4" sx={{ fontWeight: '500' }}>{getSentimentLabel(totalSentimentScore)}</Typography>
                  <Typography variant="body2" color="textSecondary">Score: {totalSentimentFormatted}</Typography>
                </Box>
                <Avatar sx={{ bgcolor: '#E8F5E9', color: '#4CAF50', width: 56, height: 56 }}>
                  <SentimentSatisfiedIcon sx={{ fontSize: 32 }} />
                </Avatar>
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      {/* Second Row - Charts */}
      <Grid container spacing={2} sx={{ mb: 2 }} alignItems="stretch">
        {/* Sentiment Proportion Chart */}
        <Grid item xs={12} sm={4}>
          <Card 
            variant="outlined" 
            sx={{ 
              height: '100%', 
              border: 'none', 
              borderRadius: 2,
              bgcolor: 'white'
            }}
          >
            <CardContent>
              <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                <SentimentSatisfiedIcon sx={{ color: '#4CAF50', mr: 1 }} />
                <Typography variant="h6">Sentiment Distribution</Typography>
              </Box>
              <Chart
                chartType="PieChart"
                width="100%"
                height="250px"
                data={sentimentProportionData}
                options={sentimentProportionOptions}
              />
            </CardContent>
          </Card>
        </Grid>

        {/* Monthly Trend Chart */}
        <Grid item xs={12} sm={4}>
          <Card 
            variant="outlined" 
            sx={{ 
              height: '100%', 
              border: 'none', 
              borderRadius: 2,
              bgcolor: 'white'
            }}
          >
            <CardContent>
              <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                <TrendingUpIcon sx={{ color: '#4CAF50', mr: 1 }} />
                <Typography variant="h6">Monthly Sentiment Trend</Typography>
              </Box>
              <Chart
                chartType="LineChart"
                width="100%"
                height="250px"
                data={monthlyTrendData}
                options={monthlyTrendOptions}
              />
            </CardContent>
          </Card>
        </Grid>

        {/* Sentiment vs Rating Chart */}
        <Grid item xs={12} sm={4}>
          <Card 
            variant="outlined" 
            sx={{ 
              height: '100%', 
              border: 'none', 
              borderRadius: 2,
              bgcolor: 'white'
            }}
          >
            <CardContent>
              <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                <StarIcon sx={{ color: '#FFC107', mr: 1 }} />
                <Typography variant="h6">Sentiment by Rating</Typography>
              </Box>
              <Chart
                chartType="ColumnChart"
                width="100%"
                height="250px"
                data={sentimentVsRatingData}
                options={sentimentVsRatingOptions}
              />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      
      {/* Third Row - Alternative Charts */}
      <Grid container spacing={2} alignItems="stretch">
        {/* Sentiment Comparison Chart */}
        <Grid item xs={12} sm={6}>
          <Card 
            variant="outlined" 
            sx={{ 
              height: '100%', 
              border: 'none', 
              borderRadius: 2,
              bgcolor: 'white'
            }}
          >
            <CardContent>
              <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                <CompareArrowsIcon sx={{ color: '#2196F3', mr: 1 }} />
                <Typography variant="h6">Sentiment Improvement</Typography>
              </Box>
              <Chart
                chartType="BarChart"
                width="100%"
                height="250px"
                data={sentimentComparisonData}
                options={sentimentComparisonOptions}
              />
            </CardContent>
          </Card>
        </Grid>
        
        {/* Sentiment by Demographics Chart */}
        <Grid item xs={12} sm={6}>
          <Card 
            variant="outlined" 
            sx={{ 
              height: '100%', 
              border: 'none', 
              borderRadius: 2,
              bgcolor: 'white'
            }}
          >
            <CardContent>
              <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                <AssessmentIcon sx={{ color: '#FF9800', mr: 1 }} />
                <Typography variant="h6">Sentiment by Demographics</Typography>
              </Box>
              <Chart
                chartType="ColumnChart"
                width="100%"
                height="250px"
                data={demographicSentimentData}
                options={demographicSentimentOptions}
              />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};

export default SentimentAnalysis;