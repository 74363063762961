import React, { useState, useEffect } from 'react';
import { 
  Button, 
  Dialog, 
  DialogActions, 
  DialogContent, 
  DialogTitle, 
  Grid, 
  TextField,
  IconButton,
  Box,
  Typography,
  Avatar,
  Divider,
  CircularProgress,
  Paper,
  Alert,
  Slide
} from '@mui/material';
import { 
  Close, 
  Save, 
  Person, 
  Phone, 
  Email, 
  Home, 
  Cake, 
  Badge,
  CloudUpload
} from '@mui/icons-material';
import { Formik, Field, Form } from 'formik';
import { useGetUserProfileQuery, useUpdateUserProfileMutation } from '../../redux/thunks/users/userprofile';
import { useGetPreSignedUrlQuery } from '../../redux/thunks/files/filespresignurl';
import { useSelector } from 'react-redux';
// Removed DatePicker import
import moment from 'moment';
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';
import * as Yup from 'yup';

// Transition animation for dialog
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

// Validation schema
const ProfileSchema = Yup.object().shape({
  firstName: Yup.string().required('First name is required'),
  lastName: Yup.string().required('Last name is required'),
  phoneNumber1: Yup.string().matches(
    /^(\+\d{1,3}[- ]?)?\d{10}$/,
    'Please enter a valid phone number'
  ),
});

const EditProfileDialog = ({ open, onClose }) => {
  const userId = useSelector((state) => state.auth.user._id);
  const { data: userData, isLoading: isUserDataLoading } = useGetUserProfileQuery(userId);
  const [updateProfile, { isLoading: isUpdating, isSuccess, isError, error }] = useUpdateUserProfileMutation();
  const [address, setAddress] = useState('');
  const [uploadedImage, setUploadedImage] = useState(null);
  const [previewImage, setPreviewImage] = useState(null);
  const [photoUrl, setPhotoUrl] = useState(null);
  const [successMessage, setSuccessMessage] = useState('');
  
  // Get presigned URL for profile photo upload
  const presignedUrlArgs = {
    eventType: 'UserProfilePhoto',
    userId
  };
  const { data: presignedUrlData, isLoading: isPresignedUrlLoading } = useGetPreSignedUrlQuery(presignedUrlArgs);

  useEffect(() => {
    // Set address from formatted address if available, otherwise use regular address
    if (userData?.geocodeResults?.formattedAddress) {
      setAddress(userData.geocodeResults.formattedAddress);
    } else if (userData?.address) {
      setAddress(userData.address);
    }
    
    if (userData?.profileImageUrl) {
      setPreviewImage(userData.profileImageUrl);
    }
  }, [userData]);

  useEffect(() => {
    // Reset success message after 3 seconds
    if (successMessage) {
      const timer = setTimeout(() => {
        setSuccessMessage('');
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [successMessage]);

  const initialValues = {
    firstName: userData?.firstName || '',
    lastName: userData?.lastName || '',
    address: userData?.address || '',
    dateOfBirth: userData?.dateOfBirth ? new Date(userData.dateOfBirth) : null,
    phoneNumber1: userData?.phoneNumber1 || '',
  };

  const handleAddressChange = (newAddress) => {
    setAddress(newAddress);
  };

  const handleSelect = (selectedAddress) => {
    setAddress(selectedAddress);
  };

  // Function to upload image to S3 using presigned URL
  const uploadPhotoToS3 = async (file, presignedUrl) => {
    try {
      const response = await fetch(presignedUrl, {
        method: 'PUT',
        body: file,
        headers: {
          'Content-Type': file.type,
          'x-amz-meta-eventtype': 'UserProfilePhoto'
        },
      });

      if (!response.ok) {
        throw new Error('Failed to upload photo');
      }

      return true;
    } catch (error) {
      console.error('Error uploading photo:', error);
      throw error;
    }
  };

  const handleImageUpload = async (event) => {
    const file = event.target.files[0];
    if (file) {
      try {
        // Generate preview for immediate UI feedback
        const reader = new FileReader();
        reader.onload = (e) => setPreviewImage(e.target.result);
        reader.readAsDataURL(file);
        
        // Store the file for later upload
        setUploadedImage(file);
        
        // Upload immediately if presigned URL is available
        if (presignedUrlData?.url) {
          await uploadPhotoToS3(file, presignedUrlData.url);
          
          // Store the public URL (without query params)
          const publicUrl = presignedUrlData.url.split('?')[0];
          setPhotoUrl(publicUrl);
          setSuccessMessage('Profile image uploaded successfully');
        } else {
          console.error("Failed to fetch pre-signed URL.");
        }
      } catch (error) {
        console.error("Error uploading photo:", error);
      }
    }
  };

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    try {
      // Upload photo to S3 if it hasn't been uploaded yet
      let finalPhotoUrl = photoUrl;
      
      if (uploadedImage && !photoUrl && presignedUrlData?.url) {
        try {
          await uploadPhotoToS3(uploadedImage, presignedUrlData.url);
          finalPhotoUrl = presignedUrlData.url.split('?')[0]; // Get the public URL
        } catch (error) {
          console.error("Error uploading photo during form submission:", error);
        }
      }

      // Prepare payload for profile update
      const payload = {
        firstName: values.firstName,
        lastName: values.lastName,
        address: address || values.address, // Just use the address string
        phoneNumber1: values.phoneNumber1,
        dateOfBirth: values.dateOfBirth ? values.dateOfBirth.toISOString() : null,
      };
      
      // Add profile image URL if available
      if (finalPhotoUrl) {
        payload.profileImageUrl = finalPhotoUrl;
      }

      // Update profile
      await updateProfile({ userId, payload });
      setSuccessMessage('Profile updated successfully');

      // Reset image upload state
      setUploadedImage(null);

      // Don't close automatically to show success message
      setTimeout(() => {
        if (isSuccess) {
          onClose();
        }
      }, 2000);

    } catch (err) {
      console.error('Error updating profile:', err);
    } finally {
      setSubmitting(false);
    }
  };

  if (isUserDataLoading || isPresignedUrlLoading) {
    return (
      <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
        <DialogContent>
          <Box display="flex" justifyContent="center" alignItems="center" p={4}>
            <CircularProgress />
          </Box>
        </DialogContent>
      </Dialog>
    );
  }

  return (
    <Dialog 
      open={open} 
      onClose={onClose} 
      maxWidth="md" 
      fullWidth
      TransitionComponent={Transition}
      PaperProps={{
        sx: {
          borderRadius: 2,
          boxShadow: 10
        }
      }}
    >
      <DialogTitle sx={{ bgcolor: 'primary.main', color: 'white', position: 'relative', pb: 2 }}>
        <Typography variant="h6" component="div">
          Edit Your Profile
        </Typography>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: 'white',
          }}
        >
          <Close />
        </IconButton>
      </DialogTitle>

      <Formik
        initialValues={initialValues}
        validationSchema={ProfileSchema}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        {({ values, errors, touched, setFieldValue, isSubmitting }) => (
          <Form>
            <DialogContent sx={{ p: 3 }}>
              {successMessage && (
                <Alert severity="success" sx={{ mb: 2 }}>
                  {successMessage}
                </Alert>
              )}

              {isError && (
                <Alert severity="error" sx={{ mb: 2 }}>
                  {error?.data?.message || 'An error occurred while updating your profile'}
                </Alert>
              )}

              <Grid container spacing={3}>
                {/* Profile Image Section */}
                <Grid item xs={12} display="flex" justifyContent="center">
                  <Box textAlign="center">
                    <Box position="relative" display="inline-block">
                      <Avatar
                        src={previewImage}
                        alt={userData?.displayName}
                        sx={{ width: 120, height: 120, mb: 2 }}
                      >
                        {userData?.displayName?.split(' ').map(n => n[0]).join('')}
                      </Avatar>
                      <label htmlFor="profile-image-upload">
                        <input
                          id="profile-image-upload"
                          type="file"
                          accept="image/*"
                          style={{ display: 'none' }}
                          onChange={handleImageUpload}
                        />
                        <IconButton
                          component="span"
                          sx={{
                            position: 'absolute',
                            bottom: 10,
                            right: 0,
                            bgcolor: 'primary.main',
                            color: 'white',
                            '&:hover': {
                              bgcolor: 'primary.dark',
                            },
                          }}
                        >
                          <CloudUpload fontSize="small" />
                        </IconButton>
                      </label>
                    </Box>
                    <Typography variant="body2" color="text.secondary">
                      Upload a new profile picture
                    </Typography>
                  </Box>
                </Grid>

                <Grid item xs={12}>
                  <Divider sx={{ my: 1 }} />
                </Grid>

                {/* Personal Information Section */}
                <Grid item xs={12}>
                  <Box sx={{ mb: 2 }}>
                    <Typography variant="subtitle1" fontWeight="bold" color="primary">
                      Personal Information
                    </Typography>
                  </Box>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                    <Person sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
                    <Field
                      as={TextField}
                      name="firstName"
                      label="First Name"
                      fullWidth
                      variant="outlined"
                      error={touched.firstName && Boolean(errors.firstName)}
                      helperText={touched.firstName && errors.firstName}
                    />
                  </Box>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                    <Person sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
                    <Field
                      as={TextField}
                      name="lastName"
                      label="Last Name"
                      fullWidth
                      variant="outlined"
                      error={touched.lastName && Boolean(errors.lastName)}
                      helperText={touched.lastName && errors.lastName}
                    />
                  </Box>
                </Grid>

                <Grid item xs={12}>
                  <Box sx={{ display: 'flex', alignItems: 'flex-start', width: '100%' }}>
                    <Home sx={{ color: 'action.active', mr: 1, mt: 2 }} />
                    <Box sx={{ flexGrow: 1 }}>
                      <PlacesAutocomplete
                        value={address}
                        onChange={handleAddressChange}
                        onSelect={handleSelect}
                      >
                        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                          <div>
                            <TextField
                              {...getInputProps({
                                placeholder: 'Enter your address',
                              })}
                              name="address"
                              label="Address"
                              fullWidth
                              variant="outlined"
                            />
                            <Paper
                              elevation={5}
                              sx={{
                                mt: 0.5,
                                display: suggestions.length > 0 ? 'block' : 'none',
                                maxHeight: 200,
                                overflow: 'auto',
                                zIndex: 9999,
                                position: 'absolute',
                                width: 'calc(100% - 56px)'
                              }}
                            >
                              {loading && (
                                <Box p={2} textAlign="center">
                                  <CircularProgress size={20} />
                                </Box>
                              )}
                              {suggestions.map((suggestion) => {
                                const style = {
                                  padding: '10px 16px',
                                  cursor: 'pointer',
                                  backgroundColor: suggestion.active ? '#f5f5f5' : '#fff',
                                  '&:hover': {
                                    backgroundColor: '#f5f5f5'
                                  }
                                };
                                return (
                                  <Box
                                    {...getSuggestionItemProps(suggestion, { style })}
                                    key={suggestion.placeId}
                                    sx={style}
                                  >
                                    <Typography variant="body2">{suggestion.description}</Typography>
                                  </Box>
                                );
                              })}
                            </Paper>
                          </div>
                        )}
                      </PlacesAutocomplete>
                    </Box>
                  </Box>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                    <Phone sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
                    <Field
                      as={TextField}
                      name="phoneNumber1"
                      label="Phone"
                      fullWidth
                      variant="outlined"
                      error={touched.phoneNumber1 && Boolean(errors.phoneNumber1)}
                      helperText={touched.phoneNumber1 && errors.phoneNumber1}
                    />
                  </Box>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                    <Cake sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
                    <TextField
                      label="Date of Birth (Optional)"
                      fullWidth
                      variant="outlined"
                      type="date"
                      value={values.dateOfBirth ? moment(values.dateOfBirth).format('YYYY-MM-DD') : ''}
                      onChange={(e) => {
                        const date = e.target.value ? new Date(e.target.value) : null;
                        setFieldValue('dateOfBirth', date, true);
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Box>
                </Grid>

                <Grid item xs={12}>
                  <Divider sx={{ my: 1 }} />
                </Grid>

                <Grid item xs={12}>
                  <Typography variant="subtitle1" fontWeight="bold" color="primary">
                    Account Information
                  </Typography>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                    <Badge sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
                    <TextField
                      name="_id"
                      label="User ID"
                      fullWidth
                      variant="outlined"
                      value={userData?._id || ''}
                      InputProps={{ readOnly: true }}
                      disabled
                    />
                  </Box>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                    <Email sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
                    <TextField
                      name="email"
                      label="Email"
                      fullWidth
                      variant="outlined"
                      value={userData?.email || ''}
                      InputProps={{ readOnly: true }}
                      disabled
                    />
                  </Box>
                </Grid>
              </Grid>
            </DialogContent>

            <DialogActions sx={{ px: 3, py: 2, bgcolor: 'grey.50' }}>
              <Button 
                onClick={onClose} 
                variant="outlined"
                startIcon={<Close />}
                disabled={isSubmitting}
              >
                Cancel
              </Button>
              <Button 
                type="submit" 
                color="primary" 
                variant="contained"
                startIcon={<Save />}
                disabled={isSubmitting}
              >
                {isSubmitting ? <CircularProgress size={24} /> : 'Save Changes'}
              </Button>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

export default EditProfileDialog;