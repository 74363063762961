import React from 'react';
import { 
  Container, 
  Grid, 
  Box, 
  Paper, 
  Typography, 
  Avatar,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Link as MuiLink
} from '@mui/material';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';

import NotificationList from './NotificationList';
import Footer from '../../Components/Footer/Footer';

// Icons
import LabelIcon from '@mui/icons-material/Label';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';

const Notifications = () => {
  const navigate = useNavigate();
  const userData = useSelector((state) => state.auth?.user || null);

  // Function to get user initials for Avatar
  const getUserInitials = (name) => {
    if (!name) return '';
    return name
      .split(' ')
      .map(part => part[0])
      .join('')
      .toUpperCase()
      .substring(0, 2);
  };

  return (
    <Box
      backgroundColor="#f5f5f5"
      maxWidth={1280}
      minHeight={'90vh'}
      mx="auto"
      p={2}
    >
      <Grid container spacing={2}>
        {/* Left Column - User Bio */}
        <Grid item xs={12} sm={3}>
          <Paper
            variant="outlined"
            sx={{ 
              padding: '20px', 
              border: 'none', 
              mb: 2, 
              borderRadius: 2,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center'
            }}
          >
            {/* User Profile Section */}
            <Avatar
              src={userData?.profileImageUrl}
              alt={userData?.displayName}
              sx={{ width: 80, height: 80, mb: 2 }}
            >
              {getUserInitials(userData?.displayName)}
            </Avatar>
            
            <Typography variant="h6" gutterBottom align="center">
              {userData?.displayName || 'User Name'}
            </Typography>
            
            <Typography variant="body2" color="textSecondary" align="center" gutterBottom>
              Trust Score: {userData?.trustScore || 0}
            </Typography>
            
            
            
          </Paper>
          
          <Paper
            variant="outlined"
            sx={{ padding: '20px', border: 'none', borderRadius: 2 }}
          >
            <Typography variant="subtitle1" gutterBottom>
              Help & Resources
            </Typography>
            
            <List dense disablePadding>
              <ListItem 
                button 
                component={Link} 
                to="https://www.revfer.com/help" 
                target="_blank"
                sx={{ borderRadius: 1, mb: 0.5 }}
              >
                <ListItemIcon sx={{ minWidth: 36 }}>
                  <HelpOutlineIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText 
                  primary="Help Center" 
                  secondary="Get support and answers" 
                />
              </ListItem>
              
              <ListItem 
                button 
                component={Link} 
                to="https://www.revfer.com/about" 
                target="_blank"
                sx={{ borderRadius: 1 }}
              >
                <ListItemIcon sx={{ minWidth: 36 }}>
                  <PeopleOutlineIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText 
                  primary="About Revfer" 
                  secondary="Learn more about our mission" 
                />
              </ListItem>
            </List>
          </Paper>
        </Grid>
        
        {/* Middle Column - Notification Feed */}
        <Grid item xs={12} sm={6}>
          <Paper
            variant="outlined"
            sx={{ 
              padding: '20px', 
              border: 'none', 
              mb: 1, 
              borderRadius: 2,
              minHeight: '70vh'
            }}
          >
            <NotificationList />
          </Paper>
        </Grid>
        
        {/* Right Column - Ads & Recommendations */}
        <Grid item xs={12} sm={3}>
         <Paper variant="outlined" sx={{ p: 2, mb: 2, border: 'none' }}>
                   <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 2 }}>
                     <LabelIcon fontSize="small" color="action" />
                     <Typography variant="subtitle2" color="text.secondary">
                       Sponsored
                     </Typography>
                   </Box>
                   
                   {/* AdSense Placeholder */}
                   <Box 
                     sx={{ 
                       width: '100%',
                       height: '250px',
                       bgcolor: 'grey.100',
                       display: 'flex',
                       alignItems: 'center',
                       justifyContent: 'center',
                       borderRadius: 1,
                       border: '1px dashed grey.300'
                     }}
                   >
                     <Typography variant="body2" color="text.secondary">
                       Advertisement
                     </Typography>
                   </Box>
                 </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Notifications;