import { useState, useEffect } from 'react';

import {
  Box,
  Container,
  Grid,
  Paper,
  Typography,
  Avatar,
  Button,
  TextField,
  LinearProgress,
  Card,
  CardContent,
  IconButton,
  Skeleton,
  Divider,
  Tooltip,
  Collapse,
  Alert,
  Stack
} from '@mui/material';
import {
  Search as SearchIcon,
  PeopleOutlined as PeopleIcon,
  Business as BusinessIcon,
  RateReview as ReviewIcon,
  ImportContacts as ImportIcon,
  TrendingUp as TrendingIcon,
  StarBorder as StarIcon,
  LocationOnOutlined as LocationIcon,
  StorefrontOutlined as StorefrontIcon,
  ArrowForward as ArrowIcon,
  VerifiedUser  as VerifiedUserIcon,
  RateReview as RateReviewIcon,
  AddBusiness as AddBusinessIcon,
  RateReview,
  TrendingUp,  
  Info as InfoIcon
  

} from '@mui/icons-material';
import { useSelector } from 'react-redux'; // For auth state
import { useAuthenticator } from '@aws-amplify/ui-react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useGetUserByEmailQuery } from '../../redux/thunks/users/getuserbyemail';
import LocationModal from './LocationModal';





// Mock Data
const GETTING_STARTED_ITEMS = [
  {
    id: 'find-people',
    title: 'Find People You Know',
    icon: PeopleIcon,
    description: 'Connect with your network',
    action: 'Find People',
    completed: false,
    color: 'primary'
  },
  {
    id: 'set-location',
    title: 'Set Your Location',
    icon: LocationIcon,
    description: 'Get relevant local recommendations',
    action: 'Set Location',
    completed: false,
    hidden: false, // This would be controlled by user's location status
    color: 'warning'
  },
  {
    id: 'find-businesses',
    title: 'Find Businesses',
    icon: StorefrontIcon,
    description: 'Discover trusted local businesses',
    action: 'Explore',
    completed: false,
    color: 'success'
  }
];

const SUGGESTED_ACTIONS = [
  {
    id: 'trusted-businesses',
    title: 'Businesses Your Connections Trust',
    description: 'See where your network shops and dines',
    count: 12,
    action: 'View All',
    goal: 3, // At least 3 connections
    isUnlocked: (connections) => connections >= 3,
    goalMessage: 'Goal: At least 3 connections',
  },
  {
    id: 'local-influencers',
    title: 'Local Influencers',
    description: 'Connect with trusted reviewers in your area',
    count: 8,
    action: 'Discover',
    goal: 'location', // Requires location to be set
    isUnlocked: (location) => Boolean(location),
    goalMessage: 'Goal: Set your address',
  },
  {
    id: 'review-insights',
    title: 'Review Insights',
    description: 'Analyze your review performance',
    count: 5,
    action: 'Start Reviewing',
    goal: 1, // At least 1 review
    isUnlocked: (reviews) => reviews >= 1,
    goalMessage: 'Goal: Write at least 1 review',
  },
];

const NEXT_GOALS = [
  {
    id: 1,
    title: 'Write First Review',
    description: 'Share your experience',
    current: 0,
    target: 1,
    color: 'primary',
    icon: ReviewIcon
  },
  {
    id: 2,
    title: 'Build Your Network',
    description: 'Connect with 5 people',
    current: 1,
    target: 5,
    color: 'success',
    icon: PeopleIcon
  },
  {
    id: 3,
    title: 'Follow Businesses',
    description: 'Follow 10 trusted businesses',
    current: 3,
    target: 10,
    color: 'warning',
    icon: BusinessIcon
  }
];

const MAIN_ACTIONS = [
  {
    icon: SearchIcon,
    title: 'Discover and Review',
    description: 'Share your experiences with local businesses',
    action: (navigate) => navigate('/find'),
    disabled: false
  },
  {
    icon: PeopleIcon,
    title: 'Connect with Others',
    description: 'Build your trust network',
    action: (navigate) => navigate('/network'),
    disabled: false
  },
  {
    icon: BusinessIcon,
    title: 'Own a Business?',
    description: 'Claim and manage your business profile',
    action: (navigate) => navigate('/company/new'),
    disabled: false
  },
  {
    icon: AddBusinessIcon,
    title: 'Request Reviews',
    description: 'Post a product or business to get reviews',
    action: () => {},
    disabled: true,
    comingSoon: true
  }
];

const FUTURE_SUGGESTED_ACTIONS = [
  {
    id: 'trending-businesses',
    title: 'Trending Businesses',
    description: 'Discover businesses gaining popularity',
    action: 'Explore Now',
    goal: 5, // Follow at least 5 businesses
    isUnlocked: (followedBusinesses) => followedBusinesses >= 5,
    goalMessage: 'Goal: Follow at least 5 businesses',
  },
  {
    id: 'referral-rewards',
    title: 'Invite Friends',
    description: 'Get rewarded for referrals',
    action: 'Invite',
    goal: 3, // Invite at least 3 friends
    isUnlocked: (invitedFriends) => invitedFriends >= 3,
    goalMessage: 'Goal: Invite at least 3 friends',
  },
  {
    id: 'ai-insights',
    title: 'AI Business Insights',
    description: 'Unlock personalized insights for your business',
    action: 'Get Insights',
    goal: 1, // Add or claim one business
    isUnlocked: (businessesAdded) => businessesAdded >= 1,
    goalMessage: 'Goal: Add or claim a business',
  },
];

const getNextGoals = (userData) => {
  if (!userData) {
    return NEXT_GOALS; // Return default goals if no user data
  }

  return [
    {
      id: 1,
      title: 'Write First Review',
      description: 'Share your experience',
      current: userData.stats?.reviews?.totalReviews || 0,
      target: 1,
      color: 'primary',
      icon: ReviewIcon
    },
    {
      id: 2,
      title: 'Build Your Network',
      description: 'Connect with 5 people',
      current: userData.firstDegreeFriends?.length || 0,
      target: 5,
      color: 'success',
      icon: PeopleIcon
    },
    {
      id: 3,
      title: 'Follow Businesses',
      description: 'Follow 10 trusted businesses',
      current: userData.stats?.follow?.totalFollows || 0,
      target: 10,
      color: 'warning',
      icon: BusinessIcon
    }
  ];
};

// Update the Next Goals section in the main component
const NextGoalsSection = ({ userData }) => {
  const goals = getNextGoals(userData);

  return (
    <Paper variant="outlined" sx={{ p: 2, border: 'none' }}>
      <Typography variant="h6" gutterBottom>
        Next Goals
      </Typography>
      <Typography variant="body2" color="text.secondary" gutterBottom>
        Complete these to level up your profile
      </Typography>

      <Box sx={{ mt: 2, display: 'flex', flexDirection: 'column', gap: 2 }}>
        {goals.map((goal) => (
          <Paper
            key={goal.id}
            variant="outlined"
            sx={{
              p: 2,
              border: 'none',
              bgcolor: 'grey.50'
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'flex-start', mb: 1 }}>
              <Avatar
                sx={{
                  width: 32,
                  height: 32,
                  mr: 1,
                  bgcolor: `${goal.color}.light`,
                  color: `${goal.color}.main`
                }}
              >
                {goal.id}
              </Avatar>
              <Box sx={{ flex: 1 }}>
                <Typography variant="subtitle2">
                  {goal.title}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {goal.description}
                </Typography>
              </Box>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <LinearProgress
                variant="determinate"
                value={Math.min((goal.current / goal.target) * 100, 100)}
                sx={{ 
                  flex: 1,
                  height: 6,
                  borderRadius: 3,
                  [`& .MuiLinearProgress-bar`]: {
                    bgcolor: `${goal.color}.main`
                  }
                }}
              />
              <Typography variant="caption" color="text.secondary">
                {goal.current}/{goal.target}
              </Typography>
            </Box>
          </Paper>
        ))}
      </Box>
    </Paper>
  );
};


// Helper function to process completion status
const getGettingStartedItems = (userData) => {
  if (!userData) {
    return GETTING_STARTED_ITEMS;
  }

  return [
    {
      id: 'find-people',
      title: 'Find People You Know',
      icon: PeopleIcon,
      description: 'Connect with your network',
      action: 'Find People',
      completed: userData.firstDegreeFriends?.length > 1,
      color: 'primary'
    },
    {
      id: 'set-location',
      title: 'Set Your Location',
      icon: LocationIcon,
      description: 'Get relevant local recommendations',
      action: 'Set Location',
      completed: userData.geocodeResults && Object.keys(userData.geocodeResults).length > 0,
      hidden: false,
      color: 'warning'
    },
    {
      id: 'find-businesses',
      title: 'Find Businesses',
      icon: StorefrontIcon,
      description: 'Discover trusted local businesses',
      action: 'Explore',
      completed: (
        (userData.myTrustedThings?.length > 1) || 
        (userData.following?.length > 1) || 
        (userData.stats?.reviews?.totalReviews > 1)
      ),
      color: 'success'
    }
  ];
};


const GetStartedSection = ({ onAction, userData }) => {
  const [showLocationAlert, setShowLocationAlert] = useState(true);
  const items = getGettingStartedItems(userData);
  const completedSteps = items.filter(item => item.completed).length;
  const totalSteps = items.length;
  console.log('items', items)

  return (
    <Paper variant="outlined" sx={{ p: 2, border: 'none' }}>
      <Typography variant="h6" gutterBottom>
        Getting Started
      </Typography>
      
      {/* Location Alert */}
      <Collapse in={!items[1].completed && showLocationAlert}>
        <Alert 
          severity="info" 
          sx={{ mb: 2 }}
          onClose={() => setShowLocationAlert(false)}
        >
          Set your location to get personalized recommendations
        </Alert>
      </Collapse>

      {/* Action Cards */}
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        {items.map((item) => {
          if (item.hidden) return null;
          const Icon = item.icon;

          return (
            <Paper
              key={item.id}
              elevation={0}
              onClick={() => !item.completed && onAction(item.id)}
              sx={{
                p: 2,
                bgcolor: `${item.color}.50`,
                borderRadius: 2,
                transition: 'all 0.3s ease',
                cursor: item.completed ? 'default' : 'pointer',
                '&:hover': {
                  bgcolor: item.completed ? `${item.color}.50` : `${item.color}.100`,
                  transform: item.completed ? 'none' : 'translateY(-2px)',
                },
              }}
            >
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Box
                  sx={{
                    bgcolor: `${item.color}.main`,
                    borderRadius: '50%',
                    p: 1,
                    mr: 2,
                    display: 'flex',
                    opacity: item.completed ? 0.7 : 1
                  }}
                >
                  <Icon sx={{ color: 'white' }} />
                </Box>
                <Box flex={1}>
                  <Typography 
                    variant="subtitle1"
                    sx={{ opacity: item.completed ? 0.7 : 1 }}
                  >
                    {item.title}
                    {item.completed && (
                      <Typography 
                        component="span" 
                        sx={{ 
                          ml: 1, 
                          color: 'success.main',
                          fontSize: '0.8rem' 
                        }}
                      >
                        ✓ Done
                      </Typography>
                    )}
                  </Typography>
                  <Typography 
                    variant="body2" 
                    color="text.secondary"
                    sx={{ opacity: item.completed ? 0.7 : 1 }}
                  >
                    {item.description}
                  </Typography>
                </Box>
              </Box>
            </Paper>
          );
        })}
      </Box>

      {/* Progress Indicator */}
      <Box sx={{ 
        mt: 2, 
        pt: 2, 
        borderTop: 1, 
        borderColor: 'divider',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
      }}>
        <Typography variant="body2" color="text.secondary">
          {completedSteps} of {totalSteps} steps completed
        </Typography>
        {completedSteps === totalSteps && (
          <Typography variant="body2" color="success.main">
            All set! ✨
          </Typography>
        )}
      </Box>
    </Paper>
  );
}


const MainActionsSection = () => {
  const navigate = useNavigate();

  return (
    <Paper variant="outlined" sx={{ p: 3, border: 'none' }}>
      <Typography variant="h5" gutterBottom>
        Start Building Your Network
      </Typography>
      <Typography variant="body1" color="text.secondary" gutterBottom>
        Here's what you can do:
      </Typography>

      <Box sx={{ mt: 3, display: 'flex', flexDirection: 'column', gap: 2 }}>
        {MAIN_ACTIONS.map((action, index) => {
          const Icon = action.icon;
          return (
            <Paper
              key={index}
              elevation={0}
              onClick={() => !action.disabled && action.action(navigate)}
              sx={{
                p: 2.5,
                bgcolor: 'grey.50',
                borderRadius: 2,
                cursor: action.disabled ? 'default' : 'pointer',
                transition: 'all 0.2s ease',
                opacity: action.disabled ? 0.7 : 1,
                '&:hover': {
                  bgcolor: action.disabled ? 'grey.50' : 'grey.100',
                  transform: action.disabled ? 'none' : 'translateY(-2px)'
                }
              }}
            >
              <Box sx={{ display: 'flex', alignItems: 'flex-start', position: 'relative' }}>
                <Box
                  sx={{
                    minWidth: 48,
                    height: 48,
                    borderRadius: 2,
                    bgcolor: 'primary.50',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    mr: 2
                  }}
                >
                  <Icon 
                    sx={{ 
                      color: 'primary.main',
                      fontSize: 24
                    }} 
                  />
                </Box>
                <Box sx={{ flex: 1 }}>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <Typography 
                      variant="subtitle1" 
                      sx={{ 
                        mb: 0.5,
                        fontWeight: 500 
                      }}
                    >
                      {action.title}
                    </Typography>
                    {action.comingSoon && (
                      <Typography
                        variant="caption"
                        sx={{
                          px: 1,
                          py: 0.5,
                          bgcolor: 'warning.light',
                          color: 'warning.dark',
                          borderRadius: 1,
                          fontSize: '0.75rem',
                          fontWeight: 500
                        }}
                      >
                        Coming Soon
                      </Typography>
                    )}
                  </Box>
                  <Typography 
                    variant="body2" 
                    color="text.secondary"
                  >
                    {action.description}
                  </Typography>
                </Box>
              </Box>
            </Paper>
          );
        })}
      </Box>

      {/* Optional: Help Text */}
      <Box sx={{ mt: 3, display: 'flex', alignItems: 'center', gap: 1 }}>
        <InfoIcon sx={{ color: 'text.secondary', fontSize: 20 }} />
        <Typography variant="body2" color="text.secondary">
          Click any card to get started with that activity
        </Typography>
      </Box>
    </Paper>
  );
};

// Skeleton Components
const ProfileSkeleton = () => (
  <Box sx={{ p: 2 }}>
    <Skeleton variant="circular" width={80} height={80} sx={{ mb: 2 }} />
    <Skeleton variant="text" width={150} />
    <Skeleton variant="text" width={100} />
    <Box sx={{ mt: 2 }}>
      <Skeleton variant="rectangular" height={8} sx={{ mb: 1 }} />
      <Skeleton variant="text" width={40} />
    </Box>
  </Box>
);

const ContentSkeleton = () => (
  <Box sx={{ p: 2 }}>
    <Skeleton variant="text" width="60%" height={40} sx={{ mb: 2 }} />
    <Skeleton variant="rectangular" height={80} sx={{ mb: 2 }} />
    <Skeleton variant="rectangular" height={200} />
  </Box>
);



// Main Component
export default function EarlyUserHome() {
  const navigate = useNavigate();  

  const locationData = useLocation();
  //const { user: initialUserData } = locationData.state || {};
  //const [loading, setLoading] = useState(false);
  const [locationModalOpen, setLocationModalOpen] = useState(false);
  const [userData, setUserData] = useState(''); // Add state for user data if not already present


  const token = useSelector((state) => state.auth?.token || null);
  const userId = useSelector((state) => state.auth?.userId || '');
  const user = useSelector((state) => state.auth?.user || '');

  const { data: userProfileData, isError: isUserDataError, isLoading: isUserLoading } = useGetUserByEmailQuery(user.email);
  console.log('userData', userData);


  useEffect(() => {
      if (userProfileData) {
        
        console.log('Setting signin userdata in redux store..');
        setUserData(userProfileData)
  
      
     
      }
    }, [userProfileData]);

  const getInitials = (name) => {
    if (!name) return '';
    const [firstName, lastName] = name.split(' ');
    return `${firstName?.[0] ?? ''}${lastName?.[0] ?? ''}`.toUpperCase();
  };

  //const { data: userData, isError, isLoading: isUserLoading } = useGetUserByEmailQuery(user.email);
  console.log('userData', userData);


  const StatsBanner = ({user}) => (
    <Box 
      sx={{ 
        width: '100%',
        bgcolor: 'primary.main',
        color: 'white',
        py: 3,
        mb: 3
      }}
    >
      <Container maxWidth="lg">
        <Grid container spacing={3}>
          <Grid item xs={12} md={4}>
            <StatCard
              title="Trust Score"
              value={user.trustScore}
              icon={TrendingUp}
              light
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <StatCard
              title="Reviews"
              value={user.stats?.reviews?.totalReviews || 0}
              icon={RateReview}
              light
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <StatCard
              title="Network"
              value={user.firstDegreeFriends?.length || 0}
              icon={PeopleIcon}
              light
            />
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
  
  const StatCard = ({ title, value, icon: Icon, light = false }) => (
    <Stack 
      direction="row" 
      alignItems="center" 
      spacing={2}
    >
      <Box
        sx={{
          bgcolor: light ? 'rgba(255,255,255,0.1)' : `${theme.palette.primary.main}15`,
          p: 1.5,
          borderRadius: 2,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <Icon sx={{ color: light ? 'white' : 'primary.main' }} />
      </Box>
      <Box>
        <Typography 
          variant="body2" 
          color={light ? 'rgba(255,255,255,0.7)' : 'text.secondary'} 
          gutterBottom
        >
          {title}
        </Typography>
        <Typography 
          variant="h6"
          color={light ? 'white' : 'inherit'}
        >
          {value}
        </Typography>
      </Box>
    </Stack>
  );
  
  
  const handleGetStartedAction = (itemId) => {
    switch(itemId) {
      case 'find-people':
        navigate('/network');
        break;
      case 'set-location':
        // Open location modal or navigate to settings
        setLocationModalOpen(true);
        break;
      case 'find-businesses':
        navigate('/find');
        break;
      default:
        console.log('Unknown action:', itemId);
    }
  };

  const handleLocationSet = async (locationData) => {
    try {
      console.log('locationData', locationData)
      // Assuming you have an API call to update user profile
      

      // Show success message or update UI as needed
      // The GetStartedSection will automatically update due to the userData update
    } catch (error) {
      console.error('Error updating location:', error);
      // Handle error (show error message, etc.)
    }
  };
  
  const handleSuggestionAction = (itemId) => {
    switch(itemId) {
      case 'trusted-businesses':
        navigate('/network/trusted-businesses');
        break;
      case 'local-influencers':
        navigate('/network/influencers');
        break;
      case 'first-reviews':
        navigate('/reviews/opportunities');
        break;
      default:
        console.log('Unknown suggestion:', itemId);
    }
  };
  

  if (isUserLoading) {
    return (
      <Box>
        <Paper sx={{ width: '100%', p: 3, bgcolor: 'primary.main', color: 'white', borderRadius: 0 }}>
          <Container maxWidth="xl">
            <Skeleton variant="text" width={300} height={40} sx={{ bgcolor: 'rgba(255,255,255,0.1)' }} />
            <Skeleton variant="text" width={400} height={30} sx={{ bgcolor: 'rgba(255,255,255,0.1)' }} />
          </Container>
        </Paper>

        <Container maxWidth="xl" sx={{ mt: 3 }}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={3}>
              <Paper><ProfileSkeleton /></Paper>
            </Grid>
            <Grid item xs={12} md={6}>
              <Paper><ContentSkeleton /></Paper>
            </Grid>
            <Grid item xs={12} md={3}>
              <Paper><ContentSkeleton /></Paper>
            </Grid>
          </Grid>
        </Container>
      </Box>
    );
  }

  return (
    <Box >
      {/* Welcome Banner */}
      <StatsBanner user={userData}/>

      <Container maxWidth="xl" sx={{ mt: 3 }}>
        <Grid container spacing={3}>
          {/* Left Column */}
          <Grid item xs={12} md={3}>
            {/* Profile Summary */}
            <Paper variant="outlined" sx={{ p: 2, mb: 3, border: 'none' }}>
              <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                <Avatar
                  sx={{ width: 80, height: 80, mr: 2 }}
                  src={user.profileImageUrl || undefined} 
                  alt={user.displayName}
                >
                  {!user.profileImageUrl && getInitials(user.displayName)}
                </Avatar>
                <Box>
                  <Typography variant="h6">{user.displayName}</Typography>
                  <Typography variant="body2" color="text.secondary">
                    New Member
                  </Typography>
                </Box>
              </Box>
              <Typography variant="body2" color="text.secondary" gutterBottom>
                Complete your profile to unlock more features
              </Typography>
              <Box sx={{ mt: 2 }}>
                <LinearProgress 
                  variant="determinate" 
                  value={user.profileComplete} 
                  sx={{ mb: 1 }}
                />
                <Typography variant="body2" color="text.secondary">
                  {user.profileComplete}% Complete
                </Typography>
              </Box>
            </Paper>

            {/* Getting Started Checklist */}
            <GetStartedSection onAction={handleGetStartedAction} userData={userData} />
          </Grid>

          {/* Middle Column */}
          <Grid item xs={12} md={6}>
           
            {/* Empty State Content */}
            <MainActionsSection />
          </Grid>

          {/* Right Column */}
          <Grid item xs={12} md={3}>
            {/* Suggested Actions */}
            <Paper variant="outlined" sx={{ p: 2, mb: 3, border: 'none' }}>
              <Typography variant="h6" gutterBottom>
                Suggested For You
              </Typography>
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                {SUGGESTED_ACTIONS.map((action, index) => {
                  const isUnlocked = action.isUnlocked(user.connections || user.location || user.reviews || 0);

                  return (
                    <Paper
                      key={index}
                      variant="outlined"
                      onClick={() => isUnlocked && handleSuggestionAction(action.id)}
                      sx={{
                        p: 2,
                        border: 'none',
                        bgcolor: isUnlocked ? 'grey.50' : 'grey.200',
                        cursor: isUnlocked ? 'pointer' : 'not-allowed',
                        '&:hover': {
                          bgcolor: isUnlocked ? 'grey.100' : 'grey.200',
                        },
                        opacity: isUnlocked ? 1 : 0.6,
                      }}
                    >
                      <Typography variant="subtitle2" sx={{ fontWeight: isUnlocked ? 600 : 400 }}>
                        {action.title}
                      </Typography>
                      <Typography variant="body2" color="text.secondary" sx={{ mb: 1 }}>
                        {action.description}
                      </Typography>
                      {!isUnlocked && (
                        <Typography
                          variant="body2"
                          sx={{ color: 'error.main', fontWeight: 'bold' }}
                        >
                          {action.goalMessage}
                        </Typography>
                      )}
                    </Paper>
                  );
                })}
              </Box>
            </Paper>
            {/* Next Goals */}
            <NextGoalsSection userData={userData} />
          </Grid>
        </Grid>
      </Container>
      <LocationModal
        open={locationModalOpen}
        onClose={() => setLocationModalOpen(false)}
        onLocationSet={handleLocationSet}
        userData={userData}
        setUserData={setUserData}
      />
    </Box>
  );
}


