import { useState,  useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import PlacesAutocomplete from 'react-places-autocomplete';
import './AddressPage.css'; // Make sure to include the CSS
import UserRoleSelector from './UserRoleSelector'


import {
  Container,
  Grid,
  Paper,
  Typography,
  Box,
  TextField,
  Button,
  MenuItem,
  Alert,
  Snackbar,
  CircularProgress,
  Skeleton,
  Chip,
  Autocomplete,
  IconButton,
  Stack,
  InputAdornment,

} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';


import DeleteIcon from '@mui/icons-material/Delete';



import { useThingAddMutation  } from '../../redux/thunks/things/thingadd';

import { usePrefetch as useGetAllSkillsPrefetch } from '../../redux/thunks/things/thingsget';
import { useGetAllThingsQuery} from '../../redux/thunks/things/thingsget';
import { useGetPreSignedUrlQuery } from '../../redux/thunks/files/filespresignurl'; // Import your RTK query
import { useSpFindBusinessNameMutation } from '../../redux/thunks/sp/spfindbusinessname';






// Add this constant at the top of your file with other constants
const BUSINESS_TYPES = [
  { value: 'physical', label: 'Physical Business Location' },
  { value: 'online', label: 'Online Business Only' },
];

const TOP_CATEGORIES = [
  { id: 1, name: 'Restaurant' },
  { id: 2, name: 'Retail' },
  { id: 3, name: 'Healthcare' },
  { id: 4, name: 'Technology' },
  { id: 5, name: 'Automotive' },
  { id: 6, name: 'Legal' },
  { id: 7, name: 'Other Professional Services' },
];

// Form Steps
const FORM_STEPS = [
  'Basic Information',
  'Location & Hours',
  'Categories & Services',
  'Photos & Details',
];

// Form Tips by Step
const FORM_TIPS = {
  0: [
    'Provide accurate business name',
    'Choose the most relevant category',
    'Include all contact information',
  ],
  1: [
    'Add complete address details',
    'Specify service areas if applicable',
    'Include business hours',
  ],
  2: [
    'Select all relevant categories',
    'List main services offered',
    'Add important amenities',
  ],
  3: [
    'Upload clear business photos',
    'Add detailed description',
    'Include relevant keywords',
  ],
};



const BUSINESS_HOURS_OPTIONS = [
    { value: '24/7', label: 'Open 24/7' },
    { value: 'mon_fri', label: 'Monday-Friday (9 AM - 5 PM)' },
    { value: 'mon_sat', label: 'Monday-Saturday (9 AM - 5 PM)' },
    { value: 'Custom', label: 'Custom' }
];
  


// Progress Tracker Component
const FormProgress = ({ currentStep, isLoading }) => {
  if (isLoading) {
    return (
      <Paper sx={{ p: 2, mb: 2 }}>
        <Skeleton variant="text" width="60%" height={32} />
        {[1, 2, 3, 4].map((i) => (
          <Skeleton key={i} variant="rectangular" height={50} sx={{ my: 1 }} />
        ))}
      </Paper>
    );
  }

  return (
    <Paper variant='outlined' sx={{ p: 2, mb: 2, border: 'none' }}>
      <Typography variant="h6" gutterBottom>
        Form Progress
      </Typography>
      <Box sx={{ mt: 2 }}>
        {FORM_STEPS.map((step, index) => (
          <Box
            key={index}
            sx={{
              p: 2,
              mb: 1,
              bgcolor: currentStep === index ? 'primary.light' : 'grey.50',
              borderRadius: 1,
              color: currentStep === index ? 'primary.main' : 'text.secondary',
            }}
          >
            <Typography variant="body2">
              {index + 1}. {step} {index < currentStep && '✓'}
            </Typography>
          </Box>
        ))}
      </Box>
    </Paper>
  );
};

// Tips Component
const FormTips = ({ step, isLoading }) => {
  if (isLoading) {
    return (
      <Paper variant='outlined' sx={{ p: 2, border: 'none'}}>
        <Skeleton variant="text" width="40%" height={32} />
        {[1, 2, 3].map((i) => (
          <Skeleton key={i} variant="text" width="80%" sx={{ my: 1 }} />
        ))}
      </Paper>
    );
  }

  return (
    <Paper variant='outlined' sx={{ p: 2, border: 'none'}}>
      <Typography variant="h6" gutterBottom>
        Tips
      </Typography>
      <Box sx={{ mt: 2 }}>
        {FORM_TIPS[step].map((tip, index) => (
          <Typography key={index} variant="body2" sx={{ mb: 1 }}>
            • {tip}
          </Typography>
        ))}
      </Box>
    </Paper>
  );
};

const validateBasicInformation = (data) => {
    const errors = {};
    if (!data.userRole?.trim()) {
      errors.userRole = 'Please select your role in the business';
    }
    if (!data.businessType) {
      errors.businessType = 'Please select business type';
    }
    if (!data.businessName?.trim()) {
      errors.businessName = 'Business name is required';
    }
    if (!data.category) {
      errors.category = 'Category is required';
    }
    if (!data.phone?.trim()) {
      errors.phone = 'Phone number is required';
    }
    if (!data.email?.trim()) {
      errors.email = 'Email is required';
    }
    return errors;
  };

  const validateLocationHours = (data) => {
    const errors = {};
    
    // Validate address only if it's a physical business
    if (data.businessType === 'physical' && !data.address?.trim()) {
        errors.address = 'Physical business address is required';
    }
    
    if (!data.businessHours) {
        errors.businessHours = 'Business hours are required';
    }
    
    return errors;
};
  

// Basic Information Form
const BasicInformationForm = ({ 
    formData, 
    onChange, 
    isLoading, 
    errors = {}, 
    setErrors 
  }) => {

  const validateField = (name, value) => {
    if (name === 'businessType' && !value) {
      return 'Business type is required';
  }
    if (name === 'businessName' && !value?.trim()) {
      return 'Business name is required';
    }
    if (name === 'category' && !value) {
      return 'Category is required';
    }
    if (name === 'phone' && !value?.trim()) {
      return 'Phone number is required';
    }
    if (name === 'email' && !value?.trim()) {
      return 'Email is required';
    }
    return '';
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    // Clear error when user types
    setErrors(prev => ({
      ...prev,
      [name]: validateField(name, value)
    }));
    onChange(e);
  };


  if (isLoading) {
    return (
        <Box>
          <Skeleton variant="text" width="40%" height={32} sx={{ mb: 2 }} />
          <Grid container spacing={3}>
            {[1, 2, 3, 4, 5].map((item) => (
              <Grid item xs={12} key={item}>
                <Skeleton variant="rectangular" height={56} />
              </Grid>
            ))}
          </Grid>
        </Box>
      );
  }

  return (
    <Box>
      <Typography variant="h6" gutterBottom>
        Basic Information
      </Typography>
      
      <Grid container spacing={3}>
      <Grid item xs={12}>
        <UserRoleSelector
          value={formData.userRole}
          onChange={(e) => handleChange(e)}
          error={errors.userRole}
        />
        </Grid>
        <Grid item xs={12}>
          <TextField
              fullWidth
              select
              label="Business Type"
              name="businessType"
              value={formData.businessType || ''}
              onChange={handleChange}
              required
              error={!!errors.businessType}
              helperText={errors.businessType || 'Select whether your business has a physical location or is online-only'}
          >
              {BUSINESS_TYPES.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                      {option.label}
                  </MenuItem>
              ))}
          </TextField>
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Business Name"
            name="businessName"
            value={formData.businessName}
            onChange={handleChange}
            required
            error={!!errors.businessName}
            helperText={errors.businessName}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            select
            label="Primary Category"
            name="category"
            value={formData.category}
            onChange={handleChange}
            required
            error={!!errors.category}
            helperText={errors.category}
          >
            {TOP_CATEGORIES.map((category) => (
              <MenuItem key={category.id} value={category.name}>
                {category.name}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Phone Number"
            name="phone"
            value={formData.phone}
            onChange={handleChange}
            required
            error={!!errors.phone}
            helperText={errors.phone}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Email"
            name="email"
            type="email"
            value={formData.email}
            onChange={handleChange}
            required
            error={!!errors.email}
            helperText={errors.email}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Website"
            name="website"
            value={formData.website}
            onChange={onChange}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            multiline
            rows={4}
            label="Business Description"
            name="description"
            value={formData.description}
            onChange={onChange}
            
          />
        </Grid>
      </Grid>
    </Box>
  );
};

// Similar Businesses Component
const SimilarBusinesses = ({  formData, currentStep, isLoading, similarBusinesses  }) => {

    const navigate = useNavigate();
    const [similarBusinessesList, setSimilarBusinessesList] = useState(similarBusinesses);

    useEffect(() => {
        // Update the state when `similarBusinesses` changes
        setSimilarBusinessesList(similarBusinesses);
      }, [similarBusinesses]);



    useEffect(() => {
        console.log('formData', formData)

        // Only process if we're past step 0 and have basic information
        if (currentStep === 0 || !formData.businessName || !formData.category) {
            setSimilarBusinessesList([]);
          return;
        }
    
        if (isLoading) return;
    
        const filterBusinesses = () => {
          let filtered = [...similarBusinesses];

          console.log('Before filtering, similarBusinessesList', similarBusinessesList)
          console.log('Before filtering', filtered)
    
          // First filter by basic information (always apply these filters)
          filtered = filtered.filter(business => {
            const categoryMatch = business.category[0].toLowerCase() === formData.category.toLowerCase();
          
            const nameMatch = (() => {
              if (!formData.businessName) return true; // If no business name is provided, match all names
              const formNameWords = formData.businessName.toLowerCase().split(' ');
              const businessNameWords = business.name.toLowerCase().split(' ');
          
              // Check if any word in formNameWords exists in businessNameWords
              return formNameWords.some(word => businessNameWords.includes(word)) ||
                     businessNameWords.some(word => formNameWords.includes(word));
            })();
          
            return categoryMatch && nameMatch;
          });

          console.log('After filtering', filtered)
    
          // Additional filters based on current step
          if (currentStep >= 1 && formData.address) {
            filtered = filtered.filter((business) => {
              // Normalize address into words and convert to lowercase
              const addressWords = formData.address.toLowerCase().replace(/[,]/g, '').split(/\s+/); // Split into words
              //console.log('business', business)
          
              // Normalize and check each field
              //const zipCode = business.zipCode ? business.zipCode.toLowerCase() : null;
              //console.log('zipCode', zipCode)
              const region = business.region ? business.region.toLowerCase() : null;
              //console.log('region', region)
              const locality = business.locality ? business.locality.toLowerCase() : null;
              //console.log('locality', locality)
          
              // Check if zipCode matches
              //if (zipCode && addressWords.includes(zipCode)) {
              //  return true;
             // }
          
              // Check if region matches
              if (region && addressWords.includes(region)) {
                return true;
              }
          
              // Check if locality matches
              if (locality && addressWords.includes(locality)) {
                return true;
              }
          
              // If no matches are found, filter out the business
              return false;
            });
          }
    
          if (currentStep >= 2 && formData.additionalCategories?.length) {
            filtered = filtered.filter(business => {
              return formData.additionalCategories.some(category =>
                business.additionalCategories.includes(category.name)
              );
            });
          }
    
          return filtered.slice(0, 5);
        };
    
        setSimilarBusinessesList(filterBusinesses());
      }, [formData, currentStep, isLoading, similarBusinesses]);
    

  if (isLoading) {
    return (
        <Box>
            <Paper variant='outlined' sx={{ p: 2, border: 'none' }}>
                <Skeleton variant="text" width="60%" height={32} />
                {[1, 2].map((i) => (
                <Skeleton key={i} variant="rectangular" height={60} sx={{ my: 1 }} />
                ))}
            </Paper>

            <Paper variant='outlined' sx={{ p: 2, border: 'none' }}>
                <Skeleton variant="text" width="60%" height={32} />
                {[1, 2].map((i) => (
                <Skeleton key={i} variant="rectangular" height={60} sx={{ my: 1 }} />
                ))}
            </Paper>

            <Paper variant='outlined' sx={{ p: 2, border: 'none' }}>
                <Skeleton variant="text" width="60%" height={32} />
                {[1, 2].map((i) => (
                <Skeleton key={i} variant="rectangular" height={60} sx={{ my: 1 }} />
                ))}
            </Paper>

        </Box>
      
    );
  }

  return (
    <Paper sx={{ p: 2 }}>
      <Typography variant="h6" gutterBottom>
        Similar Businesses
      </Typography>

      <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
        Businesses similar to {formData.businessName}
      </Typography>

      {similarBusinessesList.length > 0 ? (
        <Box>
          {similarBusinessesList.map((business) => (
            <Paper
              key={business.id}
              variant="outlined"
              sx={{ p: 2, mb: 2, border: 'none', bgcolor: 'grey.50' }}
              onClick={() =>
                navigate(`/business/${business._id}/${encodeURIComponent(business.name.replace(/[\s,\.]+/g, '-').toLowerCase())}`)
              }
            >
              <Typography variant="subtitle2" gutterBottom>
                {business.name}
              </Typography>
              <Stack direction="row" spacing={1}>
                <Typography variant="caption" color="text.secondary">
                    {business.category[0]}
                </Typography>
                <Typography variant="caption" color="text.secondary">
                    {business.region}
                </Typography>

              </Stack>
              
              {business.category.length > 0 && (
                <Box sx={{ mt: 1, display: 'flex', gap: 0.5, flexWrap: 'wrap' }}>
                  {business.category.map((category, index) => (
                    <Chip
                      key={index}
                      label={category}
                      size="small"
                      variant="outlined"
                    />
                  ))}
                </Box>
              )}
            </Paper>
          ))}
        </Box>
      ) : (
        <Typography variant="body2" color="text.secondary">
          No similar businesses found. This could mean your business is unique in this area!
        </Typography>
      )}
    </Paper>
  );
};

const LocationHoursForm = ({ formData, onChange, isLoading, errors = {} }) => {
    if (isLoading) {
        return (
            <Box>
              <Skeleton variant="text" width="40%" height={32} sx={{ mb: 2 }} />
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Skeleton variant="rectangular" height={56} sx={{ mb: 2 }} />
                </Grid>
                <Grid item xs={12}>
                  <Skeleton variant="rectangular" height={56} sx={{ mb: 2 }} />
                </Grid>
                <Grid item xs={12}>
                  <Skeleton variant="rectangular" height={120} />
                </Grid>
              </Grid>
            </Box>
          );
    }

    const handleAddressChange = (address) => {
        onChange({
          target: {
            name: 'address',
            value: address
          }
        });
    };

    const handleAddressSelect = async (address) => {
        try {
          handleAddressChange(address);
          // You can add geocoding here if needed
        } catch (error) {
          console.error('Error selecting address:', error);
        }
    };
  
    return (
      <Box>
          <Typography variant="h6" gutterBottom>
              Location & Hours
          </Typography>
          
          <Grid container spacing={3}>
              {/* Show address field for physical businesses */}
              
                  <Grid item xs={12}>
                      <Typography variant="subtitle1" gutterBottom>
                          Business Address
                      </Typography>
                      <PlacesAutocomplete
                          value={formData.address || ''}
                          onChange={handleAddressChange}
                          onSelect={handleAddressSelect}
                      >
                          {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                              <div className="dropdown-container">
                                  <TextField
                                      {...getInputProps({
                                          placeholder: 'Enter business address',
                                          variant: 'outlined',
                                          fullWidth: true,
                                          required: true,
                                          error: !!errors.address,
                                          helperText: errors.address
                                      })}
                                  />
                                  {suggestions.length > 0 && (
                                      <Paper className="dropdown-suggestions">
                                          {loading && <div>Loading...</div>}
                                          {suggestions.map((suggestion) => {
                                              const { key, ...suggestionProps } = getSuggestionItemProps(suggestion, {
                                                  className: 'suggestion-item',
                                              });
                                              return (
                                                  <div
                                                      key={suggestion.placeId}
                                                      {...suggestionProps}
                                                  >
                                                      {suggestion.description}
                                                  </div>
                                              );
                                          })}
                                      </Paper>
                                  )}
                              </div>
                          )}
                      </PlacesAutocomplete>
                  </Grid>
              

              {/* Business Hours */}
              <Grid item xs={12} sx={{ mt: 2 }}>
                  <Typography variant="subtitle1" gutterBottom>
                      Business Hours
                  </Typography>
                  <TextField
                      select
                      fullWidth
                      required
                      name="businessHours"
                      value={formData.businessHours || ''}
                      onChange={onChange}
                      variant="outlined"
                      error={!!errors.businessHours}
                      helperText={errors.businessHours}
                  >
                      {BUSINESS_HOURS_OPTIONS.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                              {option.label}
                          </MenuItem>
                      ))}
                  </TextField>
              </Grid>

              {/* Additional Information */}
              <Grid item xs={12} sx={{ mt: 2 }}>
                  <Typography variant="subtitle1" gutterBottom>
                      Additional Information
                  </Typography>
                  <TextField
                      fullWidth
                      multiline
                      rows={4}
                      name="additionalInfo"
                      value={formData.additionalInfo || ''}
                      onChange={onChange}
                      variant="outlined"
                      placeholder="Add any additional information about business hours or special occasions (e.g., 'Open on Christmas', 'Early closure on Sundays')"
                  />
              </Grid>
          </Grid>
      </Box>
  );
};

const CategoriesServicesForm = ({ formData, onChange, isLoading }) => {
    const token = useSelector((state) => state.auth?.token || null);
    const userId = useSelector((state) => state.auth?.userId || null);
    const spId = useSelector((state) => state.auth?.user?.spId);  

    const args = {
        spId: spId,
        authType: 'token',
        token: token
    }
    
    const { data: categoriesData, isLoading: iscategoriesDataLoading } = useGetAllThingsQuery(args);

    // Handler for platform changes
    const handlePlatformChange = (index, field, value) => {
        const platforms = [...(formData.platforms || [])];
        if (!platforms[index]) {
            platforms[index] = {};
        }
        platforms[index][field] = value;

        onChange({
            target: {
                name: 'platforms',
                value: platforms
            }
        });
    };

    const handleCategoryChange = (_, value) => {
        onChange({
          target: {
            name: 'additionalCategories',
            value: value
          }
        });
    };

    // Handler to remove a platform
    const handleRemovePlatform = (indexToRemove) => {
        const platforms = (formData.platforms || [])
            .filter((_, index) => index !== indexToRemove);

        onChange({
            target: {
                name: 'platforms',
                value: platforms
            }
        });
    };

    if (isLoading || iscategoriesDataLoading) {
        return (
            <Box>
                <Skeleton variant="text" width="40%" height={32} sx={{ mb: 2 }} />
                <Typography variant="subtitle1" gutterBottom>
                    <Skeleton variant="text" width="30%" />
                </Typography>
                <Skeleton variant="rectangular" height={56} sx={{ mb: 2 }} />
                <Skeleton variant="text" width="40%" height={32} sx={{ mt: 4, mb: 2 }} />
                {[1, 2].map((i) => (
                    <Skeleton key={i} variant="rectangular" height={80} sx={{ mb: 2 }} />
                ))}
            </Box>
        );
    }

    return (
        <Box>
            {/* Categories Section */}
            <Typography variant="h6" gutterBottom>
                Categories
            </Typography>
            
            <Box sx={{ mt: 3, mb: 5 }}>
                <Typography variant="subtitle1" gutterBottom>
                    Select additional categories
                </Typography>
                <Autocomplete
                    multiple
                    options={categoriesData.data}
                    value={formData.additionalCategories || []}
                    getOptionLabel={(option) => (option ? option.name || '' : '')}
                    onChange={handleCategoryChange}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            variant="outlined"
                            placeholder="Search and select categories"
                            helperText="You can select multiple categories"
                            fullWidth
                        />
                    )}
                    renderTags={(value, getTagProps) =>
                        value.map((option, index) => {
                            const { key, ...chipProps } = getTagProps({ index });
                            return (
                                <Chip
                                    key={key}
                                    {...chipProps}
                                    label={option?.name}
                                    color="primary"
                                    variant="outlined"
                                />
                            );
                        })
                    }
                />
            </Box>

            {/* Platforms Section */}
            <Box sx={{ mt: 4 }}>
                <Typography variant="h6" gutterBottom>
                    Other Platforms
                </Typography>
                <Typography variant="body2" color="text.secondary" gutterBottom>
                    Add up to 4 other platforms where your business is available
                </Typography>

                {/* Platform Input Fields */}
                <Box sx={{ mt: 2 }}>
                    {Array.from({ length: 4 }).map((_, index) => {
                        const platforms = formData.platforms || [];
                        const platform = platforms[index] || {};
                        const isLastPlatform = index === 3;

                        // Show field if:
                        // - it's not the last possible platform AND there's a platform before it
                        // - OR it's the first one
                        // - OR the current platform has data
                        const shouldShow = 
                            index === 0 || 
                            platform.name || 
                            platform.link || 
                            (index > 0 && platforms[index - 1] && (platforms[index - 1].name || platforms[index - 1].link));

                        if (!shouldShow) return null;

                        return (
                            <Paper
                                key={index}
                                variant="outlined"
                                sx={{ p: 2, mb: 2, bgcolor: 'background.paper' }}
                            >
                                <Grid container spacing={2} alignItems="center">
                                    <Grid item xs={12} sm={5}>
                                        <TextField
                                            fullWidth
                                            label={`Platform ${index + 1} Name`}
                                            placeholder="e.g., Thumbtack"
                                            value={platform.name || ''}
                                            onChange={(e) => handlePlatformChange(index, 'name', e.target.value)}
                                            variant="outlined"
                                            size="small"
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={5}>
                                        <TextField
                                            fullWidth
                                            label={`Platform ${index + 1} Link`}
                                            placeholder="https://"
                                            value={platform.link || ''}
                                            onChange={(e) => handlePlatformChange(index, 'link', e.target.value)}
                                            variant="outlined"
                                            size="small"
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={2}>
                                        {(platform.name || platform.link) && (
                                            <IconButton 
                                                onClick={() => handleRemovePlatform(index)}
                                                color="error"
                                            >
                                                <DeleteIcon />
                                            </IconButton>
                                        )}
                                    </Grid>
                                </Grid>
                            </Paper>
                        );
                    })}
                </Box>

                {/* Show message when max platforms reached */}
                {formData.platforms?.length === 4 && (
                    <Typography variant="body2" color="error" sx={{ mt: 1 }}>
                        Maximum number of platforms reached (4)
                    </Typography>
                )}
            </Box>
        </Box>
    );
};
  
  // Photos & Details Form
const PhotosDetailsForm = ({ formData, onChange, isLoading }) => {

    const [inputValue, setInputValue] = useState('');
    const [error, setError] = useState('');
    
    const MAX_TAGS = 10;
    const MAX_TAG_LENGTH = 20;

    const handleKeyDown = (e) => {
        if (e.key === 'Enter' || e.key === ',') {
            e.preventDefault();
            addTag();
        }
    };

      const addTag = () => {
        const tag = inputValue.trim().toLowerCase();
        if (!tag) return;

        if (tag.length > MAX_TAG_LENGTH) {
            setError(`Tags must be ${MAX_TAG_LENGTH} characters or less`);
            return;
        }

        const currentTags = formData.keywords || [];
        
        if (currentTags.length >= MAX_TAGS) {
            setError(`Maximum ${MAX_TAGS} tags allowed`);
            return;
        }

        if (currentTags.includes(tag)) {
            setError('This tag already exists');
            return;
        }

        const newTags = [...currentTags, tag];
        onChange({
            target: {
                name: 'keywords',
                value: newTags // Now storing as array directly
            }
        });
        setInputValue('');
        setError('');
    };

      const removeTag = (tagToRemove) => {
        const currentTags = formData.keywords || [];
        const newTags = currentTags.filter(tag => tag !== tagToRemove);
        onChange({
            target: {
                name: 'keywords',
                value: newTags // Maintaining array format
            }
        });
    };
    if (isLoading) {
        return (
            <Box>
              <Skeleton variant="text" width="40%" height={32} sx={{ mb: 2 }} />
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Skeleton variant="rectangular" height={100} />
                  <Skeleton variant="text" width="30%" sx={{ mt: 1 }} />
                </Grid>
                <Grid item xs={12}>
                  <Skeleton variant="text" width="30%" />
                  <Skeleton variant="rectangular" height={120} />
                </Grid>
                <Grid item xs={12}>
                  <Skeleton variant="text" width="30%" />
                  <Skeleton variant="rectangular" height={56} />
                </Grid>
              </Grid>
            </Box>
          );
    }

    const currentTags = formData.keywords || [];
    const handleFileChange = (e) => {
        const file = e.target.files[0]; // For now, let's handle one file
        if (file) {
        onChange({
            target: {
            name: 'photo',
            value: file
            }
        });
        }
    };

    return (
        <Box>
          <Typography variant="h6" gutterBottom>
            Photos & Details
          </Typography>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Button
                variant="outlined"
                component="label"
                fullWidth
                sx={{ height: 100 }}
              >
                {formData.photo ? 'Change Photo' : 'Upload Photo'}
                <input
                  type="file"
                  hidden
                  accept="image/*"
                  onChange={handleFileChange}
                />
              </Button>
              {formData.photo && (
                <Typography variant="body2" sx={{ mt: 1 }}>
                  Selected: {formData.photo.name}
                </Typography>
              )}
            </Grid>
    
            <Grid item xs={12}>
              <TextField
                fullWidth
                multiline
                rows={4}
                label="Special Features"
                name="specialFeatures"
                value={formData.specialFeatures}
                onChange={onChange}
                placeholder="Describe any unique features or specialties..."
              />
            </Grid>
    
            <Grid item xs={12}>
                    <Box sx={{ mb: 2 }}>
                        <TextField
                            fullWidth
                            label="Keywords/Tags"
                            value={inputValue}
                            onChange={(e) => setInputValue(e.target.value)}
                            onKeyDown={handleKeyDown}
                            placeholder="Type and press Enter to add tags"
                            error={!!error}
                            helperText={error || `${currentTags.length}/${MAX_TAGS} tags used. Press Enter or comma to add a tag`}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton 
                                            onClick={addTag}
                                            disabled={!inputValue.trim() || currentTags.length >= MAX_TAGS}
                                        >
                                            <AddIcon />
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Box>
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                        {currentTags.map((tag, index) => (
                            <Chip
                                key={index}
                                label={tag}
                                onDelete={() => removeTag(tag)}
                                color="primary"
                                variant="outlined"
                            />
                        ))}
                    </Box>
                </Grid>
          </Grid>
        </Box>
      );
};

// Main Add Business Page Component
const AddBusinessPage = () => {
  const navigate = useNavigate();

  const token = useSelector((state) => state.auth?.token || null);
  const userId = useSelector((state) => state.auth?.userId || null);
  const spId = useSelector((state) => state.auth?.user?.spId);  

  const args = {
    eventType: 'BusinessCreatePhotos',
    userId
  }

  const { data: presignedUrlData, isLoading: isPresignedUrlLoading } = useGetPreSignedUrlQuery(args);
  console.log('presignedUrlData', presignedUrlData)

  const [
    triggerSearchBusinessName,
    { data: similarBusinessesData = {}, isLoading: isSimilarBusinessesDataLoading },
  ] = useSpFindBusinessNameMutation();

  console.log('similarBusinessesData', similarBusinessesData)

  
  const [currentStep, setCurrentStep] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({}); // Add errors state
  //const [addBusiness] = useAddBusinessMutation();
  const [formData, setFormData] = useState({
    // Basic Information
    businessName: '',
    businessType:'',
    category: '',
    phone: '',
    email: '',
    website: '',
    description: '',
    userRole: '',
  
    // Location & Hours
    address: '', // Single address field from PlacesAutocomplete
    businessHours: '', // Single selection from dropdown
    additionalInfo: '', // New field for additional information
  
    // Categories
    additionalCategories: [],
  
    // Photos & Details
    photo: '',
    specialFeatures: '',
    keywords: [],

    //Platforms
    platforms: [],
  });

  const [similarBusinesses, setSimilarBusinesses] = useState([]); // Add errors state

  

  const prefetchGetAllSkills = useGetAllSkillsPrefetch('getAllThings') 
  const [addThing, { isLoading: addBusinessLoading, isError }] = useThingAddMutation();

  useEffect(() => {
    
    const prefetchAll = async () => {
      const args = {
        spId: spId,
        authType: 'token',
        token: token
      }

      await Promise.all([
        prefetchGetAllSkills(args),
        //prefetchGetPreSignedUrl()
        
      ]);
    
    }

    prefetchAll();   

  }, []);

  useEffect(() => {
    
    console.log('similarBusinessesData', similarBusinessesData)
    if(similarBusinessesData && similarBusinessesData.results &&  similarBusinessesData.results.length) {
        setSimilarBusinesses(similarBusinessesData.results)

    }

    

  }, [similarBusinessesData]);


  const uploadPhotoToS3 = async (file, presignedUrl) => {

    console.log('file', file)
    try {
      const response = await fetch(presignedUrl, {
        method: 'PUT',
        body: file,
        headers: {
          'Content-Type': file.type,
          'x-amz-meta-eventtype': 'BusinessCreatePhotos'
        },

      });

      if (!response.ok) {
        throw new Error('Failed to upload photo');
      }

      return true;
    } catch (error) {
      console.error('Error uploading photo:', error);
      throw error;
    }
  };


  const validateCurrentStep = () => {
    switch (currentStep) {
      case 0:
        const basicErrors = validateBasicInformation(formData);
        setErrors(basicErrors);
        return Object.keys(basicErrors).length === 0;
      case 1:
        const locationErrors = validateLocationHours(formData);
        setErrors(locationErrors);
        return Object.keys(locationErrors).length === 0;
      // Add validation for other steps as needed
      default:
        return true;
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };



  const handleNext = async () => {
    // First validate the current step
    if (!validateCurrentStep()) {
        return; // Don't proceed if validation fails
    }

    // If we're on the first step, do the business name search
    if (currentStep === 0) {
        try {
            let auth = {
                token: token,
                authType: 'token',
            };
            const payload = {
                auth,
                text: formData.businessName,
            };

            setCurrentStep(1);

            // Call the mutation
            await triggerSearchBusinessName({payload}).unwrap();
            
            // Move to next step after successful search
            
        } catch (error) {
            console.error('Failed to initiate business:', error);
            // Still allow proceeding to next step even if search fails
            setCurrentStep(1);
        }
        return;
    }

    // If we're on the last step, handle submission
    if (currentStep === FORM_STEPS.length - 1) {
        handleSubmit();
        return;
    }

    // Normal step progression
    setCurrentStep(prev => prev + 1);
};

  const handleBack = () => {
    setCurrentStep((prev) => prev - 1);
  };

  const handleSubmit = async () => {
     // Validate all steps before submitting
     const basicErrors = validateBasicInformation(formData);
     const locationErrors = validateLocationHours(formData);
     const allErrors = { ...basicErrors, ...locationErrors };
     // Add other validation checks
 
     if (Object.keys(allErrors).length > 0) {
      setErrors(allErrors);
      // Go back to the first step with errors
      if (Object.keys(basicErrors).length > 0) {
          setCurrentStep(0);
      } else if (Object.keys(locationErrors).length > 0) {
          setCurrentStep(1);
      }
      return;
  }
    try {
      setIsLoading(true);
      // Log the complete form data
      console.log('Form Data to be submitted:', {
        ...formData,
        businessHours: BUSINESS_HOURS_OPTIONS.find(opt => opt.value === formData.businessHours)?.label || formData.businessHours,
        
      });

      // First, upload photo if exists
      let photoUrl = null;

      if (formData.photo) {
        
        const presignedUrl = presignedUrlData.url;
        await uploadPhotoToS3(formData.photo, presignedUrl);
        photoUrl = presignedUrl.split('?')[0]; // Get the base URL without query params
      }
      // Prepare form data for submission
      const businessData = {
        ...formData,
        photoUrl,
        // Remove the actual file object as it's already uploaded
        photo: undefined,
        // Add any other necessary transformations
        businessHours: BUSINESS_HOURS_OPTIONS.find(
          opt => opt.value === formData.businessHours)?.label || formData.businessHours,        
      };

      const auth = {
        token: token,
        authType: 'token',
      };
    
      const payload = {
        auth,        
        spId,
        data: businessData
      };
      const response = await addThing(payload);
      console.log('Form data response:', response);      
      navigate(`/business/${response.data.businessId}/${response.data.businessName.replace(/[\s,\.]+/g, '-').toLowerCase()}`);
    } catch (error) {
      console.error('Failed to add business:', error);
    } finally {
      setIsLoading(false);
    }
  };
  

  const renderStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <BasicInformationForm
            formData={formData}
            onChange={handleChange}
            isLoading={isLoading}
            errors={errors}  // Pass errors to the form
            setErrors={setErrors}  // Pass setErrors to allow form-level error updates
          />
        );
      case 1:
        return (
          <LocationHoursForm
            formData={formData}
            onChange={handleChange}
            isLoading={isLoading}
            errors={errors}
          />
        );
      case 2:
        return (
          <CategoriesServicesForm
            formData={formData}
            onChange={handleChange}
            isLoading={isLoading}
          />
        );
      case 3:
        return (
          <PhotosDetailsForm
            formData={formData}
            onChange={handleChange}
            isLoading={isLoading}
          />
        );
      default:
        return null;
    }
  };

  

  return (
    <>
      {/* Header */}
      <Box sx={{ bgcolor: 'primary.main', color: 'white', py: 2, mb: 3 }}>
        <Container maxWidth="xl">
          <Typography variant="h5">
            Add a New Business
          </Typography>
        </Container>
      </Box>

      <Container maxWidth="xl">
        <Grid container spacing={3}>
          {/* Left Column */}
          <Grid item xs={12} md={3}>
            <FormProgress currentStep={currentStep} isLoading={isLoading} />
            <FormTips step={currentStep} isLoading={isLoading} />
          </Grid>

          {/* Middle Column */}
          <Grid item xs={12} md={6}>
            <Paper variant='outlined' sx={{ p: 3, border: 'none' }}>
            {renderStepContent(currentStep)}
              {/* Add other step forms here */}

              <Box sx={{ mt: 4, display: 'flex', justifyContent: 'space-between' }}>
                <Button
                  onClick={handleBack}
                  disabled={currentStep === 0 || isLoading}
                >
                  Back
                </Button>
                <Button
                  variant="contained"
                  onClick={handleNext}
                  disabled={isLoading}
                >
                  {isLoading ? (
                    <CircularProgress size={24} />
                  ) : currentStep === FORM_STEPS.length - 1 ? (
                    'Submit'
                  ) : (
                    'Next'
                  )}
                </Button>
              </Box>
            </Paper>
          </Grid>

          {/* Right Column */}
          <Grid item xs={12} md={3}>
            <SimilarBusinesses
              formData={formData}
              currentStep={currentStep}
              isLoading={isLoading || isSimilarBusinessesDataLoading}
              similarBusinesses={similarBusinesses}
            />
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default AddBusinessPage;