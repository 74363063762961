import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
  Box,
  Card,
  CardContent,
  Typography,
  Button,
  Stack,
  Avatar,
  LinearProgress
} from '@mui/material';

const BusinessSection = ({ userData }) => {
  const navigate = useNavigate();
  const thisUserId = useSelector((state) => state.auth?.userId || null);
  const [isLoggedInUser, setIsLoggedInUser] = useState(false);

  useEffect(() => {
      // Check if this is the logged-in user's profile
      if (thisUserId === userData._id) {
        setIsLoggedInUser(true);
        return;
      } 
     
    }, [thisUserId, userData]);
  const { myBusinesses } = userData;

  // Only return null if there are no businesses
  if (!myBusinesses || myBusinesses.length === 0) {
    return null;
  }

  return (
    <Card variant="outlined" sx={{ border: 'none' }}>
      <CardContent>
        <Typography variant="h6" gutterBottom>
          {isLoggedInUser ? 'My Businesses' : 'Businesses'}
        </Typography>
        <Stack spacing={3}>
          {myBusinesses.map((business) => (
            <Box key={business.id}>
              <Box 
              sx={{ display: 'flex', alignItems: 'center', gap: 2, mb: 1, cursor: 'pointer' }}
              onClick={() => navigate(`/business/${business._id}/${business.businessName.replace(/[\s,\.]+/g, '-').toLowerCase()}`)}
              >
                <Avatar
                  src={business.photoUrl}
                  alt={business.businessName}
                  sx={{ width: 30, height: 30 }}
                >
                  {business.businessName[0]}
                </Avatar>
                <Box>
                  <Typography variant="body2">{business.businessName}</Typography>
                  <Typography variant="caption" color="text.secondary">
                    {business.category[0]}
                  </Typography>
                </Box>
              </Box>
             
            </Box>
          ))}
        </Stack>
        {isLoggedInUser && (
          <Box sx={{ textAlign: 'center', mt: 2 }}>
            <Button 
              variant="contained" 
              color="primary"
              size="small"
              onClick={() => navigate('/company/new')}
            >
              Add Business
            </Button>
          </Box>
        )}
      </CardContent>
    </Card>
  );
};

export default BusinessSection;