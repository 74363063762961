import React from "react";
import { Container, Box, Link, Typography, Grid, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";

const Footer = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  // Option 1: Grid-based layout for better distribution on mobile
  const renderGridLayout = () => (
    <Grid container spacing={2} justifyContent="center" sx={{ pt: 3 }}>
      {[
        "About",
        "Help Center",
        "Privacy",
        "Legal",
        "Blogs",
        "Apps",
        "Social",
      ].map((text) => (
        <Grid item xs={6} sm={4} md="auto" key={text} sx={{ textAlign: "center" }}>
          <Link
            href={`/${text.toLowerCase()}`}
            color="inherit"
            sx={{
              fontSize: { xs: "0.8rem", md: "0.875rem" },
              textDecoration: "none",
              "&:hover": {
                textDecoration: "underline",
              },
            }}
          >
            {text}
          </Link>
        </Grid>
      ))}
    </Grid>
  );

  // Option 2: Two-row layout on mobile
  const renderTwoRowLayout = () => {
    const links = [
      "About",
      "Help Center",
      "Privacy",
      "Legal",
      "Blogs",
      "Apps",
      "Social",
    ];
    
    const firstRow = links.slice(0, 4);
    const secondRow = links.slice(4);

    return (
      <Box sx={{ pt: 3 }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            flexWrap: "wrap",
            mb: 2,
          }}
        >
          {firstRow.map((text) => (
            <Link
              key={text}
              href={`/${text.toLowerCase()}`}
              color="inherit"
              sx={{
                fontSize: "0.8rem",
                textDecoration: "none",
                "&:hover": {
                  textDecoration: "underline",
                },
                px: 2,
                py: 1,
              }}
            >
              {text}
            </Link>
          ))}
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            flexWrap: "wrap",
          }}
        >
          {secondRow.map((text) => (
            <Link
              key={text}
              href={`/${text.toLowerCase()}`}
              color="inherit"
              sx={{
                fontSize: "0.8rem",
                textDecoration: "none",
                "&:hover": {
                  textDecoration: "underline",
                },
                px: 2,
                py: 1,
              }}
            >
              {text}
            </Link>
          ))}
        </Box>
      </Box>
    );
  };

  return (
    <Box
      component="footer"
      sx={{
        backgroundColor: "#F8F9F9",
        padding: "20px 0",
        pt: 5,
        textAlign: "center",
        width: "100%",
      }}
    >
      <Container maxWidth="lg">
        <Box
          sx={{
            borderTop: "1px solid #efefef",
          }}
        >
          {isMobile ? renderTwoRowLayout() : renderGridLayout()}
        </Box>
        <Box
          sx={{
            mt: 3,
            mb: 1,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            src="/assets/revfer/skyblue.svg"
            alt="Revfer Logo"
            style={{ width: "30px", height: "15px" }}
          />
          <Typography variant="body2" sx={{ ml: 1, fontSize: "0.75rem" }}>
            Revfer &copy; {new Date().getFullYear()}
          </Typography>
        </Box>
      </Container>
    </Box>
  );
};

export default Footer;