
import {userApi} from './user'

//Get suggestion (users to connect) for this user. 
export const userSuggestionsApi = userApi.injectEndpoints({
  endpoints: (builder) => ({
    
    getUserSuggestions: builder.query({
      
      query: () => `/suggesions`,
    }),
    

  }),
  overrideExisting: false,
})

export const { useGetUserSuggestionsQuery } = userSuggestionsApi