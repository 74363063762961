
import {userApi} from './user'

//Get suggestion (users to connect) for this user. 
export const connectedBusinessesApi = userApi.injectEndpoints({
  endpoints: (builder) => ({
    
    getConnectedBusinesses: builder.query({
      
      query: () => `/connectedbusinesses`,
    }),
    

  }),
  overrideExisting: false,
})

export const { useGetConnectedBusinessesQuery } = connectedBusinessesApi