import React from 'react';
import { Grid, Card, CardContent, Typography, Box, Avatar, Divider, CircularProgress } from '@mui/material';
import { Chart } from 'react-google-charts';
import StarIcon from '@mui/icons-material/Star';
import RateReviewIcon from '@mui/icons-material/RateReview';
import SentimentVerySatisfiedIcon from '@mui/icons-material/SentimentVerySatisfied';
import SentimentNeutralIcon from '@mui/icons-material/SentimentNeutral';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import PageviewIcon from '@mui/icons-material/Pageview';
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';
import AccessibilityIcon from '@mui/icons-material/Accessibility';
import TimelineIcon from '@mui/icons-material/Timeline';

const ComparativeAnalysis = ({ data }) => {
  // Get data from props or use static data for demonstration
  //data?.comparativeAnalysisData ||
  const comparativeAnalysisData =  {
    // First Row: Key Metrics Comparison
    starRatingComparison: {
      yours: 4.2,
      competition: 4.0,
    },
    reviewCountComparison: {
      yours: 450,
      competition: 500,
    },
    sentimentComparison: {
      yours: 'positive',
      competition: 'negative',
    },
    responseRateComparison: {
      yours: 80, // percentage
      competition: 75, // percentage
    },
    responseTimeComparison: {
      yours: 10, // hours
      competition: 8, // hours
    },
    pageViewsComparison: {
      yours: 10000,
      competition: 12000,
    },

    // Second Row: Monthly Trends
    monthlyReviewCount: [
      { month: 'Jan-2024', yours: 30, competition: 35 },
      { month: 'Feb-2024', yours: 35, competition: 40 },
      { month: 'Mar-2024', yours: 40, competition: 45 },
      { month: 'Apr-2024', yours: 50, competition: 55 },
      { month: 'May-2024', yours: 55, competition: 60 },
      { month: 'Jun-2024', yours: 60, competition: 65 },
      { month: 'Jul-2024', yours: 80, competition: 75 },
    ],
    monthlySentimentTrend: [
      { month: 'Jan-2024', yours: 1, competition: 0 },
      { month: 'Feb-2024', yours: 1, competition: -1 },
      { month: 'Mar-2024', yours: 0, competition: -1 },
      { month: 'Apr-2024', yours: 1, competition: 0 },
      { month: 'May-2024', yours: 1, competition: 0 },
      { month: 'Jun-2024', yours: 0, competition: -1 },
      { month: 'Jul-2024', yours: 1, competition: 1 },
    ],
    monthlyResponseRate: [
      { month: 'Jan-2024', yours: 75, competition: 70 },
      { month: 'Feb-2024', yours: 78, competition: 73 },
      { month: 'Mar-2024', yours: 80, competition: 75 },
      { month: 'Apr-2024', yours: 82, competition: 76 },
      { month: 'May-2024', yours: 85, competition: 78 },
      { month: 'Jun-2024', yours: 88, competition: 80 },
      { month: 'Jul-2024', yours: 90, competition: 83 },
    ],

    // Third Row: Demographic Comparisons
    ageGroupComparison: [
      { ageGroup: '18-24', yours: 100, competition: 90 },
      { ageGroup: '25-34', yours: 150, competition: 140 },
      { ageGroup: '35-44', yours: 120, competition: 130 },
      { ageGroup: '45-54', yours: 50, competition: 60 },
      { ageGroup: '55+', yours: 30, competition: 35 },
    ],
    genderDistributionComparison: {
      yours: { male: 55, female: 45 }, // percentage
      competition: { male: 50, female: 50 }, // percentage
    },
  };

  // Data preparation for charts
  const reviewCountData = [
    ['Month', 'Your Business', 'Competition'],
    ...comparativeAnalysisData.monthlyReviewCount.map(data => [
      data.month,
      data.yours,
      data.competition,
    ]),
  ];

  const reviewCountOptions = {
    hAxis: { title: 'Month' },
    vAxis: { title: 'Review Count' },
    colors: ['#3f51b5', '#9e9e9e'],
    legend: { position: 'bottom' },
    chartArea: { width: '80%', height: '70%' },
    backgroundColor: 'transparent'
  };

  const sentimentTrendData = [
    ['Month', 'Your Business', 'Competition'],
    ...comparativeAnalysisData.monthlySentimentTrend.map(data => [
      data.month,
      data.yours,
      data.competition,
    ]),
  ];

  const sentimentTrendOptions = {
    hAxis: { title: 'Month' },
    vAxis: { 
      title: 'Sentiment Score', 
      minValue: -1, 
      maxValue: 1,
      ticks: [-1, -0.5, 0, 0.5, 1]
    },
    colors: ['#4CAF50', '#9e9e9e'],
    legend: { position: 'bottom' },
    chartArea: { width: '80%', height: '70%' },
    backgroundColor: 'transparent'
  };

  const responseRateData = [
    ['Month', 'Your Business', 'Competition'],
    ...comparativeAnalysisData.monthlyResponseRate.map(data => [
      data.month,
      data.yours,
      data.competition,
    ]),
  ];

  const responseRateOptions = {
    hAxis: { title: 'Month' },
    vAxis: { 
      title: 'Response Rate (%)',
      minValue: 60,
      maxValue: 100
    },
    colors: ['#2196F3', '#9e9e9e'],
    legend: { position: 'bottom' },
    chartArea: { width: '80%', height: '70%' },
    backgroundColor: 'transparent',
    curveType: 'function'
  };

  const ageGroupData = [
    ['Age Group', 'Your Business', 'Competition'],
    ...comparativeAnalysisData.ageGroupComparison.map(data => [
      data.ageGroup,
      data.yours,
      data.competition,
    ]),
  ];

  const ageGroupOptions = {
    hAxis: { title: 'Age Group' },
    vAxis: { title: 'Review Count' },
    colors: ['#FF9800', '#9e9e9e'],
    legend: { position: 'bottom' },
    chartArea: { width: '80%', height: '70%' },
    backgroundColor: 'transparent'
  };

  const genderDistributionData = [
    ['Gender', 'Your Business', 'Competition'],
    ['Male', comparativeAnalysisData.genderDistributionComparison.yours.male, comparativeAnalysisData.genderDistributionComparison.competition.male],
    ['Female', comparativeAnalysisData.genderDistributionComparison.yours.female, comparativeAnalysisData.genderDistributionComparison.competition.female],
  ];

  const genderDistributionOptions = {
    hAxis: { title: 'Gender' },
    vAxis: { 
      title: 'Percentage (%)',
      minValue: 0,
      maxValue: 100
    },
    colors: ['#9C27B0', '#9e9e9e'],
    legend: { position: 'bottom' },
    chartArea: { width: '80%', height: '70%' },
    backgroundColor: 'transparent'
  };

  // Helper function to determine if your value is better
  const isBetterThan = (yours, competition, higherIsBetter = true) => {
    return higherIsBetter ? yours > competition : yours < competition;
  };

  // Helper function to get percentage difference
  const getDifference = (yours, competition) => {
    const diff = yours - competition;
    const percentage = Math.abs(diff) / competition * 100;
    return {
      value: diff,
      percentage: percentage.toFixed(1),
      isPositive: diff > 0
    };
  };

  if (!data) {
    // Show a loading spinner if data is not available
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
        <CircularProgress color="primary" />
      </Box>
    );
  }

  return (
    <Box>
      {/* First Row: Key Metrics Comparison with accent colors */}
      <Typography variant="h6" sx={{ mb: 2 }}>Key Performance Metrics Comparison</Typography>
      <Grid container spacing={2} sx={{ mb: 3 }} alignItems="stretch">
        {/* Star Rating Card */}
        <Grid item xs={12} sm={4}>
          <Card 
            variant="outlined" 
            sx={{ 
              height: '100%', 
              position: 'relative', 
              overflow: 'visible',
              border: 'none', 
              borderRadius: 2,
              bgcolor: 'white'
            }}
          >
            <Box sx={{ position: 'absolute', left: 0, top: 0, bottom: 0, width: '8px', backgroundColor: '#FFC107', borderTopLeftRadius: 8, borderBottomLeftRadius: 8 }} />
            <CardContent>
              <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                <Avatar sx={{ bgcolor: '#FFF8E1', color: '#FFC107', mr: 2 }}>
                  <StarIcon />
                </Avatar>
                <Typography variant="h6">Star Rating</Typography>
              </Box>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
                <Box>
                  <Typography variant="body2" color="textSecondary">Your Business</Typography>
                  <Typography variant="h4" color={isBetterThan(comparativeAnalysisData.starRatingComparison.yours, comparativeAnalysisData.starRatingComparison.competition) ? 'primary' : 'textPrimary'}>
                    {comparativeAnalysisData.starRatingComparison.yours}
                  </Typography>
                </Box>
                <Divider orientation="vertical" flexItem sx={{ mx: 2 }} />
                <Box>
                  <Typography variant="body2" color="textSecondary">Competition</Typography>
                  <Typography variant="h4" color="textSecondary">
                    {comparativeAnalysisData.starRatingComparison.competition}
                  </Typography>
                </Box>
              </Box>
              {isBetterThan(comparativeAnalysisData.starRatingComparison.yours, comparativeAnalysisData.starRatingComparison.competition) ? (
                <Typography variant="body2" color="success.main">
                  You're rated {getDifference(comparativeAnalysisData.starRatingComparison.yours, comparativeAnalysisData.starRatingComparison.competition).percentage}% higher
                </Typography>
              ) : (
                <Typography variant="body2" color="error.main">
                  Competition rated {getDifference(comparativeAnalysisData.starRatingComparison.competition, comparativeAnalysisData.starRatingComparison.yours).percentage}% higher
                </Typography>
              )}
            </CardContent>
          </Card>
        </Grid>
        
        {/* Review Count Card */}
        <Grid item xs={12} sm={4}>
          <Card 
            variant="outlined" 
            sx={{ 
              height: '100%', 
              position: 'relative', 
              overflow: 'visible',
              border: 'none', 
              borderRadius: 2,
              bgcolor: 'white'
            }}
          >
            <Box sx={{ position: 'absolute', left: 0, top: 0, bottom: 0, width: '8px', backgroundColor: '#3f51b5', borderTopLeftRadius: 8, borderBottomLeftRadius: 8 }} />
            <CardContent>
              <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                <Avatar sx={{ bgcolor: '#E8EAF6', color: '#3f51b5', mr: 2 }}>
                  <RateReviewIcon />
                </Avatar>
                <Typography variant="h6">Review Count</Typography>
              </Box>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
                <Box>
                  <Typography variant="body2" color="textSecondary">Your Business</Typography>
                  <Typography variant="h4" color={isBetterThan(comparativeAnalysisData.reviewCountComparison.yours, comparativeAnalysisData.reviewCountComparison.competition) ? 'primary' : 'textPrimary'}>
                    {comparativeAnalysisData.reviewCountComparison.yours}
                  </Typography>
                </Box>
                <Divider orientation="vertical" flexItem sx={{ mx: 2 }} />
                <Box>
                  <Typography variant="body2" color="textSecondary">Competition</Typography>
                  <Typography variant="h4" color="textSecondary">
                    {comparativeAnalysisData.reviewCountComparison.competition}
                  </Typography>
                </Box>
              </Box>
              {isBetterThan(comparativeAnalysisData.reviewCountComparison.yours, comparativeAnalysisData.reviewCountComparison.competition) ? (
                <Typography variant="body2" color="success.main">
                  You have {getDifference(comparativeAnalysisData.reviewCountComparison.yours, comparativeAnalysisData.reviewCountComparison.competition).percentage}% more reviews
                </Typography>
              ) : (
                <Typography variant="body2" color="error.main">
                  Competition has {getDifference(comparativeAnalysisData.reviewCountComparison.competition, comparativeAnalysisData.reviewCountComparison.yours).percentage}% more reviews
                </Typography>
              )}
            </CardContent>
          </Card>
        </Grid>
        
        {/* Response Rate Card */}
        <Grid item xs={12} sm={4}>
          <Card 
            variant="outlined" 
            sx={{ 
              height: '100%', 
              position: 'relative', 
              overflow: 'visible',
              border: 'none', 
              borderRadius: 2,
              bgcolor: 'white'
            }}
          >
            <Box sx={{ position: 'absolute', left: 0, top: 0, bottom: 0, width: '8px', backgroundColor: '#2196F3', borderTopLeftRadius: 8, borderBottomLeftRadius: 8 }} />
            <CardContent>
              <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                <Avatar sx={{ bgcolor: '#E3F2FD', color: '#2196F3', mr: 2 }}>
                  <AccessTimeIcon />
                </Avatar>
                <Typography variant="h6">Response Rate</Typography>
              </Box>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
                <Box>
                  <Typography variant="body2" color="textSecondary">Your Business</Typography>
                  <Typography variant="h4" color={isBetterThan(comparativeAnalysisData.responseRateComparison.yours, comparativeAnalysisData.responseRateComparison.competition) ? 'primary' : 'textPrimary'}>
                    {comparativeAnalysisData.responseRateComparison.yours}%
                  </Typography>
                </Box>
                <Divider orientation="vertical" flexItem sx={{ mx: 2 }} />
                <Box>
                  <Typography variant="body2" color="textSecondary">Competition</Typography>
                  <Typography variant="h4" color="textSecondary">
                    {comparativeAnalysisData.responseRateComparison.competition}%
                  </Typography>
                </Box>
              </Box>
              {isBetterThan(comparativeAnalysisData.responseRateComparison.yours, comparativeAnalysisData.responseRateComparison.competition) ? (
                <Typography variant="body2" color="success.main">
                  Your response rate is {getDifference(comparativeAnalysisData.responseRateComparison.yours, comparativeAnalysisData.responseRateComparison.competition).percentage}% higher
                </Typography>
              ) : (
                <Typography variant="body2" color="error.main">
                  Competition's response rate is {getDifference(comparativeAnalysisData.responseRateComparison.competition, comparativeAnalysisData.responseRateComparison.yours).percentage}% higher
                </Typography>
              )}
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      {/* Second Row: Charts */}
      <Typography variant="h6" sx={{ mb: 2, mt: 2 }}>Monthly Performance Trends</Typography>
      <Grid container spacing={2} sx={{ mb: 3 }} alignItems="stretch">
        {/* Monthly Review Count Chart */}
        <Grid item xs={12} sm={4}>
          <Card 
            variant="outlined" 
            sx={{ 
              height: '100%', 
              border: 'none', 
              borderRadius: 2,
              bgcolor: 'white'
            }}
          >
            <CardContent>
              <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                <RateReviewIcon sx={{ color: '#3f51b5', mr: 1 }} />
                <Typography variant="h6">Monthly Review Count</Typography>
              </Box>
              <Chart
                chartType="LineChart"
                width="100%"
                height="250px"
                data={reviewCountData}
                options={reviewCountOptions}
              />
            </CardContent>
          </Card>
        </Grid>

        {/* Monthly Sentiment Trend Chart */}
        <Grid item xs={12} sm={4}>
          <Card 
            variant="outlined" 
            sx={{ 
              height: '100%', 
              border: 'none', 
              borderRadius: 2,
              bgcolor: 'white'
            }}
          >
            <CardContent>
              <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                <SentimentVerySatisfiedIcon sx={{ color: '#4CAF50', mr: 1 }} />
                <Typography variant="h6">Sentiment Trend</Typography>
              </Box>
              <Chart
                chartType="LineChart"
                width="100%"
                height="250px"
                data={sentimentTrendData}
                options={sentimentTrendOptions}
              />
            </CardContent>
          </Card>
        </Grid>

        {/* Monthly Response Rate Chart */}
        <Grid item xs={12} sm={4}>
          <Card 
            variant="outlined" 
            sx={{ 
              height: '100%', 
              border: 'none', 
              borderRadius: 2,
              bgcolor: 'white'
            }}
          >
            <CardContent>
              <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                <AccessTimeIcon sx={{ color: '#2196F3', mr: 1 }} />
                <Typography variant="h6">Response Rate</Typography>
              </Box>
              <Chart
                chartType="LineChart"
                width="100%"
                height="250px"
                data={responseRateData}
                options={responseRateOptions}
              />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      
      {/* Third Row: Demographics Charts */}
      <Typography variant="h6" sx={{ mb: 2, mt: 2 }}>Demographic Comparisons</Typography>
      <Grid container spacing={2} alignItems="stretch">
        {/* Age Group Comparison Chart */}
        <Grid item xs={12} sm={6}>
          <Card 
            variant="outlined" 
            sx={{ 
              height: '100%', 
              border: 'none', 
              borderRadius: 2,
              bgcolor: 'white'
            }}
          >
            <CardContent>
              <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                <AccessibilityIcon sx={{ color: '#FF9800', mr: 1 }} />
                <Typography variant="h6">Age Group Comparison</Typography>
              </Box>
              <Chart
                chartType="ColumnChart"
                width="100%"
                height="250px"
                data={ageGroupData}
                options={ageGroupOptions}
              />
            </CardContent>
          </Card>
        </Grid>
        
        {/* Gender Distribution Comparison Chart */}
        <Grid item xs={12} sm={6}>
          <Card 
            variant="outlined" 
            sx={{ 
              height: '100%', 
              border: 'none', 
              borderRadius: 2,
              bgcolor: 'white'
            }}
          >
            <CardContent>
              <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                <CompareArrowsIcon sx={{ color: '#9C27B0', mr: 1 }} />
                <Typography variant="h6">Gender Distribution</Typography>
              </Box>
              <Chart
                chartType="ColumnChart"
                width="100%"
                height="250px"
                data={genderDistributionData}
                options={genderDistributionOptions}
              />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ComparativeAnalysis;