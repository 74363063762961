import React, { useState, useEffect } from 'react';
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { useAuthenticator } from '@aws-amplify/ui-react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { createSelector } from '@reduxjs/toolkit';
import BusinessQASection from './BusinessQASection';
import BusinessStatusBanner from './BusinessStatusBanner';
import BusinessStatusChips from './BusinessStatusChips';




import { 
  Box, 
  Typography, 
  Container, 
  Grid, 
  Paper, 
  Avatar,
  Chip,
  Button,
  Skeleton,
  Divider,
  IconButton,
  Rating,
  Badge,
  CircularProgress

} from '@mui/material';
import { 
    RateReview as RateReviewIcon, 
    Favorite as FavoriteIcon, 
    Share as ShareIcon, 
    Badge as EndorseIcon,
    ThumbUp as ThumbUpIcon,
    ThumbDown as ThumbDownIcon, 
    Comment as CommentIcon, 
    Visibility as FollowIcon,
    Edit as EditIcon,
    Verified as VerificationIcon,
    LinkOff as UnlinkIcon,
    BusinessCenter as ClaimIcon,
    Report as ReportIcon,
    ArrowForward as ArrowForwardIcon,
    FiberNew as FiberNewIcon,
    Cake as CakeIcon,
    LocationOn as LocationIcon,
    AccessTime as TimeIcon,
    AutoAwesome as ConceptIcon,
    Label as LabelIcon,
    BarChart


  } from '@mui/icons-material';

  import {useGetBusinessByIdQuery} from '../../redux/thunks/unauth/getbusinessbyid'
  import { usePrefetch as useUserTrustChainPrefetch } from '../../redux/thunks/users/usertrustchainsget';
  import { useGetUserEndorsementsQuery  } from '../../redux/thunks/users/userendorsements';
  import { useThingEndorseMutation } from '../../redux/thunks/things/thingendorse';
  import { useThingUnEndorseMutation } from '../../redux/thunks/things/thingunendorse';
  import { useGetUserFollowingQuery  } from '../../redux/thunks/users/userfollowings';
  import { useThingFollowMutation } from '../../redux/thunks/things/thingfollow';
  import { useThingUnFollowMutation } from '../../redux/thunks/things/thingunfollow';
  import ClaimBusiness from './ClaimBusiness';
  import ReportBusiness from './ReportBusiness';
  import { useSpReviewLikeMutation } from '../../redux/thunks/sp/spreviewlike';
  import { userApi} from '../../redux/thunks/users/user'


  import SkillStats from './SkillStats';



// Static Data Structures




  
  

// Skeleton Components
const BusinessOwnerSkeleton = () => (
    <Paper sx={{ p: 2, textAlign: 'center' }}>
      <Skeleton variant="circular" width={80} height={80} sx={{ mx: 'auto', mb: 2 }} />
      <Skeleton variant="text" width={120} sx={{ mx: 'auto' }} />
      <Skeleton variant="text" width={100} sx={{ mx: 'auto' }} />
      <Box sx={{ mt: 2 }}>
        {[1, 2, 3].map((item) => (
          <Skeleton key={item} variant="text" />
        ))}
      </Box>
    </Paper>
  );

const BusinessDetailsSkeleton = () => (
  <Box>
    <Skeleton variant="rectangular" height={80} sx={{ mb: 2 }} />
    <Skeleton variant="rectangular" height={250} sx={{ mb: 2 }} />
    <Skeleton variant="rectangular" height={180} sx={{ mb: 2 }} />
    <Skeleton variant="rectangular" height={160} />
  </Box>
);

const BusinessStatusSkeleton = () => (
  <Box>
    {[1, 2, 3, 4].map((item) => (
      <Skeleton key={item} variant="text" />
    ))}
  </Box>
);

const ReviewInteractionSection = ({ review }) => {
  const navigate = useNavigate();
  const { authStatus } = useAuthenticator(context => [context.authStatus]);
  const token = useSelector((state) => state.auth?.token || null);
  const reviewLiked = useSelector((state) => state.auth?.user?.reviewsLiked || [], shallowEqual);
  const reviewDisliked = useSelector((state) => state.auth?.user?.reviewsDisliked || [], shallowEqual);

  const comments = review.comments || [];


  const [thumbsUpPressed, setThumbsUpPressed] = useState(reviewLiked.includes(review._id));
  const [thumbsDownPressed, setThumbsDownPressed] = useState(reviewDisliked.includes(review._id));
  const [thisToken, setThisToken] = useState(null);


  const [likeReview, { isLoading: likeDislikeReviewLoading }] = useSpReviewLikeMutation();

  console.log('review', review)

  useEffect(() => {
    if (authStatus === 'authenticated') {
      setThisToken(token);
    }
  }, [authStatus, token]);


  const handleCommentOnReview = () => {
    navigate(`/review/comments/${review._id}`, { state: review });

  };

  const handleLikeAction = async (actionType) => {
    if(!thisToken) {
      // Use navigate to go to the feedback form page and pass props
      navigate('/auth/signin', { state: 'tbd' });

    }
    const payload = {
      auth: {
        token: thisToken,
        authType: 'token'
      },
      reviewId: review._id,
      action: actionType
    };

    if (actionType === 'like') {
      setThumbsUpPressed(true);
      setThumbsDownPressed(false);
    } else if (actionType === 'dislike') {
      setThumbsDownPressed(true);
      setThumbsUpPressed(false);
    } else if (actionType === 'unlike') {
      setThumbsUpPressed(false);
    } else if (actionType === 'undislike') {
      setThumbsDownPressed(false);
    }

    await likeReview({ payload });
  };

  const handleThumbsUpPress = () => {
    if(!thisToken) {
      // Use navigate to go to the feedback form page and pass props
      navigate('/auth/signin', { state: 'tbd' });

    }
    if (thumbsUpPressed) {
      handleLikeAction('unlike');
    } else {
      handleLikeAction('like');
      if (thumbsDownPressed) {
        handleLikeAction('undislike');
      }
    }
  };

  const handleThumbsDownPress = () => {
    if(!thisToken) {
      // Use navigate to go to the feedback form page and pass props
      navigate('/auth/signin', { state: 'tbd' });

    }
    if (thumbsDownPressed) {
      handleLikeAction('undislike');
    } else {
      handleLikeAction('dislike');
      if (thumbsUpPressed) {
        handleLikeAction('unlike');
      }
    }
  };

  return (
    <>
      
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
          <IconButton 
            color={thumbsUpPressed ? 'primary' : 'default'} aria-label="Thumb Up" onClick={handleThumbsUpPress}
          >
            <ThumbUpIcon />
            
          </IconButton>
          
          <IconButton 
            color={thumbsDownPressed ? 'error' : 'default'} aria-label="Thumb Down" onClick={handleThumbsDownPress}
          >
            <ThumbDownIcon />
          </IconButton>
          
          <IconButton
            onClick={handleCommentOnReview}
        
          >
            <Badge badgeContent={comments.length} color="error">
            <CommentIcon />
            </Badge>
           
          </IconButton>
        </Box>
      </Box>
      <Divider sx={{ my: 2 }} />
    </>
  );
};

const BusinessDetails = () => {

  const navigate = useNavigate();
  const dispatch = useDispatch();


  const { authStatus } = useAuthenticator(context => [context.authStatus]);
  const { businessId, title } = useParams();
  const token = useSelector((state) => state.auth?.token || null);
  const spId = useSelector((state) => state.auth?.user?.spId || null); 
  


  const [isLoading, setIsLoading] = useState(true);
  const [isBusinessOwner, setIsBusinessOwner] = useState(false);
  const [profileData, setProfileData] = useState('');
  const [thisToken, setThisToken] = useState(null);
  const [thisSpId, setThisSpId] = useState(null);
  const [isTrustedThing, setIsTrustedThing] = useState(false)
  const [isFollowed, setIsFollowed] = useState(false)
  const [isClaimDialogOpen, setIsClaimDialogOpen] = useState(false);
  const [isReportDialogOpen, setIsReportDialogOpen] = useState(false);
  const [businessDisplayStatus, setBusinessDisplayStatus] = useState('active');

  const [endorseButtonLoading, setEndorseButtonLoading] = useState(false);
  const [unendorseButtonLoading, setUnendorseButtonLoading] = useState(false);
  const [followButtonLoading, setFollowButtonLoading] = useState(false);
  const [unfollowButtonLoading, setUnfollowButtonLoading] = useState(false);

  

  const [reviews, setReviews] = useState([]);
  const [sortCriteria, setSortCriteria] = useState('date'); // 'date', 'rating', 'smartRating'


  const [userInteractions, setUserInteractions] = useState({
      isFollowing: false,
      hasEndorsed: false,
      reviewCount: 0
  });

  const prefetchUserTrustChain = useUserTrustChainPrefetch('getUserTrustchain');
  
 
  console.log('in business unauth businessId', businessId);  
  console.log('in business unauth title', title);  

  const args = {
    businessId: businessId,
    authType: 'none',
    token: token,
    title: title
  }

  const { data: profile, error: profileError, isLoading: profileLoading } = useGetBusinessByIdQuery(args);
  const { data: myTrustedThings, error: myTrustedThingsError, isLoading: myTrustedThingsLoading } = useGetUserEndorsementsQuery();
  const [triggerEndorse, { data: endorseResponse, isLoading: endorseIsLoading }] = useThingEndorseMutation();
  const [triggerUnEndorse, { data: unendorseResponse, isLoading: unendorseIsLoading }] = useThingUnEndorseMutation();

  const [triggerFollow, { data: followResponse, isLoading: followIsLoading }] = useThingFollowMutation();
  const [triggerUnFollow, { data: unFollowResponse, isLoading: unFollowIsLoading }] = useThingUnFollowMutation();
  const { data: followings, error: followingsError, isLoading: followingsLoading } = useGetUserFollowingQuery();

  console.log('profileData', profileData)
  console.log('profileLoading', profileLoading)
  console.log('myTrustedThingsLoading', myTrustedThingsLoading)
  console.log('endorseIsLoading', endorseIsLoading)
  console.log('unendorseIsLoading', unendorseIsLoading)
  console.log('followIsLoading', followIsLoading)
  console.log('unFollowIsLoading', unFollowIsLoading)
  console.log('followingsLoading', followingsLoading)
  console.log('profileError', profileError)
  console.log('isLoading', isLoading)
  console.log('businessDisplayStatus', businessDisplayStatus)


  

  useEffect(() => {
    if (authStatus === 'authenticated') {
      setThisToken(token);
      setThisSpId(spId);
    }
  }, [authStatus, token, spId]);

  useEffect(() => {
    console.log(' myTrustedThings', myTrustedThings)
    if (myTrustedThings && profile) {
      const isTrustedThing = myTrustedThings.myTrustedThings.some((followingItem) => {
        return (
          followingItem === profile._id
        );
      });
  
      setIsTrustedThing(isTrustedThing);         
    }
  }, [myTrustedThings, profile]);

  useEffect(() => {
    //console.log('in provider type in provider profile, useeffect followings', followings)
    if (followings && profile) {
      const isUserFollowed = followings.following.some((followingItem) => {
        return (
          followingItem === profile._id
        );
      });
  
      setIsFollowed(isUserFollowed);
      
         
    }
  }, [followings, profile]);

  useEffect(() => {
    setIsLoading(profileLoading || myTrustedThingsLoading || followingsLoading);
  
  }, [profileLoading, myTrustedThingsLoading, followingsLoading]);

  useEffect(() => {
    console.log('outside profile useEffect hook, profile', profile);
    console.log('thisToken', thisToken)
    console.log('thisSpId', thisSpId)


    if(profile && profile.businessDisplayStatus) {
      setBusinessDisplayStatus(profile.businessDisplayStatus)
    }



    if (!thisToken && profile && profile.spEmail === 'admin@revfer.com') {
      setProfileData(profile);
      setIsBusinessOwner(false);
    }
    if (!thisToken && profile && profile.spEmail !== 'admin@revfer.com') {
      setProfileData(profile);
      setIsBusinessOwner(false);
    }
    if (thisToken && profile && profile.spEmail === 'admin@revfer.com') {
      setProfileData(profile);
      setIsBusinessOwner(false);
    }
    if (thisToken && profile && profile.spEmail !== 'admin@revfer.com') {
      setProfileData(profile);
      if (thisSpId !== profile.spId) {
        prefetchUserTrustChain(profile.userId);
        setIsBusinessOwner(false);
        
      } else {
        console.log('test')
        setIsBusinessOwner(true);

      }
      
      
    }
        
  }, [ profile, thisToken ]);

  console.log('isBusinessOwner', isBusinessOwner)


  const sortReviews = (criteria) => {
    const sortedReviews = [...profileData.reviews].sort((a, b) => {
      switch(criteria) {
        case 'rating':
          return b.rating - a.rating;
        case 'smartRating':
          return b.SkillValue_all - a.SkillValue_all;
        case 'date':
        default:
          return new Date(b.date) - new Date(a.date);
      }
    });
    setReviews(sortedReviews);
    setSortCriteria(criteria);
  };

  // function for writing a review
  const handleWriteReview = () => {
    // TODO: Open review modal or navigate to review page
    console.log('Navigating to write review for', profileData.businessName);
    const feedbackProps = {
      skillName: profileData.skillName,
      skillPhoto: profileData.photoUrl,
      skillId: profileData.skillId,
      spId: profileData.spId,
      businessId: profileData._id,
      businessName: profileData.businessName,
      skill: profileData
    };

    // Use navigate to go to the feedback form page and pass props
    navigate('/feedback', { state: feedbackProps });
  };

  // Function for endorsing the business
  const handleEndorse = async () => {
    if(!thisToken) {
      navigate('/auth/signin', { state: 'tbd' });
      return;
    }
    
    // Set the button loading state
    setEndorseButtonLoading(true);
    
    // Handle Endorse action
    const auth = {
      token: token,
      authType: 'token',
    };
    
    const payload = {
      auth,     
      businessId: profileData._id,
    };  
        
    try {
      await triggerEndorse({ payload });
      // Success - update the endorsed state
      setIsTrustedThing(true);
      
      // Invalidate the cache manually
      dispatch(userApi.util.invalidateTags(['Endorsements']));
    } catch (error) {
      console.error('Error endorsing business:', error);
    } finally {
      // Always turn off loading state when done
      setEndorseButtonLoading(false);
    }
  };
  

  const handleUnEndorsement = async () => {
    if(!thisToken) {
      navigate('/auth/signin', { state: 'tbd' });
      return;
    }
    
    // Set the button loading state
    setUnendorseButtonLoading(true);
    
    // Handle UnEndorse action
    const auth = {
      token: token,
      authType: 'token',
    };
    
    const payload = {
      auth,     
      businessId: profileData._id,
    };  
    
    try {    
      await triggerUnEndorse({ payload });
      // Success - update the endorsed state
      setIsTrustedThing(false);
      
      // Invalidate the cache manually
      dispatch(userApi.util.invalidateTags(['Endorsements']));
    } catch (error) {
      console.error('Error unendorsing business:', error);
    } finally {
      // Always turn off loading state when done
      setUnendorseButtonLoading(false);
    }
  };

  
  const handleFollow = async () => {
    if(!thisToken) {
      navigate('/auth/signin', { state: 'tbd' });
      return;
    }
    
    // Set the button loading state
    setFollowButtonLoading(true);
    
    // Handle follow action
    const auth = {
      token: token,
      authType: 'token',
    };
    let sp = {spId: profileData.spId, skillId: profileData.skillId}    
    const payload = {
      auth,
      sp,
      businessName: profileData.businessName,
      businessId: profileData._id
    };  
    
    try {  
      await triggerFollow({ payload });
      // Success - update the follow state
      setIsFollowed(true);
      
      // Invalidate the cache manually
      dispatch(userApi.util.invalidateTags(['Follow']));
    } catch (error) {
      console.error('Error following business:', error);
    } finally {
      // Always turn off loading state when done
      setFollowButtonLoading(false);
    }
  };

  const handleUnFollow = async () => {
    if(!thisToken) {
      navigate('/auth/signin', { state: 'tbd' });
      return;
    }
    
    // Set the button loading state
    setUnfollowButtonLoading(true);
    
    // Handle unfollow action
    const auth = {
      token: token,
      authType: 'token',
    };
    let sp = {spId: profileData.spId, skillId: profileData.skillId}    
    const payload = {
      auth,
      sp,
      businessName: profileData.businessName,
      businessId: profileData._id
    };  
    
    try {  
      await triggerUnFollow({ payload });
      // Success - update the follow state
      setIsFollowed(false);
      
      // Invalidate the cache manually
      dispatch(userApi.util.invalidateTags(['Follow']));
    } catch (error) {
      console.error('Error unfollowing business:', error);
    } finally {
      // Always turn off loading state when done
      setUnfollowButtonLoading(false);
    }
  };

  // Business Owner Actions
  const handleEditBusinessDetails = () => {
      console.log('Navigating to business details edit');
      navigate(`/business/${profileData._id}/edit`, { state: profileData })
  };
  
  const handleStartVerification = () => {
    console.log('Initiating business verification process');
    // TODO: Start verification workflow
  };
  
  const handleUnclaim = () => {
    console.log('Unclaiming business');
    // TODO: Implement business unclaim logic
  };
  
  
  const handleOpenClaimDialog = () => {
    console.log('claim clicked')
    setIsClaimDialogOpen(true);
  };
  const handleCloseClaimDialog = () => {
    setIsClaimDialogOpen(false);
  };

  const handleOpenReportDialog = () => {
    console.log('report clicked')
    setIsReportDialogOpen(true);
  };

  const handleCloseReportDialog = () => {
    setIsReportDialogOpen(false);
  };
 
  
  const handleShareBusiness = () => {
    console.log('Sharing business profile');
    // TODO: Implement business sharing functionality
  }; 

  const calculateReferalStats = () => {
    console.log('Sharing business profile');
    // TODO: Implement business sharing functionality
  }


  const UserLogo = ({photoUrl, userName}) => {
    
    if (photoUrl) {
      return (
        <Avatar
          
          src={photoUrl}
          alt={`${userName} logo`}
          sx={{ 
            width: 80, 
            height: 80,
            mx: 'auto', 
            mb: 2,
          }}
        />
      );
    }
    
    return (
      <Avatar 
        sx={{ 
          width: 80, 
          height: 80, 
          mx: 'auto', 
          mb: 2,
          bgcolor: 'grey.300'
        }}
      >
        {userName.split(' ').map(n => n[0]).join('')}
      </Avatar>
    );
  };

  

  
  // Business Logo Component
  const BusinessLogo = ({photoUrl, businessName}) => {
    
    if (photoUrl) {
      return (
        <Avatar
          variant="rounded"
          src={photoUrl}
          alt={`${businessName} logo`}
          sx={{ 
            width: 48, 
            height: 48
          }}
        />
      );
    }
    
    return (
      <Avatar
        variant="rounded"
        sx={{ 
          width: 48, 
          height: 48,
          bgcolor: 'primary.light',
          color: 'primary.contrastText',
          fontSize: 16
        }}
      >
        {businessName[0].toUpperCase()}
      </Avatar>
    );
  };

  if (isLoading) {
    return (
      <Container maxWidth="xl" sx={{ py: 3 }}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={3}>
            <BusinessOwnerSkeleton />
          </Grid>
          <Grid item xs={12} md={6}>
            <BusinessDetailsSkeleton />
          </Grid>
          <Grid item xs={12} md={3}>
            <BusinessStatusSkeleton />
          </Grid>
        </Grid>
      </Container>
    );
  }
 

  return (
    <Container maxWidth="xl" sx={{ py: 3 }}>
      <Grid container spacing={3}>
        {/* Left Column - Business Owner */}
        <Grid item xs={12} md={3}>
          <Paper 
            variant="outlined" 
            sx={{ p: 2, textAlign: 'center', mb: 2, border: 'none', cursor: profileData.spEmail !== 'admin@revfer.com' ? 'pointer' : 'default' }}
            onClick={() => {
              if (profileData.spEmail !== 'admin@revfer.com') {
                navigate(`/profile/${profileData.spUserId}/new`);
              }
            }}
          >
            <UserLogo photoUrl={profileData.spPhoto} userName={profileData.spDisplayName || 'Revfer Systems'}/>
            <Typography variant="h6">{profileData.spDisplayName}</Typography>
            <Typography variant="subtitle2" color="text.secondary">
              {profileData.spRole}
            </Typography>
            <Box sx={{ mt: 2, textAlign: 'left', px: 2 }}>
            {profileData.created && (
                <Typography variant="body2" color="text.secondary" sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  <FiberNewIcon fontSize="small" sx={{ color: 'info.light' }} /> 
                  Joined {new Date(profileData.created).toLocaleDateString('en-US', { month: 'long', year: 'numeric' })}
                </Typography>
            )}
            {profileData.spTag && (
                <Typography variant="body2" color="text.secondary" sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  <CakeIcon fontSize="small" sx={{ color: 'info.light' }} /> 
                 profileData.spTag
                </Typography>
            )}
             
            </Box>
          </Paper>

          <Paper variant="outlined" sx={{ p: 2, mb: 2, border: 'none' }}>
            <Typography variant="h6" sx={{ mb: 2 }}>
              Similar Businesses
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Will appear as business category is confirmed
            </Typography>
          </Paper>

          {!isBusinessOwner && (
            <Paper variant="outlined" sx={{ p: 2, mb: 2, border: 'none' }}>
              <Typography variant="h6" sx={{ mb: 2 }}>Business Interactions</Typography>
              
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Button 
                    fullWidth 
                    variant="contained"
                    color="primary" 
                    startIcon={<RateReviewIcon />}
                    onClick={handleWriteReview}
                    sx={{ 
                      height: '100%',
                      display: 'flex', 
                      flexDirection: 'column', 
                      padding: 1.5,
                      borderRadius: 2,
                      '& .MuiButton-startIcon': {
                        margin: 0,
                        marginBottom: 1
                      }
                    }}
                  >
                    <Typography variant="body2">Write Review</Typography>
                  </Button>
                </Grid>
                
                {!isTrustedThing ? (
                  <Grid item xs={6}>
                    <Button 
                      fullWidth 
                      variant="contained"
                      color="secondary"
                      startIcon={endorseButtonLoading ? (
                        <CircularProgress size={16} color="inherit" />
                      ) : (
                        <EndorseIcon />
                      )}
                      onClick={handleEndorse}
                      disabled={endorseButtonLoading}
                      sx={{ 
                        height: '100%',
                        display: 'flex', 
                        flexDirection: 'column', 
                        padding: 1.5,
                        borderRadius: 2,
                        '& .MuiButton-startIcon': {
                          margin: 0,
                          marginBottom: 1
                        }
                      }}
                    >
                      <Typography variant="body2">
                        {endorseButtonLoading ? 'Endorsing...' : 'Endorse'}
                      </Typography>
                    </Button>
                  </Grid>
                ) : (
                  <Grid item xs={6}>
                    <Button 
                      fullWidth 
                      variant="outlined"
                      color="secondary"
                      startIcon={unendorseButtonLoading ? (
                        <CircularProgress size={16} color="inherit" />
                      ) : (
                        <EndorseIcon />
                      )}
                      onClick={handleUnEndorsement}
                      disabled={unendorseButtonLoading}
                      sx={{ 
                        height: '100%',
                        display: 'flex', 
                        flexDirection: 'column', 
                        padding: 1.5,
                        borderRadius: 2,
                        '& .MuiButton-startIcon': {
                          margin: 0,
                          marginBottom: 1
                        }
                      }}
                    >
                      <Typography variant="body2">
                        {unendorseButtonLoading ? 'Removing...' : 'Unendorse'}
                      </Typography>
                    </Button>
                  </Grid>
                )}

                
                {!isFollowed ? (
                  <Grid item xs={12}>
                    <Button 
                      fullWidth 
                      variant="contained"
                      color="info"
                      startIcon={followButtonLoading ? (
                        <CircularProgress size={16} color="inherit" />
                      ) : (
                        <FollowIcon />
                      )}
                      onClick={handleFollow}
                      disabled={followButtonLoading}
                      sx={{ 
                        display: 'flex', 
                        padding: 1.5,
                        borderRadius: 2,
                      }}
                    >
                      <Typography variant="body2">
                        {followButtonLoading ? 'Following...' : 'Follow Business'}
                      </Typography>
                    </Button>
                  </Grid>
                ) : (
                  <Grid item xs={12}>
                    <Button 
                      fullWidth 
                      variant="outlined"
                      color="info"
                      startIcon={unfollowButtonLoading ? (
                        <CircularProgress size={16} color="inherit" />
                      ) : (
                        <FollowIcon />
                      )}
                      onClick={handleUnFollow}
                      disabled={unfollowButtonLoading}
                      sx={{ 
                        display: 'flex', 
                        padding: 1.5,
                        borderRadius: 2,
                      }}
                    >
                      <Typography variant="body2">
                        {unfollowButtonLoading ? 'Unfollowing...' : 'Unfollow Business'}
                      </Typography>
                    </Button>
                  </Grid>
                )}
              </Grid>
            </Paper>
          )}

          

          <Paper variant="outlined" sx={{ p: 2, border: 'none' }}>
              {/* Business Owner View */}
              {isBusinessOwner && (
                <>
                  <Typography variant="h6" sx={{ mb: 2 }}>Business Owner Actions</Typography>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <Button
                        fullWidth
                        variant="contained"
                        color="primary"
                        startIcon={<EditIcon />}
                        onClick={handleEditBusinessDetails}
                        sx={{ 
                          height: '100%',
                          display: 'flex', 
                          flexDirection: 'column', 
                          padding: 2,
                          borderRadius: 2,
                          '& .MuiButton-startIcon': {
                            margin: 0,
                            marginBottom: 1
                          }
                        }}
                      >
                        <Typography variant="body2">Edit Business</Typography>
                      </Button>
                    </Grid>
                    <Grid item xs={6}>
                      <Button
                        fullWidth
                        variant="contained"
                        color="secondary"
                        startIcon={<BarChart />}
                        onClick={() => navigate(`/business/admin/${profileData._id}`)}
                        sx={{ 
                          height: '100%',
                          display: 'flex', 
                          flexDirection: 'column',
                          padding: 2,
                          borderRadius: 2,
                          '& .MuiButton-startIcon': {
                            margin: 0,
                            marginBottom: 1
                          }
                        }}
                      >
                        <Typography variant="body2">View Stats</Typography>
                      </Button>
                    </Grid>
                    <Grid item xs={6}>
                      <Button
                        fullWidth
                        variant="contained"
                        color="info"
                        startIcon={<VerificationIcon />}
                        onClick={handleStartVerification}
                        sx={{ 
                          height: '100%',
                          display: 'flex', 
                          flexDirection: 'column',
                          padding: 2,
                          borderRadius: 2,
                          '& .MuiButton-startIcon': {
                            margin: 0,
                            marginBottom: 1
                          }
                        }}
                      >
                        <Typography variant="body2">Verify Business</Typography>
                      </Button>
                    </Grid>
                    <Grid item xs={6}>
                      <Button
                        fullWidth
                        variant="outlined"
                        color="error"
                        startIcon={<UnlinkIcon />}
                        onClick={handleUnclaim}
                        sx={{ 
                          height: '100%',
                          display: 'flex', 
                          flexDirection: 'column',
                          padding: 2,
                          borderRadius: 2,
                          '& .MuiButton-startIcon': {
                            margin: 0,
                            marginBottom: 1
                          }
                        }}
                      >
                        <Typography variant="body2">Unclaim</Typography>
                      </Button>
                    </Grid>
                  </Grid>
                </>
              )}

            {/* Non-Business Owner View */}
            {!isBusinessOwner && (
              <Paper variant="outlined" sx={{ p: 2, border: 'none' }}>
                <Typography variant="h6" sx={{ mb: 2 }}>Business Actions</Typography>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Button
                      fullWidth
                      variant="contained"
                      color="warning"
                      startIcon={<ClaimIcon />}
                      onClick={handleOpenClaimDialog}
                      sx={{ 
                        height: '100%',
                        display: 'flex', 
                        flexDirection: 'column', 
                        padding: 1.5,
                        borderRadius: 2,
                        '& .MuiButton-startIcon': {
                          margin: 0,
                          marginBottom: 1
                        }
                      }}
                    >
                      <Typography variant="body2">Claim Business</Typography>
                    </Button>
                  </Grid>
                  
                  <Grid item xs={6}>
                    <Button
                      fullWidth
                      variant="contained"
                      color="error"
                      startIcon={<ReportIcon />}
                      onClick={handleOpenReportDialog}
                      sx={{ 
                        height: '100%',
                        display: 'flex', 
                        flexDirection: 'column', 
                        padding: 1.5,
                        borderRadius: 2,
                        '& .MuiButton-startIcon': {
                          margin: 0,
                          marginBottom: 1
                        }
                      }}
                    >
                      <Typography variant="body2">Report</Typography>
                    </Button>
                  </Grid>
                  
                  <Grid item xs={12}>
                    <Button
                      fullWidth
                      variant="contained"
                      color="success"
                      startIcon={<ShareIcon />}
                      onClick={handleShareBusiness}
                      sx={{ 
                        display: 'flex', 
                        padding: 1.5,
                        borderRadius: 2,
                      }}
                    >
                      <Typography variant="body2">Share Business</Typography>
                    </Button>
                  </Grid>
                </Grid>
                
                {/* ClaimBusiness Dialog */}
                <ClaimBusiness
                  isOpen={isClaimDialogOpen}
                  onClose={handleCloseClaimDialog}
                  businessId={profileData._id}
                />
                
                {/* ReportBusiness Dialog */}
                <ReportBusiness 
                  isOpen={isReportDialogOpen} 
                  onClose={handleCloseReportDialog} 
                  businessId={profileData._id} 
                />
              </Paper>
            )}
          </Paper>
        </Grid>

        {/* Middle Column - Business Details */}
        <Grid item xs={12} md={6}>
          <BusinessStatusBanner tags={profileData.tags} />

          <Paper variant="outlined" sx={{ p: 2, mb: 2, border: 'none' }}>
          <Box 
              sx={{ 
                display: 'flex', 
                alignItems: 'center', 
                gap: 2, 
                mb: 2 
              }}
            >
              <BusinessLogo photoUrl={profileData.photoUrl} businessName={profileData.businessName}/>
              <Box>
                <Typography variant="h4">{profileData.businessName}</Typography>
                <Typography variant="subtitle1" color="text.secondary">
                  {Array.isArray(profileData.category)
                    ? profileData.category.slice(0, 3).map((cat, index) => (
                        <React.Fragment key={index}>
                          {cat}
                          {index < 2 && (
                            <span style={{ fontSize: '1.2em', fontWeight: 'bold' }}> · </span>
                          )}
                        </React.Fragment>
                      ))
                    : profileData.category}
                </Typography>
              </Box>
            </Box>

            {/* Ratings Section */}
            <Box sx={{ display: 'flex', gap: 2, my: 2 }}>
              <Box>
                <Typography variant="body2">Traditional Rating</Typography>
                <Typography variant="h6">
                {`${Math.floor(profileData.avgSkillRating_all*10)/10}`} ★
                </Typography>
              </Box>
              <Box>
                <Typography variant="body2">Smart Rating</Typography>
                <Typography variant="h6">
                  {Math.round(profileData.SkillValue_all*10)/10} ★
                </Typography>
              </Box>
              <Box>
                <Typography variant="body2">Reference %</Typography>
                <Typography variant="h6">
                {profileData.reviews && profileData.reviews.length > 0 ? (
                `${Math.round((profileData.reviews.filter(review => review.connectionLength > 0).length / profileData.reviews.length) * 100)}%`
                 ) : '0%'
                }
                </Typography>
              </Box>
            </Box>

            <Box sx={{ display: 'flex', gap: 1, my: 2 }}>
              <BusinessStatusChips tags={profileData.tags} maxChips={3} />
            </Box>

            <Box sx={{ mt: 2 }}>
              {profileData.region && (
                <Typography 
                variant="body2" 
                color="text.secondary" 
                sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 1 }}
              >
                <LocationIcon fontSize="small" color="action" />
                {profileData.region}
              </Typography>
              )}

              {profileData.businessHours && (
                <Typography 
                variant="body2" 
                color="text.secondary" 
                sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 1 }}
              >
                <TimeIcon fontSize="small" color="action" />
                {profileData.businessHours}
              </Typography>
              )}
              {profileData.businessConcept && (
                <Typography 
                variant="body2" 
                color="text.secondary" 
                sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 1 }}
              >
                <ConceptIcon fontSize="small" color="action" />
                {profileData.businessConcept}
              </Typography>
              )}
            
              
              
            </Box>
          </Paper>
          <BusinessQASection isLoading={isLoading} businessId={businessId} thisToken={thisToken}/>
          

           {/* Reviews Section */}
           <Paper variant="outlined" sx={{ p: 2, border: 'none' }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
              <Typography variant="h6">Customer Reviews</Typography>
              <Box>
                <Button 
                  variant={sortCriteria === 'date' ? 'contained' : 'outlined'}
                  size="small"
                  sx={{ mr: 1 }}
                  onClick={() => sortReviews('date')}
                >
                  Recent
                </Button>
                <Button 
                  variant={sortCriteria === 'rating' ? 'contained' : 'outlined'}
                  size="small"
                  sx={{ mr: 1 }}
                  onClick={() => sortReviews('rating')}
                >
                  Highest Rated
                </Button>
                <Button 
                  variant={sortCriteria === 'smartRating' ? 'contained' : 'outlined'}
                  size="small"
                  onClick={() => sortReviews('smartRating')}
                >
                  Smart Rating
                </Button>
              </Box>
            </Box>

            {profileData.reviews.map((review) => (
              <Paper 
                key={review.id} 
                variant="outlined" 
                sx={{ p: 2, mb: 2, border: 'none' }}
              >
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <Box>
                    <Typography variant="subtitle1">{review.userDetails.displayName}</Typography>
                    <Typography variant="body2" color="text.secondary">
                      {new Date(review.created).toLocaleDateString()}
                    </Typography>
                  </Box>
                  <Box display="flex" alignItems="center">
                    <Rating 
                      value={Math.round(review.rating * 10) / 10} 
                      precision={0.1} 
                      readOnly 
                      size="small" 
                    />
                    <Typography variant="body2" sx={{ ml: 1 }}>
                      {Math.round(review.rating * 10) / 10}
                    </Typography>
                  </Box>
                </Box>
                <Typography variant="body2" sx={{ mt: 1 }}>
                  {review.review}
                </Typography>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 1 }}>
                  <Typography variant="caption" color="text.secondary">
                    {review.likes.length} Likes
                  </Typography>
                  {review.verified && (
                    <Chip 
                      label="Verified" 
                      color="success" 
                      size="small" 
                      variant="outlined" 
                    />
                  )}
                </Box>
                <ReviewInteractionSection 
                  review={review}
                  
                />
              </Paper>
            ))}
            <Box sx={{ display: 'flex', justifyContent: 'center', mt: 3 }}>
              <Button
                variant="outlined"
                color="primary"
                onClick={() => navigate(`/business/${profileData._id}/reviews`)}
                endIcon={<ArrowForwardIcon />}
              >
                Show All Reviews
              </Button>
            </Box>
          </Paper>



          

        </Grid>

        {/* Right Column - Business Status */}
        <Grid item xs={12} md={3}>
        <Paper variant="outlined" sx={{ p: 2, mb: 2, border: 'none' }}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 2 }}>
            <LabelIcon fontSize="small" color="action" />
            <Typography variant="subtitle2" color="text.secondary">
              Sponsored
            </Typography>
          </Box>
          
          {/* AdSense Placeholder */}
          <Box 
            sx={{ 
              width: '100%',
              height: '250px',
              bgcolor: 'grey.100',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              borderRadius: 1,
              border: '1px dashed grey.300'
            }}
          >
            <Typography variant="body2" color="text.secondary">
              Advertisement
            </Typography>
          </Box>
        </Paper>

          

          <Paper variant="outlined" sx={{ p: 2, border: 'none', mb: 1, borderRadius: 2 }}
            >
              <SkillStats skill={profileData} spId={profileData.spId} />
            </Paper>
        </Grid>
      </Grid>
    </Container>
  );
};

export default BusinessDetails;