import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'; // For navigation
import { useSelector } from 'react-redux'; // For auth state
import { usePrefetch } from '../../redux/thunks/users/userprofile';



import {
  Container,
  Grid,
  Paper,
  Typography,
  Box,
  TextField,
  Button,
  Avatar,
  Chip,
  Skeleton,
  IconButton,
  InputAdornment,
  Divider,
  Card,
  CardContent,
  Popover,
  MenuList,
  MenuItem,
  CircularProgress
} from '@mui/material';
import {
  Search as SearchIcon,
  FilterList as FilterIcon,
  ImportContacts as ImportIcon,
  Share as ShareIcon,
  Email as EmailIcon,
  Person as PersonIcon,
} from '@mui/icons-material';
import { useFindUserMutation } from '../../redux/thunks/users/userfind';
import { useEmailInviteMutation } from '../../redux/thunks/users/useremailinvite'; // Import your RTK Query mutation
import { useAddTrustedUserMutation  } from '../../redux/thunks/users/useraddtrusteduser';
import { useGetUserIncomingReqQuery } from '../../redux/thunks/users/userincomingrequests';
import { useGetUserOutgoingReqQuery } from '../../redux/thunks/users/useroutgoingrequests';
import { useGetNetworkStatsQuery } from '../../redux/thunks/users/getnetworkstats';
import { useGetUserSuggestionsQuery } from '../../redux/thunks/users/getusersuggestions';
import { useGetConnectedBusinessesQuery } from '../../redux/thunks/users/getconnectedbusiness';







const TRUST_SCORE_RANGES = [
  { label: 'All Scores', value: 'all' },
  { label: '90+ (Excellent)', value: '90' },
  { label: '80-89 (Very Good)', value: '80' },
  { label: '70-79 (Good)', value: '70' }
];

const GROWTH_TIPS = [
  'Personalize your invitations',
  'Connect with mutual contacts',
  'Build your trust score',
  'Stay active in your network'
];


const RECOMMENDED_USERS = [
  {
    id: 1,
    name: 'David Wilson',
    role: 'Marketing Director',
    avatar: '/placeholder.jpg',
    mutualConnections: 5
  },
  {
    id: 2,
    name: 'Emma Thompson',
    role: 'UX Designer',
    avatar: '/placeholder.jpg',
    mutualConnections: 3
  }
];

// Skeleton Components
const StatCardSkeleton = () => (
  <Paper variant='outlined' sx={{ p: 3, mb: 2, border: 'none' }}>
    <Skeleton variant="text" width="60%" height={32} sx={{ mb: 2 }} />
    <Grid container spacing={2}>
      {[1, 2, 3, 4].map((item) => (
        <Grid item xs={6} key={item}>
          <Skeleton variant="text" width="60%" height={40} />
          <Skeleton variant="text" width="80%" height={24} />
        </Grid>
      ))}
    </Grid>
  </Paper>
);

const SearchResultSkeleton = () => (
  <Paper variant='outlined' sx={{ p: 2, mb: 2, border: 'none' }}>
    <Box sx={{ display: 'flex', gap: 2 }}>
      <Skeleton variant="circular" width={50} height={50} />
      <Box sx={{ flex: 1 }}>
        <Skeleton variant="text" width="60%" />
        <Skeleton variant="text" width="40%" />
        <Skeleton variant="text" width="30%" />
      </Box>
      <Skeleton variant="rectangular" width={100} height={36} sx={{ borderRadius: 18 }} />
    </Box>
  </Paper>
);

// Main Components
const NetworkStatsCard = ({ stats }) => (
  <Paper variant='outlined' sx={{ p: 3, mb: 2, border: 'none' }}>
    <Typography variant="h6" gutterBottom>Network Statistics</Typography>
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <Typography variant="h4" color="primary">{stats.networkFollows || 0 }</Typography>
        <Typography variant="body2" color="text.secondary">Follows</Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography variant="h4" color="primary">{stats.networkReferred || 0}</Typography>
        <Typography variant="body2" color="text.secondary">Referred</Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography variant="h4" color="primary">{stats.networkReviews || 0}</Typography>
        <Typography variant="body2" color="text.secondary">Reviews</Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography variant="h4" color="primary">{stats.networkViews || 0}</Typography>
        <Typography variant="body2" color="text.secondary">Pages Viewed</Typography>
      </Grid>
    </Grid>
  </Paper>
);

const QuickActionsCard = () => (
  <Paper variant='outlined' sx={{ p: 3, mb: 2, border: 'none' }}>
    <Typography variant="h6" gutterBottom>Quick Actions</Typography>
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
      <Button variant="contained" startIcon={<ImportIcon />}>
        Import Email Contacts
      </Button>
      <Button variant="outlined" startIcon={<ShareIcon />}>
        Share Profile Link
      </Button>
    </Box>
  </Paper>
);

const GrowthTipsCard = ({ tips }) => (
  <Paper variant='outlined' sx={{ p: 3, border: 'none' }}>
    <Typography variant="h6" gutterBottom>Network Growth Tips</Typography>
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
      {tips.map((tip, index) => (
        <Typography key={index} variant="body2" sx={{ display: 'flex', alignItems: 'center' }}>
          • {tip}
        </Typography>
      ))}
    </Box>
  </Paper>
);

const SearchResultCard = ({ user, onConnect, thisUser }) => {
  const [isPending, setIsPending] = useState(false);
  const [showRequestSent, setShowRequestSent] = useState(false);
  
  console.log('user', user)

  const firstDegreeFriends = thisUser.firstDegreeFriends
  console.log('firstDegreeFriends', firstDegreeFriends)

  const prefetchUserProfiles = usePrefetch('getUserProfile')
  const { data: incoming, isLoading: incomingLoading, isSuccess: incomingSuccess  } = useGetUserIncomingReqQuery(thisUser._id);
  const { data: outgoing, isLoading: outgoingLoading, isSuccess: outgoingSuccess  } = useGetUserOutgoingReqQuery(thisUser._id);
  

  const isConnected =  firstDegreeFriends.length > 0 &&  firstDegreeFriends.some((friend) => friend.userId.toString() === user._id.toString());
    
  const isMe = thisUser._id === user._id

  const isLoading = incomingLoading || outgoingLoading

  // Handle connect action with local state update
  const handleConnect = async (userId) => {
    setShowRequestSent(true);
    setTimeout(() => {
      setShowRequestSent(false);
      setIsPending(true);
    }, 2000); // Show the message for 2 seconds before changing to Pending
    
    onConnect(userId);
  };

  useEffect(() => {
    console.log('incoming', incoming)
    console.log('outgoing', outgoing)


    if (incoming && outgoing) {
      // Combine two arrays and create a new array with only unique values.
      const incomingIds = incoming.pendingInTrustedUsers.map(item => item.trustedUserId);
      const outgoingIds = outgoing.pendingOutTrustedUsers.map(item => item.trustedUserId);
      const isPendingIn =  incoming.pendingInTrustedUsers.length > 0 &&  incoming.pendingInTrustedUsers.some((friend) => friend.trustedUserId.toString() === user._id.toString());
      const isPendingOut =  outgoing.pendingOutTrustedUsers.length > 0 &&  outgoing.pendingOutTrustedUsers.some((friend) => friend.trustedUserId.toString() === user._id.toString());
      console.log('outgoing', outgoing)
      console.log('isPendingOut', isPendingOut)
      console.log('isPendingIn', isPendingIn)
      setIsPending(isPendingIn || isPendingOut)
      // Create a new array with only unique ids.
      const profileIds = [...new Set([...incomingIds, ...outgoingIds])];
      //profileIds.forEach(id => prefetch(id));
    }
    }, [incoming, outgoing, user]);
    
  console.log('isPending', isPending)

  return (
    <Paper variant='outlined' sx={{ p: 2, mb: 2, border: 'none' }}>
      <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
        <Avatar src={user.profileImageUrl} sx={{ width: 50, height: 50 }} />
        <Box sx={{ flex: 1 }}>
          <Typography variant="subtitle1">{user.displayName}</Typography>
          {user.company && (
            <Typography variant="body2" color="text.secondary">
            {user.role} at {user.company}
            </Typography>

          )}
          
         
          
          {/* Request sent message */}
          {showRequestSent && (
            <Typography variant="body2" color="success.main" sx={{ mt: 1, fontWeight: 'bold' }}>
              Connection request sent!
            </Typography>
          )}
        </Box>
        
        {/* Connect button - show if not connected, not self, not pending */}
        {!isConnected && !isMe && !isPending && !showRequestSent &&
          <Button 
            disabled={isLoading} 
            variant="outlined" 
            sx={{ borderRadius: '999px', border: '1px solid', padding: '8px 16px' }}  
            onClick={() => handleConnect(user._id)}
          >
            {isLoading ? 'Connecting...' : 'Connect'}
          </Button>      
        }
        
        {/* Message button - show if connected */}
        {isConnected &&
          <Button 
            variant="outlined" 
            sx={{ borderRadius: '999px', border: '1px solid', padding: '8px 16px' }}
          >
            Message
          </Button>      
        }
        
        {/* Show 'You' for self */}
        {isMe &&
          <Button 
            variant="outlined" 
            sx={{ borderRadius: '999px', border: '1px solid', padding: '8px 16px' }}
          >
            You
          </Button>      
        }
        
        {/* Show 'Pending' for pending connections */}
        {(isPending || showRequestSent) &&
          <Button 
            variant="outlined" 
            sx={{ 
              borderRadius: '999px', 
              border: '1px solid', 
              padding: '8px 16px',
              bgcolor: showRequestSent ? 'success.light' : 'inherit',
              color: showRequestSent ? 'white' : 'inherit'
            }}
          >
            {showRequestSent ? 'Sent!' : 'Pending'}
          </Button>      
        }
      </Box>
    </Paper>
  );
};

const EmailInviteCard = () => {

  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState(null);
  const [inviteUser, { isLoading }] = useEmailInviteMutation();

  const handleSubmit = async () => {
    try {
      setError(null); // Reset any previous errors
      const payload = {
        emails: [email],
        message,
      };
      const response = await inviteUser(payload).unwrap();
      console.log('Invitation sent successfully:', response);
      setEmail('');
      setMessage('');
    } catch (err) {
      console.error('Error sending invitation:', err);
      setError(err?.data?.message || 'An unexpected error occurred.'); // Update error state with API error
    }
  };

  
  
  return (
    <Paper variant="outlined" sx={{ p: 3, mb: 2, border: 'none' }}>
      <Typography variant="h6" gutterBottom>
        Invite via Email
      </Typography>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        <TextField
          fullWidth
          placeholder="Enter email address"
          size="small"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <EmailIcon color="action" />
              </InputAdornment>
            ),
          }}
        />
        <TextField
          fullWidth
          multiline
          rows={4}
          placeholder="Add a personal message..."
          value={message}
          onChange={(e) => setMessage(e.target.value)}
        />
        {error && (
          <Typography color="error" variant="body2">
            {error}
          </Typography>
        )}
        <Button
          variant="contained"
          fullWidth
          onClick={handleSubmit}
          disabled={isLoading || !email}
        >
          {isLoading ? <CircularProgress size={24} /> : 'Send Invite'}
        </Button>
      </Box>
    </Paper>
  );
};

const RecommendedUsersCard = ({ users, onConnect }) => (
  <Paper variant='outlined' sx={{ p: 3, border: 'none' }}>
    <Typography variant="h6" gutterBottom>Recommended</Typography>
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
      {users.map((user) => (
        <Box
          key={user.id}
          sx={{
            display: 'flex',
            gap: 2,
            p: 2,
            bgcolor: 'grey.50',
            borderRadius: 1,
            alignItems: 'center'
          }}
        >
          <Avatar src={user.avatar} />
          <Box sx={{ flex: 1 }}>
            <Typography variant="subtitle2">{user.name}</Typography>
            <Typography variant="body2" color="text.secondary">
              {user.role} • {user.mutualConnections} mutual
            </Typography>
          </Box>
          <IconButton size="small" onClick={() => onConnect(user.id)}>
            <PersonIcon />
          </IconButton>
        </Box>
      ))}
    </Box>
  </Paper>
);

// Main Page Component
export default function SearchInvitePage() {
  const navigate = useNavigate(); // For navigation

  const thisUser = useSelector((state) => state.auth?.user || '');
  console.log('thisUser', thisUser)

  const [searchQuery, setSearchQuery] = useState('');
  const [searchInitiated, setSearchInitiated] = useState(false);
  const [displayedUsers, setDisplayedUsers] = useState([]);

  const [showSameRegion, setShowSameRegion] = useState(false);
  const [selectedTrustScore, setSelectedTrustScore] = useState('all');
  const [anchorElTrust, setAnchorElTrust] = useState(null);

  const { data: networkStatsData, isLoading: isNetworkStatsDataLoading } = useGetNetworkStatsQuery();
  console.log('networkStatsData', networkStatsData)

  const { data: suggestionsData, isLoading: isSuggestionsDataLoading } = useGetUserSuggestionsQuery();
  console.log('suggestionsData', suggestionsData)

  const { data: connectedBusinessData, isLoading: isConnectedBusinessDataLoading } = useGetConnectedBusinessesQuery();
  console.log('connectedBusinessData', connectedBusinessData)

  const [triggerSearch, { data: searchData, isLoading: isSearchLoading }] = useFindUserMutation();
  const [addTrustedUser, { isLoading: isConnectLoading }] = useAddTrustedUserMutation();

  // Transform connectedBusinessData into the format expected by displayedUsers
  useEffect(() => {
    if (connectedBusinessData && !searchInitiated) {
      // Extract all businesses from all connected users
      const allBusinesses = [];
      
      // Check if connectedBusinessData is an array (extended network of users)
      if (Array.isArray(connectedBusinessData)) {
        // Iterate through each connected user
        connectedBusinessData.forEach(networkUser => {
          // Check if the user has a businesses array
          if (networkUser.businesses && Array.isArray(networkUser.businesses)) {
            // Add all businesses from this user to our collection
            networkUser.businesses.forEach(business => {
              allBusinesses.push({
                _id: business.spUserId || business.businessId,
                displayName: business.spDisplayName || 'Service Provider',
                role: "Service Provider", // Default role
                company: business.businessName,
                mutualConnections: 1, // Connected through the network user
                trustScore: 80, // Default score
                profileImageUrl: business.spPhoto,
                isPopular: true,
                region: thisUser.region, // Use user's region or default
                connectedThrough: networkUser.displayName // Track who they're connected through
              });
            });
          }
        });
      } 
      // If it's a single object with a businesses array (direct connection structure)
      else if (connectedBusinessData.businesses && Array.isArray(connectedBusinessData.businesses)) {
        connectedBusinessData.businesses.forEach(business => {
          allBusinesses.push({
            _id: business.spUserId || business.businessId,
            displayName: business.spDisplayName || 'Service Provider',
            role: "Service Provider",
            company: business.businessName,
            mutualConnections: 0,
            trustScore: 80,
            profileImageUrl: business.spPhoto,
            isPopular: true,
            region: thisUser.region
          });
        });
      }
      
      // Remove duplicates by business ID
      const uniqueBusinesses = [...new Map(allBusinesses.map(item => 
        [item._id, item])).values()];
      
      setDisplayedUsers(uniqueBusinesses);
    }
  }, [connectedBusinessData, searchInitiated, thisUser.region]);

  useEffect(() => {
    if (searchData) {
      console.log('searchData', searchData)
      setDisplayedUsers(searchData)   
    }
  }, [searchData]);

  const applyFilters = () => {
    let filteredUsers = [...displayedUsers];

    if (searchQuery) {
      filteredUsers = filteredUsers.filter(user => 
        user.displayName.toLowerCase().includes(searchQuery.toLowerCase())
      );
    }

    // Filter by same region if toggled
    if (showSameRegion) {
      filteredUsers = filteredUsers.filter(user => user.region === thisUser.region);
    }

    if (selectedTrustScore !== 'all') {
      const minScore = parseInt(selectedTrustScore);
      filteredUsers = filteredUsers.filter(user => user.trustScore >= minScore);
    }

    setDisplayedUsers(filteredUsers);
  };

  const handleTrustClick = (event) => {
    setAnchorElTrust(event.currentTarget);
  };
  
  const handleTrustClose = () => {
    setAnchorElTrust(null);
  };
  
  const handleTrustSelect = (score) => {
    setSelectedTrustScore(score);
    handleTrustClose();
    applyFilters();
  };

  const handleConnect = async (userId) => {
    console.log('Connecting with user:', userId);
    try {
      const payload = { 'trustedUserId': userId, requestType: 'new' }
      await addTrustedUser({payload, userId: userId});
      //navigate to user profile
    } catch (error) {
      console.error('Failed to add trusted user:', error);
    }
  };

  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleSearchSubmit = async(event) => {
    event.preventDefault();
    if (searchQuery.trim()) {
      setSearchInitiated(true);
      const payload = { text: searchQuery };
      await triggerSearch({ payload });
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault(); // Prevent form submission if within a form
      handleSearchSubmit(event);
    }
  };

  const clearSearch = () => {
    setSearchQuery('');
    setSearchInitiated(false);
    
    // Reset filters
    setShowSameRegion(false);
    setSelectedTrustScore('all');
    
    // Reprocess the original connectedBusinessData to reset the display
    if (connectedBusinessData) {
      const allBusinesses = [];
      
      // Check if connectedBusinessData is an array (extended network of users)
      if (Array.isArray(connectedBusinessData)) {
        // Iterate through each connected user
        connectedBusinessData.forEach(networkUser => {
          // Check if the user has a businesses array
          if (networkUser.businesses && Array.isArray(networkUser.businesses)) {
            // Add all businesses from this user to our collection
            networkUser.businesses.forEach(business => {
              allBusinesses.push({
                _id: business.spUserId || business.businessId,
                displayName: business.spDisplayName || 'Service Provider',
                role: "Service Provider", // Default role
                company: business.businessName,
                mutualConnections: 1, // Connected through the network user
                trustScore: 80, // Default score
                profileImageUrl: business.spPhoto,
                isPopular: true,
                region: thisUser.region, // Use user's region or default
                connectedThrough: networkUser.displayName // Track who they're connected through
              });
            });
          }
        });
      } 
      // If it's a single object with a businesses array (direct connection structure)
      else if (connectedBusinessData.businesses && Array.isArray(connectedBusinessData.businesses)) {
        connectedBusinessData.businesses.forEach(business => {
          allBusinesses.push({
            _id: business.spUserId || business.businessId,
            displayName: business.spDisplayName || 'Service Provider',
            role: "Service Provider",
            company: business.businessName,
            mutualConnections: 0,
            trustScore: 80,
            profileImageUrl: business.spPhoto,
            isPopular: true,
            region: thisUser.region
          });
        });
      }
      
      // Remove duplicates by business ID
      const uniqueBusinesses = [...new Map(allBusinesses.map(item => 
        [item._id, item])).values()];
      
      setDisplayedUsers(uniqueBusinesses);
    } else {
      setDisplayedUsers([]);
    }
  };

  // Combined loading state for initial load
  const isPageLoading = isNetworkStatsDataLoading || isConnectedBusinessDataLoading;

  if (isPageLoading) {
    return (
      <Box>
        <Box 
          sx={{ 
            bgcolor: 'primary.main', 
            color: 'white',
            p: 3,
            mb: 3 
          }}
        >
          <Container maxWidth="xl">
            <Skeleton 
              variant="text" 
              width={300} 
              height={40} 
              sx={{ bgcolor: 'rgba(255,255,255,0.1)' }} 
            />
          </Container>
        </Box>

        <Container maxWidth="xl">
          <Grid container spacing={3}>
            <Grid item xs={12} md={3}>
              <StatCardSkeleton />
              <StatCardSkeleton />
              <StatCardSkeleton />
            </Grid>

            <Grid item xs={12} md={6}>
              <Paper variant='outlined' sx={{ p: 3, mb: 2, border: 'none' }}>
                <Skeleton variant="rectangular" height={56} sx={{ borderRadius: 1 }} />
              </Paper>
              {[1, 2, 3].map((item) => (
                <SearchResultSkeleton key={item} />
              ))}
            </Grid>

            <Grid item xs={12} md={3}>
              <StatCardSkeleton />
              <StatCardSkeleton />
            </Grid>
          </Grid>
        </Container>
      </Box>
    );
  }

  return (
    <Box>
      <Box 
        sx={{ 
          bgcolor: 'primary.main', 
          color: 'white',
          p: 3,
          mb: 3 
        }}
      >
        <Container maxWidth="xl">
          <Typography variant="h4">Find & Invite Connections</Typography>
          <Typography variant="subtitle1" sx={{ opacity: 0.9 }}>
            Grow your trusted network
          </Typography>
        </Container>
      </Box>

      <Container maxWidth="xl">
        <Grid container spacing={3}>
          {/* Left Column */}
          <Grid item xs={12} md={3}>
            <NetworkStatsCard stats={networkStatsData || {}} />
            <QuickActionsCard />
            <GrowthTipsCard tips={GROWTH_TIPS} />
          </Grid>

          {/* Middle Column */}
          <Grid item xs={12} md={6}>
            <Paper variant='outlined' sx={{ p: 3, mb: 2, border: 'none' }}>
              <form onSubmit={handleSearchSubmit}>
                <TextField
                  fullWidth
                  placeholder="Search by name, company, or role..."
                  value={searchQuery}
                  onChange={handleSearch}
                  onKeyDown={handleKeyPress}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        {searchInitiated && (
                          <Button 
                            size="small" 
                            onClick={clearSearch}
                            sx={{ mr: 1 }}
                          >
                            Clear
                          </Button>
                        )}
                        <IconButton onClick={() => applyFilters()}>
                          <FilterIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </form>
              <Box sx={{ display: 'flex', gap: 1, mt: 2 }}>
                <Chip
                  label={`Same Region ${showSameRegion ? '• On' : '• Off'}`}
                  variant="outlined"
                  onClick={() => {
                    setShowSameRegion(!showSameRegion);
                    setTimeout(applyFilters, 0);
                  }}
                  color={showSameRegion ? "primary" : "default"}
                />
                <Chip
                  label={`Trust Score ${selectedTrustScore !== 'all' ? `• ${selectedTrustScore}+` : '▼'}`}
                  variant="outlined"
                  onClick={handleTrustClick}
                  color={selectedTrustScore !== 'all' ? "primary" : "default"}
                />
                
                {/* Trust Score Popover remains the same */}
                <Popover
                  open={Boolean(anchorElTrust)}
                  anchorEl={anchorElTrust}
                  onClose={handleTrustClose}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                >
                  <MenuList>
                    {TRUST_SCORE_RANGES.map((range) => (
                      <MenuItem 
                        key={range.value}
                        onClick={() => handleTrustSelect(range.value)}
                        selected={selectedTrustScore === range.value}
                      >
                        {range.label}
                      </MenuItem>
                    ))}
                  </MenuList>
                </Popover>
              </Box>
            </Paper>

            {/* Header for results */}
            <Box sx={{ px: 2, mb: 2 }}>
              <Typography variant="h6">
                {searchInitiated ? 'Search Results' : 'Extended Business Network'}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {searchInitiated 
                  ? `Found ${displayedUsers.length} results for "${searchQuery}"`
                  : 'Service providers from businesses in your extended network'}
              </Typography>
            </Box>
            
            {isSearchLoading ? (
              // Show loading skeletons when search is in progress
              [1, 2, 3].map((item) => (
                <SearchResultSkeleton key={item} />
              ))
            ) : displayedUsers.length > 0 ? (
              // Show search results if available
              displayedUsers.map((user) => (
                <SearchResultCard
                  key={user._id}
                  thisUser={thisUser}
                  user={user}
                  onConnect={handleConnect}
                />
              ))
            ) : (
              // Show no results message
              <Paper variant='outlined' sx={{ p: 3, textAlign: 'center', border: 'none' }}>
                <Typography variant="body1">No results found</Typography>
                <Typography variant="body2" color="text.secondary">
                  Try adjusting your search criteria
                </Typography>
              </Paper>
            )}
          </Grid>

          {/* Right Column */}
          <Grid item xs={12} md={3}>
            <EmailInviteCard />
            <RecommendedUsersCard 
              users={suggestionsData || RECOMMENDED_USERS}
              onConnect={handleConnect}
            />
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}