import React from 'react';
import { Grid, Card, CardContent, Typography, Box, Avatar, CircularProgress } from '@mui/material';
import { Chart } from 'react-google-charts';
import RatingIcon from '@mui/icons-material/Star';
import SentimentIcon from '@mui/icons-material/SentimentSatisfiedAlt';
import ReviewIcon from '@mui/icons-material/RateReview';
import PageViewIcon from '@mui/icons-material/Visibility';

const KPIs = ({data}) => {
  // Get data from props or use static data for demonstration
  const staticData1 = data?.businessKPIsData || {
    rating: 4.5,
    sentiment: '.8',
    reviewCount: 1250,
    pageViews: 5230,
    trustRank: 20,
    hopsRatio: 8,
    globalRank: '1000',
    reviewDistribution: [5, 10, 10, 5, 5],
    hopLevelReviews: [300, 500, 450],
    impactfulReviewers: ['Reviewer 1', 'Reviewer 2', 'Reviewer 3'],
    impactfulReviews: [
      { id: 1, text: 'Great service and friendly staff. Highly recommend!', reviewer: 'John Doe', createdAt: '2024-07-20' },
      { id: 2, text: 'Had a wonderful experience. Will definitely return!', reviewer: 'Jane Smith', createdAt: '2024-07-19' },
      { id: 3, text: 'Quality could be improved, but overall good value.', reviewer: 'Alex Johnson', createdAt: '2024-07-18' },
    ],
    hopsPercentage: []
  };
//use actual data eventually
  const staticData =  {
    rating: 4.5,
    sentiment: '.8',
    reviewCount: 1250,
    pageViews: 5230,
    trustRank: 20,
    hopsRatio: 8,
    globalRank: '1000',
    reviewDistribution: [5, 10, 10, 5, 5],
    hopLevelReviews: [300, 500, 450],
    impactfulReviewers: ['Reviewer 1', 'Reviewer 2', 'Reviewer 3'],
    impactfulReviews: [
      { id: 1, text: 'Great service and friendly staff. Highly recommend!', reviewer: 'John Doe', createdAt: '2024-07-20' },
      { id: 2, text: 'Had a wonderful experience. Will definitely return!', reviewer: 'Jane Smith', createdAt: '2024-07-19' },
      { id: 3, text: 'Quality could be improved, but overall good value.', reviewer: 'Alex Johnson', createdAt: '2024-07-18' },
    ],
    hopsPercentage: []
  };

  console.log('data', data);
  console.log('staticData', staticData);

  function convertSentiment(totalSentiment) {
    if (totalSentiment > 0.5 && totalSentiment <= 1) {
      return 'Positive';
    } else if (totalSentiment >= -1 && totalSentiment < -0.5) {
      return 'Negative';
    } else if (totalSentiment === 0) {
      return 'Mixed'; // Mixed if exactly 0
    } else if (totalSentiment >= -0.5 && totalSentiment <= 0.5) {
      return 'Neutral';
    } else {
      throw new Error('Invalid sentiment value. Sentiment should be between -1 and 1.');
    }
  }

  // Calculate percentages, ensuring they're positive
  const trustRankPercentage = Math.max(0, Math.floor(staticData.trustRank) * 20);
  const remainingTrustRank = Math.max(0, 100 - trustRankPercentage);
  
  const hopsRatioPercentage = Math.max(0, staticData.hopsRatio || 8);
  const remainingHopsRatio = Math.max(0, 100 - hopsRatioPercentage);

  // Configure pie chart for trust rank - ensuring all values are positive
  const trustRankData = [
    ['Category', 'Value'],
    ['Top Percentile', trustRankPercentage],
    ['Remaining', remainingTrustRank],
  ];

  const trustRankOptions = {
    pieHole: 0.7,
    pieSliceText: 'none',
    legend: 'none',
    tooltip: { trigger: 'none' },
    slices: {
      0: { color: '#3f51b5' },
      1: { color: '#e0e0e0' }
    },
    chartArea: { width: '80%', height: '80%' },
    width: '100%',
    height: 250,
  };

  // Configure pie chart for hops ratio - ensuring all values are positive
  const hopsRatioData = [
    ['Category', 'Value'],
    ['Connection Sales', hopsRatioPercentage],
    ['Remaining', remainingHopsRatio],
  ];

  const hopsRatioOptions = {
    pieHole: 0.7,
    pieSliceText: 'none',
    legend: 'none',
    tooltip: { trigger: 'none' },
    slices: {
      0: { color: '#4caf50' },
      1: { color: '#e0e0e0' }
    },
    chartArea: { width: '80%', height: '80%' },
    width: '100%',
    height: 250,
  };

  // Configure bar chart for hop level reviews
  const hopLevelData = [
    ['Hop Level', 'Reviews', { role: 'style' }],
    ['1st Hop', staticData.hopLevelReviews[0], '#9C27B0'], 
    ['2nd Hop', staticData.hopLevelReviews[1], '#9C27B0'], 
    ['3rd Hop', staticData.hopLevelReviews[2], '#9C27B0'], 
  ];

  const hopLevelOptions = {
    title: '',
    chartArea: { width: '80%', height: '70%' },
    hAxis: {
      title: 'Hop Level',
      titleTextStyle: { color: '#5f6368' },
    },
    vAxis: {
      title: 'Number of Reviews',
      titleTextStyle: { color: '#5f6368' },
      minValue: 0,
    },
    legend: { position: 'none' },
    colors: ['#9C27B0'],
    bar: { groupWidth: '60%' },
  };

  if (!data) {
    // Show a loading spinner if data is not available
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
        <CircularProgress color="primary" />
      </Box>
    );
  }

  return (
    <Box>
      {/* First Row - Key Metrics with accent colors */}
      <Grid container spacing={2} sx={{ mb: 2 }} alignItems="stretch">
        {/* Rating Card */}
        <Grid item xs={12} sm={3}>
          <Card 
            variant="outlined" 
            sx={{ 
              height: '100%', 
              position: 'relative', 
              overflow: 'visible',
              border: 'none', 
              borderRadius: 2,
              bgcolor: 'white'
            }}
          >
            <Box sx={{ position: 'absolute', left: 0, top: 0, bottom: 0, width: '8px', backgroundColor: '#FFD700', borderTopLeftRadius: 8, borderBottomLeftRadius: 8 }} />
            <CardContent>
              <Box display="flex" alignItems="center" justifyContent="space-between">
                <Box>
                  <Typography variant="caption" color="textSecondary">Rating</Typography>
                  <Typography variant="h4" sx={{ fontWeight: '500' }}>{staticData.rating}</Typography>
                  <Typography variant="body2" color="textSecondary">+0.2 from last month</Typography>
                </Box>
                <Avatar sx={{ bgcolor: '#FFF9C4', color: '#FFD700', width: 56, height: 56 }}>
                  <RatingIcon sx={{ fontSize: 32 }} />
                </Avatar>
              </Box>
            </CardContent>
          </Card>
        </Grid>

        {/* Sentiment Card */}
        <Grid item xs={12} sm={3}>
          <Card 
            variant="outlined" 
            sx={{ 
              height: '100%', 
              position: 'relative', 
              overflow: 'visible',
              border: 'none', 
              borderRadius: 2,
              bgcolor: 'white'
            }}
          >
            <Box sx={{ position: 'absolute', left: 0, top: 0, bottom: 0, width: '8px', backgroundColor: '#4CAF50', borderTopLeftRadius: 8, borderBottomLeftRadius: 8 }} />
            <CardContent>
              <Box display="flex" alignItems="center" justifyContent="space-between">
                <Box>
                  <Typography variant="caption" color="textSecondary">Sentiment</Typography>
                  <Typography variant="h5" sx={{ fontWeight: '500' }}>{convertSentiment(staticData.sentiment)}</Typography>
                  <Typography variant="body2" color="textSecondary">Score: {staticData.sentiment}/1.0</Typography>
                </Box>
                <Avatar sx={{ bgcolor: '#E8F5E9', color: '#4CAF50', width: 56, height: 56 }}>
                  <SentimentIcon sx={{ fontSize: 32 }} />
                </Avatar>
              </Box>
            </CardContent>
          </Card>
        </Grid>

        {/* Review Count Card */}
        <Grid item xs={12} sm={3}>
          <Card 
            variant="outlined" 
            sx={{ 
              height: '100%', 
              position: 'relative', 
              overflow: 'visible',
              border: 'none', 
              borderRadius: 2,
              bgcolor: 'white'
            }}
          >
            <Box sx={{ position: 'absolute', left: 0, top: 0, bottom: 0, width: '8px', backgroundColor: '#2196F3', borderTopLeftRadius: 8, borderBottomLeftRadius: 8 }} />
            <CardContent>
              <Box display="flex" alignItems="center" justifyContent="space-between">
                <Box>
                  <Typography variant="caption" color="textSecondary">Review Count</Typography>
                  <Typography variant="h4" sx={{ fontWeight: '500' }}>{staticData.reviewCount}</Typography>
                  <Typography variant="body2" color="textSecondary">+125 new reviews</Typography>
                </Box>
                <Avatar sx={{ bgcolor: '#E3F2FD', color: '#2196F3', width: 56, height: 56 }}>
                  <ReviewIcon sx={{ fontSize: 32 }} />
                </Avatar>
              </Box>
            </CardContent>
          </Card>
        </Grid>

        {/* Page Views Card */}
        <Grid item xs={12} sm={3}>
          <Card 
            variant="outlined" 
            sx={{ 
              height: '100%', 
              position: 'relative', 
              overflow: 'visible',
              border: 'none', 
              borderRadius: 2,
              bgcolor: 'white'
            }}
          >
            <Box sx={{ position: 'absolute', left: 0, top: 0, bottom: 0, width: '8px', backgroundColor: '#9C27B0', borderTopLeftRadius: 8, borderBottomLeftRadius: 8 }} />
            <CardContent>
              <Box display="flex" alignItems="center" justifyContent="space-between">
                <Box>
                  <Typography variant="caption" color="textSecondary">Page Views</Typography>
                  <Typography variant="h4" sx={{ fontWeight: '500' }}>{staticData.pageViews}</Typography>
                  <Typography variant="body2" color="textSecondary">+12% from previous period</Typography>
                </Box>
                <Avatar sx={{ bgcolor: '#F3E5F5', color: '#9C27B0', width: 56, height: 56 }}>
                  <PageViewIcon sx={{ fontSize: 32 }} />
                </Avatar>
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      {/* Second Row - Charts */}
      <Grid container spacing={2} sx={{ mb: 2 }} alignItems="stretch">
        {/* Industry Rank Chart - Using a card with custom progress indicator instead of pie chart */}
        <Grid item xs={12} sm={4}>
          <Card 
            variant="outlined" 
            sx={{ 
              height: '100%', 
              border: 'none', 
              borderRadius: 2,
              bgcolor: 'white'
            }}
          >
            <CardContent>
              <Typography variant="h6" sx={{ mb: 3, textAlign: 'center' }}>
                Top % in Your Industry
              </Typography>
              
              {/* Custom circular progress indicator */}
              <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'relative', height: '200px' }}>
                <Box 
                  sx={{ 
                    position: 'relative',
                    width: '180px',
                    height: '180px',
                    borderRadius: '50%',
                    background: `conic-gradient(#3f51b5 ${trustRankPercentage * 3.6}deg, #e0e0e0 ${trustRankPercentage * 3.6}deg)`,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    '&::before': {
                      content: '""',
                      position: 'absolute',
                      width: '70%',
                      height: '70%',
                      borderRadius: '50%',
                      background: 'white',
                    }
                  }}
                >
                  <Box sx={{ position: 'relative', textAlign: 'center', zIndex: 1 }}>
                    <Typography variant="h4" sx={{ fontWeight: 'bold', color: '#3f51b5' }}>
                      {trustRankPercentage}%
                    </Typography>
                    <Typography variant="caption" color="textSecondary">
                      Top Percentile
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </CardContent>
          </Card>
        </Grid>

        {/* Sales Through Connections Chart - Using a card with custom progress indicator instead of pie chart */}
        <Grid item xs={12} sm={4}>
          <Card 
            variant="outlined" 
            sx={{ 
              height: '100%', 
              border: 'none', 
              borderRadius: 2,
              bgcolor: 'white'
            }}
          >
            <CardContent>
              <Typography variant="h6" sx={{ mb: 3, textAlign: 'center' }}>
                Estimated % Sales Through Connections
              </Typography>
              
              {/* Custom circular progress indicator */}
              <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'relative', height: '200px' }}>
                <Box 
                  sx={{ 
                    position: 'relative',
                    width: '180px',
                    height: '180px',
                    borderRadius: '50%',
                    background: `conic-gradient(#4caf50 ${hopsRatioPercentage * 3.6}deg, #e0e0e0 ${hopsRatioPercentage * 3.6}deg)`,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    '&::before': {
                      content: '""',
                      position: 'absolute',
                      width: '70%',
                      height: '70%',
                      borderRadius: '50%',
                      background: 'white',
                    }
                  }}
                >
                  <Box sx={{ position: 'relative', textAlign: 'center', zIndex: 1 }}>
                    <Typography variant="h4" sx={{ fontWeight: 'bold', color: '#4caf50' }}>
                      {hopsRatioPercentage}%
                    </Typography>
                    <Typography variant="caption" color="textSecondary">
                      Connection Driven
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </CardContent>
          </Card>
        </Grid>

        {/* Review Count by Hop Chart */}
        <Grid item xs={12} sm={4}>
          <Card 
            variant="outlined" 
            sx={{ 
              height: '100%', 
              border: 'none', 
              borderRadius: 2,
              bgcolor: 'white'
            }}
          >
            <CardContent>
              <Typography variant="h6" sx={{ mb: 2, textAlign: 'center' }}>
                Review Count by Hop
              </Typography>
              
              {/* Use a different chart type for the hop data to avoid issues */}
              <Chart
                chartType="BarChart"
                width="100%"
                height="250px"
                data={hopLevelData}
                options={hopLevelOptions}
              />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      
      {/* Third Row - Impactful Reviewers */}
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Card 
            variant="outlined" 
            sx={{ 
              border: 'none', 
              borderRadius: 2,
              bgcolor: 'white'
            }}
          >
            <Box sx={{ bgcolor: '#f5f5f5', p: 2, borderTopLeftRadius: 8, borderTopRightRadius: 8 }}>
              <Typography variant="h6">Top Reviewers Impact</Typography>
            </Box>
            <CardContent>
              <Grid container spacing={2}>
                {staticData.impactfulReviews.map((review, index) => (
                  <Grid item xs={12} md={4} key={review.id}>
                    <Box sx={{ display: 'flex', alignItems: 'flex-start', mb: 2 }}>
                      <Avatar 
                        sx={{ 
                          mr: 2, 
                          bgcolor: index === 0 ? '#E3F2FD' : index === 1 ? '#FFF3E0' : '#E8F5E9'
                        }}
                      >
                        {review.reviewer.split(' ').map(name => name[0]).join('')}
                      </Avatar>
                      <Box>
                        <Typography variant="subtitle1">{review.reviewer}</Typography>
                        <Typography variant="body2" color="textSecondary" sx={{ mb: 1 }}>
                          {new Date(review.createdAt).toLocaleDateString()}
                        </Typography>
                        <Typography variant="body2" sx={{ fontStyle: 'italic' }}>
                          "{review.text}"
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                ))}
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};

export default KPIs;